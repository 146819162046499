import {
  createFileRoute,
  Link,
  Outlet,
  redirect,
} from "@tanstack/react-router";

import { SSOTokens } from "@/api/services/auth.service";
import { OPLogo } from "@/components/op-logo";
import { toast } from "@/lib/utils/toast";

export const Route = createFileRoute("/dashboard/admin")({
  beforeLoad: () => {
    const cookieStore = window.localStorage;

    const userType = cookieStore.getItem("userType") as SSOTokens["user_type"];
    if (userType !== "admin") {
      toast("You are not authorized to access this page.");
      return redirect({ to: "/login" });
    }
  },
  component: () => (
    <div className="h-svh">
      <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
        <Link to="/">
          <OPLogo />
        </Link>
      </header>

      <div className="h-full pb-40 pt-36">
        <Outlet />
      </div>
    </div>
  ),
});
