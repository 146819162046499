import { useMutation } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";

import { FounderService } from "@/api/services/founder.service";
import { BottomNavigation } from "@/components/bottom-navigation";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { Button } from "@/components/ui/button";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import {
  founderOnboardingState$,
  MonthlyEmailPreference,
} from "@/store/founder.state";

export const Route = createFileRoute("/onboarding/founder/mail-preference")({
  component: function Page() {
    const is_monthly_mail_allowed =
      founderOnboardingState$.is_monthly_mail_allowed.get();
    const navigate = Route.useNavigate();

    const onboardFounderMutation = useMutation({
      mutationFn: FounderService.onboardFounder,
    });

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      const founder = founderOnboardingState$.get();

      await onboardFounderMutation.mutateAsync({
        name: founder.name,
        time_zone: founder.time_zone,
        company_name: founder.company_name,
        project_outcome_description: founder.project_outcome_description,
        links: founder.links,
        is_monthly_mail_allowed: !!is_monthly_mail_allowed,
        profile_picture: null,
      });

      navigate({
        to: "/dashboard/founder",
        replace: true,
      });
    };

    return (
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center text-center"
      >
        <h1 className="mt-10 max-w-[320px] text-balance md:mt-20 md:max-w-[400px]">
          {"Do you want to get our monthly emails with new"}{" "}
          {"collaborations and product updates?"}
        </h1>

        <div className="mt-20 flex justify-center gap-2">
          <ToggleGroup
            type="single"
            defaultValue={
              is_monthly_mail_allowed === undefined
                ? undefined
                : is_monthly_mail_allowed
                  ? "yes"
                  : "no"
            }
            onValueChange={(value) => {
              if (value)
                founderOnboardingState$.is_monthly_mail_allowed.set(
                  value as MonthlyEmailPreference,
                );
            }}
          >
            <ToggleGroupItem value="yes">Yes</ToggleGroupItem>
            <ToggleGroupItem value="no">No</ToggleGroupItem>
          </ToggleGroup>
        </div>
        <BottomNavigation
          left={
            <Button size="sm" asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={
            is_monthly_mail_allowed && <Button type="submit">Next</Button>
          }
        />
      </form>
    );
  },
});
