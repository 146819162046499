import { useMutation } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import dayjs from "dayjs";
import { useState } from "react";

import { FounderService } from "@/api/services/founder.service";
import { BottomNavigation } from "@/components/bottom-navigation";
import { helpDialog$ } from "@/components/dialogs/common/help";
import { FadeScaleContainer } from "@/components/fade-scale-container";
import { DelivarablesDialog } from "@/components/founder/deliverables-dialog";
import { OfferButton } from "@/components/proposals/offer-button";
import { SlideContainer } from "@/components/slide-container";
import { Button } from "@/components/ui/button";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { useIsMobile } from "@/hooks/use-is-mobile";
import { useProposals } from "@/hooks/use-proposal";
import { Propsal } from "@/lib/global-types";
import { queryClient } from "@/lib/query-client";
import { cn } from "@/lib/utils";

export const Route = createFileRoute("/dashboard/founder/proposals")({
  loader: async () => {
    const founder = await queryClient.fetchQuery(
      FounderService.getCurrentFounder(),
    );
    const proposal = await queryClient.fetchQuery(
      FounderService.fetchFounderProposal(),
    );

    return { founder, proposal };
  },
  component: function Page() {
    const { founder, proposal } = Route.useLoaderData();
    const navigate = Route.useNavigate();
    const allProposals = useProposals(proposal);
    const { isMobile } = useIsMobile();

    const [selectedProposalType, setSelectedProposalType] = useState<
      Propsal["type"] | null
    >(null);
    const [delivarablesDialogOpen, setDelivarablesDialogOpen] = useState(false);

    const selectedProposal = allProposals[selectedProposalType!];

    const saveMutation = useMutation({
      mutationFn: async () => {
        if (!selectedProposalType) return;

        setDelivarablesDialogOpen(false);
        const id = proposal.proposals.find(
          (p) => p.type === selectedProposalType,
        )!.id;
        await FounderService.acceptProposal(id);
        await FounderService.acceptAgreement();
        navigate({
          to: "/dashboard/founder",
          replace: true,
        });
        /* router.replace(`/dashboard/founder/`); */
      },
    });

    return (
      <>
        <FadeScaleContainer className="flex w-full flex-col items-center pb-52 text-center">
          <div className="mb-10 flex flex-col gap-2 md:mb-20">
            <h2 className="text-center">
              Which package works best for you? <br />
            </h2>

            <Link
              className="underline"
              to="/dashboard/founder/proposals/included"
            >
              Compare what’s included
            </Link>
          </div>

          <div className="flex w-screen flex-col items-center gap-6 md:w-auto md:flex-row">
            <Carousel
              opts={{
                containScroll: isMobile ? false : "keepSnaps",
                active: isMobile,
              }}
              className="w-full"
            >
              <CarouselContent>
                <CarouselItem className="flex basis-[80%] items-center justify-center md:basis-1/3">
                  <OfferButton
                    title={"Request"}
                    className={cn({
                      "is-active bg-black text-white":
                        selectedProposalType === "requested",
                    })}
                    onClick={() => {
                      setSelectedProposalType("requested");
                      setDelivarablesDialogOpen(true);
                    }}
                    payload={allProposals.requested}
                  />
                </CarouselItem>

                <CarouselItem className="flex basis-[80%] items-center justify-center md:basis-1/3">
                  <OfferButton
                    title={"Recommendation"}
                    className={cn({
                      "is-active bg-black text-white":
                        selectedProposalType === "recommended",
                    })}
                    onClick={() => {
                      setSelectedProposalType("recommended");
                      setDelivarablesDialogOpen(true);
                    }}
                    payload={allProposals.recommended}
                  />
                </CarouselItem>

                <CarouselItem className="flex basis-[80%] items-center justify-center md:basis-1/3">
                  <OfferButton
                    title={"Suggestion"}
                    className={cn({
                      "is-active bg-black text-white":
                        selectedProposalType === "suggested",
                    })}
                    onClick={() => {
                      setSelectedProposalType("suggested");
                      setDelivarablesDialogOpen(true);
                    }}
                    payload={allProposals.suggested}
                  />
                </CarouselItem>
              </CarouselContent>
            </Carousel>
          </div>

          <div className="mt-20 flex flex-row items-center gap-4">
            <Button asChild>
              <Link target="_blank" to={`/presentation/founder`}>
                Process
              </Link>
            </Button>
            <Button asChild>
              <Link
                target="_blank"
                to={"/samples"}
                search={{ filter: undefined }}
              >
                Samples
              </Link>
            </Button>
          </div>

          {selectedProposalType && selectedProposal && (
            <DelivarablesDialog
              name={selectedProposalType}
              open={delivarablesDialogOpen}
              proposal={selectedProposal}
              founder={founder}
              onConfirm={saveMutation.mutate}
              loading={saveMutation.isPending}
              onClose={() => setDelivarablesDialogOpen(false)}
            />
          )}

          <SlideContainer
            key={selectedProposalType}
            className="mt-20 flex w-1/3 flex-col gap-20 text-center"
          >
            <p>{proposal.description}</p>
            <p>
              Staring date is set on <br /> {/* //Monday 15th of September. */}
              {dayjs(proposal.kickoff_date).format("dddd Do of MMMM")}
            </p>
          </SlideContainer>
        </FadeScaleContainer>
        <BottomNavigation
          middle={
            <Button size={"sm"} onClick={() => helpDialog$.set(true)}>
              ?
            </Button>
          }
        />
      </>
    );
  },
});
