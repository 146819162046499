/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as OnboardingImport } from './routes/onboarding'
import { Route as NewImport } from './routes/new'
import { Route as LoginImport } from './routes/login'
import { Route as DashboardImport } from './routes/dashboard'
import { Route as IndexImport } from './routes/index'
import { Route as NewIndexImport } from './routes/new/index'
import { Route as PresentationFounderImport } from './routes/presentation.founder'
import { Route as PresentationCreativesImport } from './routes/presentation.creatives'
import { Route as NewFounderImport } from './routes/new/founder'
import { Route as NewDesignerImport } from './routes/new/designer'
import { Route as DashboardFounderImport } from './routes/dashboard/founder'
import { Route as DashboardDesignerImport } from './routes/dashboard/designer'
import { Route as DashboardAdminImport } from './routes/dashboard/admin'
import { Route as ApiRedirectImport } from './routes/api/redirect'
import { Route as MiscAboutImport } from './routes/_misc/about'
import { Route as SamplesSamplesIndexImport } from './routes/samples/_samples.index'
import { Route as OnboardingFounderIndexImport } from './routes/onboarding/founder/index'
import { Route as NewFounderIndexImport } from './routes/new/founder/index'
import { Route as NewDesignerIndexImport } from './routes/new/designer/index'
import { Route as OnboardingFounderTimezoneImport } from './routes/onboarding/founder/timezone'
import { Route as OnboardingFounderOutcomeImport } from './routes/onboarding/founder/outcome'
import { Route as OnboardingFounderMailPreferenceImport } from './routes/onboarding/founder/mail-preference'
import { Route as OnboardingFounderLinksImport } from './routes/onboarding/founder/links'
import { Route as OnboardingFounderCompanyImport } from './routes/onboarding/founder/company'
import { Route as NewFounderTimeImport } from './routes/new/founder/time'
import { Route as NewFounderPlansImport } from './routes/new/founder/plans'
import { Route as NewFounderPackagesAndPlanImport } from './routes/new/founder/packages-and-plan'
import { Route as NewFounderPackagesImport } from './routes/new/founder/packages'
import { Route as NewFounderEmailImport } from './routes/new/founder/email'
import { Route as NewFounderCheckEmailImport } from './routes/new/founder/check-email'
import { Route as NewDesignerTimeImport } from './routes/new/designer/time'
import { Route as NewDesignerSamplesImport } from './routes/new/designer/samples'
import { Route as NewDesignerLinksImport } from './routes/new/designer/links'
import { Route as NewDesignerEmailImport } from './routes/new/designer/email'
import { Route as NewDesignerCheckMailImport } from './routes/new/designer/check-mail'
import { Route as DashboardFounderReceiptImport } from './routes/dashboard/founder/receipt'
import { Route as DashboardFounderProposalsImport } from './routes/dashboard/founder/proposals'
import { Route as DashboardFounderInvoiceImport } from './routes/dashboard/founder/invoice'
import { Route as DashboardFounderCompanyFormImport } from './routes/dashboard/founder/company-form'
import { Route as DashboardFounderAgreementImport } from './routes/dashboard/founder/agreement'
import { Route as DashboardAdminSiteReportsImport } from './routes/dashboard/admin/site-reports'
import { Route as DashboardAdminCreativesImport } from './routes/dashboard/admin/creatives'
import { Route as DashboardAdminCreateProjectImport } from './routes/dashboard/admin/create-project'
import { Route as MiscAboutMissionImport } from './routes/_misc/about_.mission'
import { Route as MiscNewsletterUpdatesImport } from './routes/_misc/_newsletter.updates'
import { Route as MiscNewsletterConfirmedImport } from './routes/_misc/_newsletter.confirmed'
import { Route as OnboardingCreativesTypeIndexImport } from './routes/onboarding/creatives.$type/index'
import { Route as DashboardFounderFounderIndexImport } from './routes/dashboard/founder/_founder.index'
import { Route as DashboardDesignerDesignerIndexImport } from './routes/dashboard/designer/_designer.index'
import { Route as DashboardAdminAdminIndexImport } from './routes/dashboard/admin/_admin.index'
import { Route as OnboardingCreativesTypeTimeZoneImport } from './routes/onboarding/creatives.$type/time-zone'
import { Route as OnboardingCreativesTypeLevelsImport } from './routes/onboarding/creatives.$type/levels'
import { Route as NewFounderPackagesDurationsImport } from './routes/new/founder/packages_.durations'
import { Route as DashboardFounderProposalsIncludedImport } from './routes/dashboard/founder/proposals_.included'
import { Route as DashboardFounderInvoiceDetailImport } from './routes/dashboard/founder/invoice_.detail'
import { Route as DashboardFounderUpdatesUpdatesIndexImport } from './routes/dashboard/founder/updates/_updates.index'
import { Route as DashboardFounderUpdatesUpdateIdIndexImport } from './routes/dashboard/founder/updates/$updateId.index'
import { Route as DashboardDesignerProjectsProjectsIndexImport } from './routes/dashboard/designer/projects/_projects.index'
import { Route as DashboardAdminFoundersFoundersIndexImport } from './routes/dashboard/admin/founders/_founders.index'
import { Route as DashboardAdminDesignersDesignerIdIndexImport } from './routes/dashboard/admin/designers.$designerId/index'
import { Route as DashboardFounderUpdatesUpdatesThanksImport } from './routes/dashboard/founder/updates/_updates.thanks'
import { Route as DashboardFounderUpdatesUpdateIdRateImport } from './routes/dashboard/founder/updates/$updateId.rate'
import { Route as DashboardFounderUpdatesUpdateIdFeedbackImport } from './routes/dashboard/founder/updates/$updateId.feedback'
import { Route as DashboardAdminFoundersFounderIdProposalImport } from './routes/dashboard/admin/founders/$founderId/proposal'
import { Route as DashboardAdminFoundersFounderIdInvoiceImport } from './routes/dashboard/admin/founders/$founderId/invoice'
import { Route as DashboardAdminFoundersFounderIdInfoImport } from './routes/dashboard/admin/founders/$founderId/info'
import { Route as DashboardAdminFoundersFounderIdEditImport } from './routes/dashboard/admin/founders/$founderId/edit'
import { Route as DashboardAdminDesignersDesignerIdRateImport } from './routes/dashboard/admin/designers.$designerId/rate'
import { Route as DashboardAdminDesignersDesignerIdInfoImport } from './routes/dashboard/admin/designers.$designerId/info'
import { Route as DashboardAdminDesignersDesignerIdAssignImport } from './routes/dashboard/admin/designers.$designerId/assign'
import { Route as DashboardDesignerProjectsProjectIdProjectIdIndexImport } from './routes/dashboard/designer/projects/$projectId/_$projectId.index'
import { Route as DashboardAdminFoundersFounderIdFounderIdIndexImport } from './routes/dashboard/admin/founders/$founderId/_$founderId.index'
import { Route as DashboardDesignerProjectsProjectIdUpdatesUpdatesIndexImport } from './routes/dashboard/designer/projects/$projectId/updates/_updates.index'
import { Route as DashboardDesignerProjectsProjectIdUpdatesUpdateIdIndexImport } from './routes/dashboard/designer/projects/$projectId/updates/$updateId.index'
import { Route as DashboardAdminFoundersFounderIdUpdatesUpdatesIndexImport } from './routes/dashboard/admin/founders/$founderId/updates/_updates.index'
import { Route as DashboardAdminFoundersFounderIdUpdatesUpdateIdIndexImport } from './routes/dashboard/admin/founders/$founderId/updates/$updateId.index'
import { Route as DashboardDesignerProjectsProjectIdUpdatesUpdateIdPreviewImport } from './routes/dashboard/designer/projects/$projectId/updates/$updateId.preview'
import { Route as DashboardDesignerProjectsProjectIdUpdatesUpdateIdNextImport } from './routes/dashboard/designer/projects/$projectId/updates/$updateId.next'
import { Route as DashboardAdminFoundersFounderIdUpdatesUpdateIdPreviewImport } from './routes/dashboard/admin/founders/$founderId/updates/$updateId.preview'
import { Route as DashboardAdminFoundersFounderIdUpdatesUpdateIdNextImport } from './routes/dashboard/admin/founders/$founderId/updates/$updateId.next'

// Create/Update Routes

const OnboardingRoute = OnboardingImport.update({
  id: '/onboarding',
  path: '/onboarding',
  getParentRoute: () => rootRoute,
} as any)

const NewRoute = NewImport.update({
  id: '/new',
  path: '/new',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const DashboardRoute = DashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const NewIndexRoute = NewIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => NewRoute,
} as any)

const PresentationFounderRoute = PresentationFounderImport.update({
  id: '/presentation/founder',
  path: '/presentation/founder',
  getParentRoute: () => rootRoute,
} as any)

const PresentationCreativesRoute = PresentationCreativesImport.update({
  id: '/presentation/creatives',
  path: '/presentation/creatives',
  getParentRoute: () => rootRoute,
} as any)

const NewFounderRoute = NewFounderImport.update({
  id: '/founder',
  path: '/founder',
  getParentRoute: () => NewRoute,
} as any)

const NewDesignerRoute = NewDesignerImport.update({
  id: '/designer',
  path: '/designer',
  getParentRoute: () => NewRoute,
} as any)

const DashboardFounderRoute = DashboardFounderImport.update({
  id: '/founder',
  path: '/founder',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardDesignerRoute = DashboardDesignerImport.update({
  id: '/designer',
  path: '/designer',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardAdminRoute = DashboardAdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => DashboardRoute,
} as any)

const ApiRedirectRoute = ApiRedirectImport.update({
  id: '/api/redirect',
  path: '/api/redirect',
  getParentRoute: () => rootRoute,
} as any)

const MiscAboutRoute = MiscAboutImport.update({
  id: '/_misc/about',
  path: '/about',
  getParentRoute: () => rootRoute,
} as any)

const SamplesSamplesIndexRoute = SamplesSamplesIndexImport.update({
  id: '/samples/_samples/',
  path: '/samples/',
  getParentRoute: () => rootRoute,
} as any)

const OnboardingFounderIndexRoute = OnboardingFounderIndexImport.update({
  id: '/founder/',
  path: '/founder/',
  getParentRoute: () => OnboardingRoute,
} as any)

const NewFounderIndexRoute = NewFounderIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => NewFounderRoute,
} as any)

const NewDesignerIndexRoute = NewDesignerIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => NewDesignerRoute,
} as any)

const OnboardingFounderTimezoneRoute = OnboardingFounderTimezoneImport.update({
  id: '/founder/timezone',
  path: '/founder/timezone',
  getParentRoute: () => OnboardingRoute,
} as any)

const OnboardingFounderOutcomeRoute = OnboardingFounderOutcomeImport.update({
  id: '/founder/outcome',
  path: '/founder/outcome',
  getParentRoute: () => OnboardingRoute,
} as any)

const OnboardingFounderMailPreferenceRoute =
  OnboardingFounderMailPreferenceImport.update({
    id: '/founder/mail-preference',
    path: '/founder/mail-preference',
    getParentRoute: () => OnboardingRoute,
  } as any)

const OnboardingFounderLinksRoute = OnboardingFounderLinksImport.update({
  id: '/founder/links',
  path: '/founder/links',
  getParentRoute: () => OnboardingRoute,
} as any)

const OnboardingFounderCompanyRoute = OnboardingFounderCompanyImport.update({
  id: '/founder/company',
  path: '/founder/company',
  getParentRoute: () => OnboardingRoute,
} as any)

const NewFounderTimeRoute = NewFounderTimeImport.update({
  id: '/time',
  path: '/time',
  getParentRoute: () => NewFounderRoute,
} as any)

const NewFounderPlansRoute = NewFounderPlansImport.update({
  id: '/plans',
  path: '/plans',
  getParentRoute: () => NewFounderRoute,
} as any)

const NewFounderPackagesAndPlanRoute = NewFounderPackagesAndPlanImport.update({
  id: '/packages-and-plan',
  path: '/packages-and-plan',
  getParentRoute: () => NewFounderRoute,
} as any)

const NewFounderPackagesRoute = NewFounderPackagesImport.update({
  id: '/packages',
  path: '/packages',
  getParentRoute: () => NewFounderRoute,
} as any)

const NewFounderEmailRoute = NewFounderEmailImport.update({
  id: '/email',
  path: '/email',
  getParentRoute: () => NewFounderRoute,
} as any)

const NewFounderCheckEmailRoute = NewFounderCheckEmailImport.update({
  id: '/check-email',
  path: '/check-email',
  getParentRoute: () => NewFounderRoute,
} as any)

const NewDesignerTimeRoute = NewDesignerTimeImport.update({
  id: '/time',
  path: '/time',
  getParentRoute: () => NewDesignerRoute,
} as any)

const NewDesignerSamplesRoute = NewDesignerSamplesImport.update({
  id: '/samples',
  path: '/samples',
  getParentRoute: () => NewDesignerRoute,
} as any)

const NewDesignerLinksRoute = NewDesignerLinksImport.update({
  id: '/links',
  path: '/links',
  getParentRoute: () => NewDesignerRoute,
} as any)

const NewDesignerEmailRoute = NewDesignerEmailImport.update({
  id: '/email',
  path: '/email',
  getParentRoute: () => NewDesignerRoute,
} as any)

const NewDesignerCheckMailRoute = NewDesignerCheckMailImport.update({
  id: '/check-mail',
  path: '/check-mail',
  getParentRoute: () => NewDesignerRoute,
} as any)

const DashboardFounderReceiptRoute = DashboardFounderReceiptImport.update({
  id: '/receipt',
  path: '/receipt',
  getParentRoute: () => DashboardFounderRoute,
} as any)

const DashboardFounderProposalsRoute = DashboardFounderProposalsImport.update({
  id: '/proposals',
  path: '/proposals',
  getParentRoute: () => DashboardFounderRoute,
} as any)

const DashboardFounderInvoiceRoute = DashboardFounderInvoiceImport.update({
  id: '/invoice',
  path: '/invoice',
  getParentRoute: () => DashboardFounderRoute,
} as any)

const DashboardFounderCompanyFormRoute =
  DashboardFounderCompanyFormImport.update({
    id: '/company-form',
    path: '/company-form',
    getParentRoute: () => DashboardFounderRoute,
  } as any)

const DashboardFounderAgreementRoute = DashboardFounderAgreementImport.update({
  id: '/agreement',
  path: '/agreement',
  getParentRoute: () => DashboardFounderRoute,
} as any)

const DashboardAdminSiteReportsRoute = DashboardAdminSiteReportsImport.update({
  id: '/site-reports',
  path: '/site-reports',
  getParentRoute: () => DashboardAdminRoute,
} as any)

const DashboardAdminCreativesRoute = DashboardAdminCreativesImport.update({
  id: '/creatives',
  path: '/creatives',
  getParentRoute: () => DashboardAdminRoute,
} as any)

const DashboardAdminCreateProjectRoute =
  DashboardAdminCreateProjectImport.update({
    id: '/create-project',
    path: '/create-project',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const MiscAboutMissionRoute = MiscAboutMissionImport.update({
  id: '/_misc/about_/mission',
  path: '/about/mission',
  getParentRoute: () => rootRoute,
} as any)

const MiscNewsletterUpdatesRoute = MiscNewsletterUpdatesImport.update({
  id: '/_misc/_newsletter/updates',
  path: '/updates',
  getParentRoute: () => rootRoute,
} as any)

const MiscNewsletterConfirmedRoute = MiscNewsletterConfirmedImport.update({
  id: '/_misc/_newsletter/confirmed',
  path: '/confirmed',
  getParentRoute: () => rootRoute,
} as any)

const OnboardingCreativesTypeIndexRoute =
  OnboardingCreativesTypeIndexImport.update({
    id: '/creatives/$type/',
    path: '/creatives/$type/',
    getParentRoute: () => OnboardingRoute,
  } as any)

const DashboardFounderFounderIndexRoute =
  DashboardFounderFounderIndexImport.update({
    id: '/_founder/',
    path: '/',
    getParentRoute: () => DashboardFounderRoute,
  } as any)

const DashboardDesignerDesignerIndexRoute =
  DashboardDesignerDesignerIndexImport.update({
    id: '/_designer/',
    path: '/',
    getParentRoute: () => DashboardDesignerRoute,
  } as any)

const DashboardAdminAdminIndexRoute = DashboardAdminAdminIndexImport.update({
  id: '/_admin/',
  path: '/',
  getParentRoute: () => DashboardAdminRoute,
} as any)

const OnboardingCreativesTypeTimeZoneRoute =
  OnboardingCreativesTypeTimeZoneImport.update({
    id: '/creatives/$type/time-zone',
    path: '/creatives/$type/time-zone',
    getParentRoute: () => OnboardingRoute,
  } as any)

const OnboardingCreativesTypeLevelsRoute =
  OnboardingCreativesTypeLevelsImport.update({
    id: '/creatives/$type/levels',
    path: '/creatives/$type/levels',
    getParentRoute: () => OnboardingRoute,
  } as any)

const NewFounderPackagesDurationsRoute =
  NewFounderPackagesDurationsImport.update({
    id: '/packages_/durations',
    path: '/packages/durations',
    getParentRoute: () => NewFounderRoute,
  } as any)

const DashboardFounderProposalsIncludedRoute =
  DashboardFounderProposalsIncludedImport.update({
    id: '/proposals_/included',
    path: '/proposals/included',
    getParentRoute: () => DashboardFounderRoute,
  } as any)

const DashboardFounderInvoiceDetailRoute =
  DashboardFounderInvoiceDetailImport.update({
    id: '/invoice_/detail',
    path: '/invoice/detail',
    getParentRoute: () => DashboardFounderRoute,
  } as any)

const DashboardFounderUpdatesUpdatesIndexRoute =
  DashboardFounderUpdatesUpdatesIndexImport.update({
    id: '/updates/_updates/',
    path: '/updates/',
    getParentRoute: () => DashboardFounderRoute,
  } as any)

const DashboardFounderUpdatesUpdateIdIndexRoute =
  DashboardFounderUpdatesUpdateIdIndexImport.update({
    id: '/updates/$updateId/',
    path: '/updates/$updateId/',
    getParentRoute: () => DashboardFounderRoute,
  } as any)

const DashboardDesignerProjectsProjectsIndexRoute =
  DashboardDesignerProjectsProjectsIndexImport.update({
    id: '/projects/_projects/',
    path: '/projects/',
    getParentRoute: () => DashboardDesignerRoute,
  } as any)

const DashboardAdminFoundersFoundersIndexRoute =
  DashboardAdminFoundersFoundersIndexImport.update({
    id: '/founders/_founders/',
    path: '/founders/',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardAdminDesignersDesignerIdIndexRoute =
  DashboardAdminDesignersDesignerIdIndexImport.update({
    id: '/designers/$designerId/',
    path: '/designers/$designerId/',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardFounderUpdatesUpdatesThanksRoute =
  DashboardFounderUpdatesUpdatesThanksImport.update({
    id: '/updates/_updates/thanks',
    path: '/updates/thanks',
    getParentRoute: () => DashboardFounderRoute,
  } as any)

const DashboardFounderUpdatesUpdateIdRateRoute =
  DashboardFounderUpdatesUpdateIdRateImport.update({
    id: '/updates/$updateId/rate',
    path: '/updates/$updateId/rate',
    getParentRoute: () => DashboardFounderRoute,
  } as any)

const DashboardFounderUpdatesUpdateIdFeedbackRoute =
  DashboardFounderUpdatesUpdateIdFeedbackImport.update({
    id: '/updates/$updateId/feedback',
    path: '/updates/$updateId/feedback',
    getParentRoute: () => DashboardFounderRoute,
  } as any)

const DashboardAdminFoundersFounderIdProposalRoute =
  DashboardAdminFoundersFounderIdProposalImport.update({
    id: '/founders/$founderId/proposal',
    path: '/founders/$founderId/proposal',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardAdminFoundersFounderIdInvoiceRoute =
  DashboardAdminFoundersFounderIdInvoiceImport.update({
    id: '/founders/$founderId/invoice',
    path: '/founders/$founderId/invoice',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardAdminFoundersFounderIdInfoRoute =
  DashboardAdminFoundersFounderIdInfoImport.update({
    id: '/founders/$founderId/info',
    path: '/founders/$founderId/info',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardAdminFoundersFounderIdEditRoute =
  DashboardAdminFoundersFounderIdEditImport.update({
    id: '/founders/$founderId/edit',
    path: '/founders/$founderId/edit',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardAdminDesignersDesignerIdRateRoute =
  DashboardAdminDesignersDesignerIdRateImport.update({
    id: '/designers/$designerId/rate',
    path: '/designers/$designerId/rate',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardAdminDesignersDesignerIdInfoRoute =
  DashboardAdminDesignersDesignerIdInfoImport.update({
    id: '/designers/$designerId/info',
    path: '/designers/$designerId/info',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardAdminDesignersDesignerIdAssignRoute =
  DashboardAdminDesignersDesignerIdAssignImport.update({
    id: '/designers/$designerId/assign',
    path: '/designers/$designerId/assign',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardDesignerProjectsProjectIdProjectIdIndexRoute =
  DashboardDesignerProjectsProjectIdProjectIdIndexImport.update({
    id: '/projects/$projectId/_$projectId/',
    path: '/projects/$projectId/',
    getParentRoute: () => DashboardDesignerRoute,
  } as any)

const DashboardAdminFoundersFounderIdFounderIdIndexRoute =
  DashboardAdminFoundersFounderIdFounderIdIndexImport.update({
    id: '/founders/$founderId/_$founderId/',
    path: '/founders/$founderId/',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardDesignerProjectsProjectIdUpdatesUpdatesIndexRoute =
  DashboardDesignerProjectsProjectIdUpdatesUpdatesIndexImport.update({
    id: '/projects/$projectId/updates/_updates/',
    path: '/projects/$projectId/updates/',
    getParentRoute: () => DashboardDesignerRoute,
  } as any)

const DashboardDesignerProjectsProjectIdUpdatesUpdateIdIndexRoute =
  DashboardDesignerProjectsProjectIdUpdatesUpdateIdIndexImport.update({
    id: '/projects/$projectId/updates/$updateId/',
    path: '/projects/$projectId/updates/$updateId/',
    getParentRoute: () => DashboardDesignerRoute,
  } as any)

const DashboardAdminFoundersFounderIdUpdatesUpdatesIndexRoute =
  DashboardAdminFoundersFounderIdUpdatesUpdatesIndexImport.update({
    id: '/founders/$founderId/updates/_updates/',
    path: '/founders/$founderId/updates/',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardAdminFoundersFounderIdUpdatesUpdateIdIndexRoute =
  DashboardAdminFoundersFounderIdUpdatesUpdateIdIndexImport.update({
    id: '/founders/$founderId/updates/$updateId/',
    path: '/founders/$founderId/updates/$updateId/',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardDesignerProjectsProjectIdUpdatesUpdateIdPreviewRoute =
  DashboardDesignerProjectsProjectIdUpdatesUpdateIdPreviewImport.update({
    id: '/projects/$projectId/updates/$updateId/preview',
    path: '/projects/$projectId/updates/$updateId/preview',
    getParentRoute: () => DashboardDesignerRoute,
  } as any)

const DashboardDesignerProjectsProjectIdUpdatesUpdateIdNextRoute =
  DashboardDesignerProjectsProjectIdUpdatesUpdateIdNextImport.update({
    id: '/projects/$projectId/updates/$updateId/next',
    path: '/projects/$projectId/updates/$updateId/next',
    getParentRoute: () => DashboardDesignerRoute,
  } as any)

const DashboardAdminFoundersFounderIdUpdatesUpdateIdPreviewRoute =
  DashboardAdminFoundersFounderIdUpdatesUpdateIdPreviewImport.update({
    id: '/founders/$founderId/updates/$updateId/preview',
    path: '/founders/$founderId/updates/$updateId/preview',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardAdminFoundersFounderIdUpdatesUpdateIdNextRoute =
  DashboardAdminFoundersFounderIdUpdatesUpdateIdNextImport.update({
    id: '/founders/$founderId/updates/$updateId/next',
    path: '/founders/$founderId/updates/$updateId/next',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/dashboard': {
      id: '/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/new': {
      id: '/new'
      path: '/new'
      fullPath: '/new'
      preLoaderRoute: typeof NewImport
      parentRoute: typeof rootRoute
    }
    '/onboarding': {
      id: '/onboarding'
      path: '/onboarding'
      fullPath: '/onboarding'
      preLoaderRoute: typeof OnboardingImport
      parentRoute: typeof rootRoute
    }
    '/_misc/about': {
      id: '/_misc/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof MiscAboutImport
      parentRoute: typeof rootRoute
    }
    '/api/redirect': {
      id: '/api/redirect'
      path: '/api/redirect'
      fullPath: '/api/redirect'
      preLoaderRoute: typeof ApiRedirectImport
      parentRoute: typeof rootRoute
    }
    '/dashboard/admin': {
      id: '/dashboard/admin'
      path: '/admin'
      fullPath: '/dashboard/admin'
      preLoaderRoute: typeof DashboardAdminImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/designer': {
      id: '/dashboard/designer'
      path: '/designer'
      fullPath: '/dashboard/designer'
      preLoaderRoute: typeof DashboardDesignerImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/founder': {
      id: '/dashboard/founder'
      path: '/founder'
      fullPath: '/dashboard/founder'
      preLoaderRoute: typeof DashboardFounderImport
      parentRoute: typeof DashboardImport
    }
    '/new/designer': {
      id: '/new/designer'
      path: '/designer'
      fullPath: '/new/designer'
      preLoaderRoute: typeof NewDesignerImport
      parentRoute: typeof NewImport
    }
    '/new/founder': {
      id: '/new/founder'
      path: '/founder'
      fullPath: '/new/founder'
      preLoaderRoute: typeof NewFounderImport
      parentRoute: typeof NewImport
    }
    '/presentation/creatives': {
      id: '/presentation/creatives'
      path: '/presentation/creatives'
      fullPath: '/presentation/creatives'
      preLoaderRoute: typeof PresentationCreativesImport
      parentRoute: typeof rootRoute
    }
    '/presentation/founder': {
      id: '/presentation/founder'
      path: '/presentation/founder'
      fullPath: '/presentation/founder'
      preLoaderRoute: typeof PresentationFounderImport
      parentRoute: typeof rootRoute
    }
    '/new/': {
      id: '/new/'
      path: '/'
      fullPath: '/new/'
      preLoaderRoute: typeof NewIndexImport
      parentRoute: typeof NewImport
    }
    '/_misc/_newsletter/confirmed': {
      id: '/_misc/_newsletter/confirmed'
      path: '/confirmed'
      fullPath: '/confirmed'
      preLoaderRoute: typeof MiscNewsletterConfirmedImport
      parentRoute: typeof rootRoute
    }
    '/_misc/_newsletter/updates': {
      id: '/_misc/_newsletter/updates'
      path: '/updates'
      fullPath: '/updates'
      preLoaderRoute: typeof MiscNewsletterUpdatesImport
      parentRoute: typeof rootRoute
    }
    '/_misc/about_/mission': {
      id: '/_misc/about_/mission'
      path: '/about/mission'
      fullPath: '/about/mission'
      preLoaderRoute: typeof MiscAboutMissionImport
      parentRoute: typeof rootRoute
    }
    '/dashboard/admin/create-project': {
      id: '/dashboard/admin/create-project'
      path: '/create-project'
      fullPath: '/dashboard/admin/create-project'
      preLoaderRoute: typeof DashboardAdminCreateProjectImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/creatives': {
      id: '/dashboard/admin/creatives'
      path: '/creatives'
      fullPath: '/dashboard/admin/creatives'
      preLoaderRoute: typeof DashboardAdminCreativesImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/site-reports': {
      id: '/dashboard/admin/site-reports'
      path: '/site-reports'
      fullPath: '/dashboard/admin/site-reports'
      preLoaderRoute: typeof DashboardAdminSiteReportsImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/founder/agreement': {
      id: '/dashboard/founder/agreement'
      path: '/agreement'
      fullPath: '/dashboard/founder/agreement'
      preLoaderRoute: typeof DashboardFounderAgreementImport
      parentRoute: typeof DashboardFounderImport
    }
    '/dashboard/founder/company-form': {
      id: '/dashboard/founder/company-form'
      path: '/company-form'
      fullPath: '/dashboard/founder/company-form'
      preLoaderRoute: typeof DashboardFounderCompanyFormImport
      parentRoute: typeof DashboardFounderImport
    }
    '/dashboard/founder/invoice': {
      id: '/dashboard/founder/invoice'
      path: '/invoice'
      fullPath: '/dashboard/founder/invoice'
      preLoaderRoute: typeof DashboardFounderInvoiceImport
      parentRoute: typeof DashboardFounderImport
    }
    '/dashboard/founder/proposals': {
      id: '/dashboard/founder/proposals'
      path: '/proposals'
      fullPath: '/dashboard/founder/proposals'
      preLoaderRoute: typeof DashboardFounderProposalsImport
      parentRoute: typeof DashboardFounderImport
    }
    '/dashboard/founder/receipt': {
      id: '/dashboard/founder/receipt'
      path: '/receipt'
      fullPath: '/dashboard/founder/receipt'
      preLoaderRoute: typeof DashboardFounderReceiptImport
      parentRoute: typeof DashboardFounderImport
    }
    '/new/designer/check-mail': {
      id: '/new/designer/check-mail'
      path: '/check-mail'
      fullPath: '/new/designer/check-mail'
      preLoaderRoute: typeof NewDesignerCheckMailImport
      parentRoute: typeof NewDesignerImport
    }
    '/new/designer/email': {
      id: '/new/designer/email'
      path: '/email'
      fullPath: '/new/designer/email'
      preLoaderRoute: typeof NewDesignerEmailImport
      parentRoute: typeof NewDesignerImport
    }
    '/new/designer/links': {
      id: '/new/designer/links'
      path: '/links'
      fullPath: '/new/designer/links'
      preLoaderRoute: typeof NewDesignerLinksImport
      parentRoute: typeof NewDesignerImport
    }
    '/new/designer/samples': {
      id: '/new/designer/samples'
      path: '/samples'
      fullPath: '/new/designer/samples'
      preLoaderRoute: typeof NewDesignerSamplesImport
      parentRoute: typeof NewDesignerImport
    }
    '/new/designer/time': {
      id: '/new/designer/time'
      path: '/time'
      fullPath: '/new/designer/time'
      preLoaderRoute: typeof NewDesignerTimeImport
      parentRoute: typeof NewDesignerImport
    }
    '/new/founder/check-email': {
      id: '/new/founder/check-email'
      path: '/check-email'
      fullPath: '/new/founder/check-email'
      preLoaderRoute: typeof NewFounderCheckEmailImport
      parentRoute: typeof NewFounderImport
    }
    '/new/founder/email': {
      id: '/new/founder/email'
      path: '/email'
      fullPath: '/new/founder/email'
      preLoaderRoute: typeof NewFounderEmailImport
      parentRoute: typeof NewFounderImport
    }
    '/new/founder/packages': {
      id: '/new/founder/packages'
      path: '/packages'
      fullPath: '/new/founder/packages'
      preLoaderRoute: typeof NewFounderPackagesImport
      parentRoute: typeof NewFounderImport
    }
    '/new/founder/packages-and-plan': {
      id: '/new/founder/packages-and-plan'
      path: '/packages-and-plan'
      fullPath: '/new/founder/packages-and-plan'
      preLoaderRoute: typeof NewFounderPackagesAndPlanImport
      parentRoute: typeof NewFounderImport
    }
    '/new/founder/plans': {
      id: '/new/founder/plans'
      path: '/plans'
      fullPath: '/new/founder/plans'
      preLoaderRoute: typeof NewFounderPlansImport
      parentRoute: typeof NewFounderImport
    }
    '/new/founder/time': {
      id: '/new/founder/time'
      path: '/time'
      fullPath: '/new/founder/time'
      preLoaderRoute: typeof NewFounderTimeImport
      parentRoute: typeof NewFounderImport
    }
    '/onboarding/founder/company': {
      id: '/onboarding/founder/company'
      path: '/founder/company'
      fullPath: '/onboarding/founder/company'
      preLoaderRoute: typeof OnboardingFounderCompanyImport
      parentRoute: typeof OnboardingImport
    }
    '/onboarding/founder/links': {
      id: '/onboarding/founder/links'
      path: '/founder/links'
      fullPath: '/onboarding/founder/links'
      preLoaderRoute: typeof OnboardingFounderLinksImport
      parentRoute: typeof OnboardingImport
    }
    '/onboarding/founder/mail-preference': {
      id: '/onboarding/founder/mail-preference'
      path: '/founder/mail-preference'
      fullPath: '/onboarding/founder/mail-preference'
      preLoaderRoute: typeof OnboardingFounderMailPreferenceImport
      parentRoute: typeof OnboardingImport
    }
    '/onboarding/founder/outcome': {
      id: '/onboarding/founder/outcome'
      path: '/founder/outcome'
      fullPath: '/onboarding/founder/outcome'
      preLoaderRoute: typeof OnboardingFounderOutcomeImport
      parentRoute: typeof OnboardingImport
    }
    '/onboarding/founder/timezone': {
      id: '/onboarding/founder/timezone'
      path: '/founder/timezone'
      fullPath: '/onboarding/founder/timezone'
      preLoaderRoute: typeof OnboardingFounderTimezoneImport
      parentRoute: typeof OnboardingImport
    }
    '/new/designer/': {
      id: '/new/designer/'
      path: '/'
      fullPath: '/new/designer/'
      preLoaderRoute: typeof NewDesignerIndexImport
      parentRoute: typeof NewDesignerImport
    }
    '/new/founder/': {
      id: '/new/founder/'
      path: '/'
      fullPath: '/new/founder/'
      preLoaderRoute: typeof NewFounderIndexImport
      parentRoute: typeof NewFounderImport
    }
    '/onboarding/founder/': {
      id: '/onboarding/founder/'
      path: '/founder'
      fullPath: '/onboarding/founder'
      preLoaderRoute: typeof OnboardingFounderIndexImport
      parentRoute: typeof OnboardingImport
    }
    '/samples/_samples/': {
      id: '/samples/_samples/'
      path: '/samples'
      fullPath: '/samples'
      preLoaderRoute: typeof SamplesSamplesIndexImport
      parentRoute: typeof rootRoute
    }
    '/dashboard/founder/invoice_/detail': {
      id: '/dashboard/founder/invoice_/detail'
      path: '/invoice/detail'
      fullPath: '/dashboard/founder/invoice/detail'
      preLoaderRoute: typeof DashboardFounderInvoiceDetailImport
      parentRoute: typeof DashboardFounderImport
    }
    '/dashboard/founder/proposals_/included': {
      id: '/dashboard/founder/proposals_/included'
      path: '/proposals/included'
      fullPath: '/dashboard/founder/proposals/included'
      preLoaderRoute: typeof DashboardFounderProposalsIncludedImport
      parentRoute: typeof DashboardFounderImport
    }
    '/new/founder/packages_/durations': {
      id: '/new/founder/packages_/durations'
      path: '/packages/durations'
      fullPath: '/new/founder/packages/durations'
      preLoaderRoute: typeof NewFounderPackagesDurationsImport
      parentRoute: typeof NewFounderImport
    }
    '/onboarding/creatives/$type/levels': {
      id: '/onboarding/creatives/$type/levels'
      path: '/creatives/$type/levels'
      fullPath: '/onboarding/creatives/$type/levels'
      preLoaderRoute: typeof OnboardingCreativesTypeLevelsImport
      parentRoute: typeof OnboardingImport
    }
    '/onboarding/creatives/$type/time-zone': {
      id: '/onboarding/creatives/$type/time-zone'
      path: '/creatives/$type/time-zone'
      fullPath: '/onboarding/creatives/$type/time-zone'
      preLoaderRoute: typeof OnboardingCreativesTypeTimeZoneImport
      parentRoute: typeof OnboardingImport
    }
    '/dashboard/admin/_admin/': {
      id: '/dashboard/admin/_admin/'
      path: '/'
      fullPath: '/dashboard/admin/'
      preLoaderRoute: typeof DashboardAdminAdminIndexImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/designer/_designer/': {
      id: '/dashboard/designer/_designer/'
      path: '/'
      fullPath: '/dashboard/designer/'
      preLoaderRoute: typeof DashboardDesignerDesignerIndexImport
      parentRoute: typeof DashboardDesignerImport
    }
    '/dashboard/founder/_founder/': {
      id: '/dashboard/founder/_founder/'
      path: '/'
      fullPath: '/dashboard/founder/'
      preLoaderRoute: typeof DashboardFounderFounderIndexImport
      parentRoute: typeof DashboardFounderImport
    }
    '/onboarding/creatives/$type/': {
      id: '/onboarding/creatives/$type/'
      path: '/creatives/$type'
      fullPath: '/onboarding/creatives/$type'
      preLoaderRoute: typeof OnboardingCreativesTypeIndexImport
      parentRoute: typeof OnboardingImport
    }
    '/dashboard/admin/designers/$designerId/assign': {
      id: '/dashboard/admin/designers/$designerId/assign'
      path: '/designers/$designerId/assign'
      fullPath: '/dashboard/admin/designers/$designerId/assign'
      preLoaderRoute: typeof DashboardAdminDesignersDesignerIdAssignImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/designers/$designerId/info': {
      id: '/dashboard/admin/designers/$designerId/info'
      path: '/designers/$designerId/info'
      fullPath: '/dashboard/admin/designers/$designerId/info'
      preLoaderRoute: typeof DashboardAdminDesignersDesignerIdInfoImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/designers/$designerId/rate': {
      id: '/dashboard/admin/designers/$designerId/rate'
      path: '/designers/$designerId/rate'
      fullPath: '/dashboard/admin/designers/$designerId/rate'
      preLoaderRoute: typeof DashboardAdminDesignersDesignerIdRateImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/founders/$founderId/edit': {
      id: '/dashboard/admin/founders/$founderId/edit'
      path: '/founders/$founderId/edit'
      fullPath: '/dashboard/admin/founders/$founderId/edit'
      preLoaderRoute: typeof DashboardAdminFoundersFounderIdEditImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/founders/$founderId/info': {
      id: '/dashboard/admin/founders/$founderId/info'
      path: '/founders/$founderId/info'
      fullPath: '/dashboard/admin/founders/$founderId/info'
      preLoaderRoute: typeof DashboardAdminFoundersFounderIdInfoImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/founders/$founderId/invoice': {
      id: '/dashboard/admin/founders/$founderId/invoice'
      path: '/founders/$founderId/invoice'
      fullPath: '/dashboard/admin/founders/$founderId/invoice'
      preLoaderRoute: typeof DashboardAdminFoundersFounderIdInvoiceImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/founders/$founderId/proposal': {
      id: '/dashboard/admin/founders/$founderId/proposal'
      path: '/founders/$founderId/proposal'
      fullPath: '/dashboard/admin/founders/$founderId/proposal'
      preLoaderRoute: typeof DashboardAdminFoundersFounderIdProposalImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/founder/updates/$updateId/feedback': {
      id: '/dashboard/founder/updates/$updateId/feedback'
      path: '/updates/$updateId/feedback'
      fullPath: '/dashboard/founder/updates/$updateId/feedback'
      preLoaderRoute: typeof DashboardFounderUpdatesUpdateIdFeedbackImport
      parentRoute: typeof DashboardFounderImport
    }
    '/dashboard/founder/updates/$updateId/rate': {
      id: '/dashboard/founder/updates/$updateId/rate'
      path: '/updates/$updateId/rate'
      fullPath: '/dashboard/founder/updates/$updateId/rate'
      preLoaderRoute: typeof DashboardFounderUpdatesUpdateIdRateImport
      parentRoute: typeof DashboardFounderImport
    }
    '/dashboard/founder/updates/_updates/thanks': {
      id: '/dashboard/founder/updates/_updates/thanks'
      path: '/updates/thanks'
      fullPath: '/dashboard/founder/updates/thanks'
      preLoaderRoute: typeof DashboardFounderUpdatesUpdatesThanksImport
      parentRoute: typeof DashboardFounderImport
    }
    '/dashboard/admin/designers/$designerId/': {
      id: '/dashboard/admin/designers/$designerId/'
      path: '/designers/$designerId'
      fullPath: '/dashboard/admin/designers/$designerId'
      preLoaderRoute: typeof DashboardAdminDesignersDesignerIdIndexImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/founders/_founders/': {
      id: '/dashboard/admin/founders/_founders/'
      path: '/founders'
      fullPath: '/dashboard/admin/founders'
      preLoaderRoute: typeof DashboardAdminFoundersFoundersIndexImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/designer/projects/_projects/': {
      id: '/dashboard/designer/projects/_projects/'
      path: '/projects'
      fullPath: '/dashboard/designer/projects'
      preLoaderRoute: typeof DashboardDesignerProjectsProjectsIndexImport
      parentRoute: typeof DashboardDesignerImport
    }
    '/dashboard/founder/updates/$updateId/': {
      id: '/dashboard/founder/updates/$updateId/'
      path: '/updates/$updateId'
      fullPath: '/dashboard/founder/updates/$updateId'
      preLoaderRoute: typeof DashboardFounderUpdatesUpdateIdIndexImport
      parentRoute: typeof DashboardFounderImport
    }
    '/dashboard/founder/updates/_updates/': {
      id: '/dashboard/founder/updates/_updates/'
      path: '/updates'
      fullPath: '/dashboard/founder/updates'
      preLoaderRoute: typeof DashboardFounderUpdatesUpdatesIndexImport
      parentRoute: typeof DashboardFounderImport
    }
    '/dashboard/admin/founders/$founderId/_$founderId/': {
      id: '/dashboard/admin/founders/$founderId/_$founderId/'
      path: '/founders/$founderId'
      fullPath: '/dashboard/admin/founders/$founderId'
      preLoaderRoute: typeof DashboardAdminFoundersFounderIdFounderIdIndexImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/designer/projects/$projectId/_$projectId/': {
      id: '/dashboard/designer/projects/$projectId/_$projectId/'
      path: '/projects/$projectId'
      fullPath: '/dashboard/designer/projects/$projectId'
      preLoaderRoute: typeof DashboardDesignerProjectsProjectIdProjectIdIndexImport
      parentRoute: typeof DashboardDesignerImport
    }
    '/dashboard/admin/founders/$founderId/updates/$updateId/next': {
      id: '/dashboard/admin/founders/$founderId/updates/$updateId/next'
      path: '/founders/$founderId/updates/$updateId/next'
      fullPath: '/dashboard/admin/founders/$founderId/updates/$updateId/next'
      preLoaderRoute: typeof DashboardAdminFoundersFounderIdUpdatesUpdateIdNextImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/founders/$founderId/updates/$updateId/preview': {
      id: '/dashboard/admin/founders/$founderId/updates/$updateId/preview'
      path: '/founders/$founderId/updates/$updateId/preview'
      fullPath: '/dashboard/admin/founders/$founderId/updates/$updateId/preview'
      preLoaderRoute: typeof DashboardAdminFoundersFounderIdUpdatesUpdateIdPreviewImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/designer/projects/$projectId/updates/$updateId/next': {
      id: '/dashboard/designer/projects/$projectId/updates/$updateId/next'
      path: '/projects/$projectId/updates/$updateId/next'
      fullPath: '/dashboard/designer/projects/$projectId/updates/$updateId/next'
      preLoaderRoute: typeof DashboardDesignerProjectsProjectIdUpdatesUpdateIdNextImport
      parentRoute: typeof DashboardDesignerImport
    }
    '/dashboard/designer/projects/$projectId/updates/$updateId/preview': {
      id: '/dashboard/designer/projects/$projectId/updates/$updateId/preview'
      path: '/projects/$projectId/updates/$updateId/preview'
      fullPath: '/dashboard/designer/projects/$projectId/updates/$updateId/preview'
      preLoaderRoute: typeof DashboardDesignerProjectsProjectIdUpdatesUpdateIdPreviewImport
      parentRoute: typeof DashboardDesignerImport
    }
    '/dashboard/admin/founders/$founderId/updates/$updateId/': {
      id: '/dashboard/admin/founders/$founderId/updates/$updateId/'
      path: '/founders/$founderId/updates/$updateId'
      fullPath: '/dashboard/admin/founders/$founderId/updates/$updateId'
      preLoaderRoute: typeof DashboardAdminFoundersFounderIdUpdatesUpdateIdIndexImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/founders/$founderId/updates/_updates/': {
      id: '/dashboard/admin/founders/$founderId/updates/_updates/'
      path: '/founders/$founderId/updates'
      fullPath: '/dashboard/admin/founders/$founderId/updates'
      preLoaderRoute: typeof DashboardAdminFoundersFounderIdUpdatesUpdatesIndexImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/designer/projects/$projectId/updates/$updateId/': {
      id: '/dashboard/designer/projects/$projectId/updates/$updateId/'
      path: '/projects/$projectId/updates/$updateId'
      fullPath: '/dashboard/designer/projects/$projectId/updates/$updateId'
      preLoaderRoute: typeof DashboardDesignerProjectsProjectIdUpdatesUpdateIdIndexImport
      parentRoute: typeof DashboardDesignerImport
    }
    '/dashboard/designer/projects/$projectId/updates/_updates/': {
      id: '/dashboard/designer/projects/$projectId/updates/_updates/'
      path: '/projects/$projectId/updates'
      fullPath: '/dashboard/designer/projects/$projectId/updates'
      preLoaderRoute: typeof DashboardDesignerProjectsProjectIdUpdatesUpdatesIndexImport
      parentRoute: typeof DashboardDesignerImport
    }
  }
}

// Create and export the route tree

interface DashboardAdminRouteChildren {
  DashboardAdminCreateProjectRoute: typeof DashboardAdminCreateProjectRoute
  DashboardAdminCreativesRoute: typeof DashboardAdminCreativesRoute
  DashboardAdminSiteReportsRoute: typeof DashboardAdminSiteReportsRoute
  DashboardAdminAdminIndexRoute: typeof DashboardAdminAdminIndexRoute
  DashboardAdminDesignersDesignerIdAssignRoute: typeof DashboardAdminDesignersDesignerIdAssignRoute
  DashboardAdminDesignersDesignerIdInfoRoute: typeof DashboardAdminDesignersDesignerIdInfoRoute
  DashboardAdminDesignersDesignerIdRateRoute: typeof DashboardAdminDesignersDesignerIdRateRoute
  DashboardAdminFoundersFounderIdEditRoute: typeof DashboardAdminFoundersFounderIdEditRoute
  DashboardAdminFoundersFounderIdInfoRoute: typeof DashboardAdminFoundersFounderIdInfoRoute
  DashboardAdminFoundersFounderIdInvoiceRoute: typeof DashboardAdminFoundersFounderIdInvoiceRoute
  DashboardAdminFoundersFounderIdProposalRoute: typeof DashboardAdminFoundersFounderIdProposalRoute
  DashboardAdminDesignersDesignerIdIndexRoute: typeof DashboardAdminDesignersDesignerIdIndexRoute
  DashboardAdminFoundersFoundersIndexRoute: typeof DashboardAdminFoundersFoundersIndexRoute
  DashboardAdminFoundersFounderIdFounderIdIndexRoute: typeof DashboardAdminFoundersFounderIdFounderIdIndexRoute
  DashboardAdminFoundersFounderIdUpdatesUpdateIdNextRoute: typeof DashboardAdminFoundersFounderIdUpdatesUpdateIdNextRoute
  DashboardAdminFoundersFounderIdUpdatesUpdateIdPreviewRoute: typeof DashboardAdminFoundersFounderIdUpdatesUpdateIdPreviewRoute
  DashboardAdminFoundersFounderIdUpdatesUpdateIdIndexRoute: typeof DashboardAdminFoundersFounderIdUpdatesUpdateIdIndexRoute
  DashboardAdminFoundersFounderIdUpdatesUpdatesIndexRoute: typeof DashboardAdminFoundersFounderIdUpdatesUpdatesIndexRoute
}

const DashboardAdminRouteChildren: DashboardAdminRouteChildren = {
  DashboardAdminCreateProjectRoute: DashboardAdminCreateProjectRoute,
  DashboardAdminCreativesRoute: DashboardAdminCreativesRoute,
  DashboardAdminSiteReportsRoute: DashboardAdminSiteReportsRoute,
  DashboardAdminAdminIndexRoute: DashboardAdminAdminIndexRoute,
  DashboardAdminDesignersDesignerIdAssignRoute:
    DashboardAdminDesignersDesignerIdAssignRoute,
  DashboardAdminDesignersDesignerIdInfoRoute:
    DashboardAdminDesignersDesignerIdInfoRoute,
  DashboardAdminDesignersDesignerIdRateRoute:
    DashboardAdminDesignersDesignerIdRateRoute,
  DashboardAdminFoundersFounderIdEditRoute:
    DashboardAdminFoundersFounderIdEditRoute,
  DashboardAdminFoundersFounderIdInfoRoute:
    DashboardAdminFoundersFounderIdInfoRoute,
  DashboardAdminFoundersFounderIdInvoiceRoute:
    DashboardAdminFoundersFounderIdInvoiceRoute,
  DashboardAdminFoundersFounderIdProposalRoute:
    DashboardAdminFoundersFounderIdProposalRoute,
  DashboardAdminDesignersDesignerIdIndexRoute:
    DashboardAdminDesignersDesignerIdIndexRoute,
  DashboardAdminFoundersFoundersIndexRoute:
    DashboardAdminFoundersFoundersIndexRoute,
  DashboardAdminFoundersFounderIdFounderIdIndexRoute:
    DashboardAdminFoundersFounderIdFounderIdIndexRoute,
  DashboardAdminFoundersFounderIdUpdatesUpdateIdNextRoute:
    DashboardAdminFoundersFounderIdUpdatesUpdateIdNextRoute,
  DashboardAdminFoundersFounderIdUpdatesUpdateIdPreviewRoute:
    DashboardAdminFoundersFounderIdUpdatesUpdateIdPreviewRoute,
  DashboardAdminFoundersFounderIdUpdatesUpdateIdIndexRoute:
    DashboardAdminFoundersFounderIdUpdatesUpdateIdIndexRoute,
  DashboardAdminFoundersFounderIdUpdatesUpdatesIndexRoute:
    DashboardAdminFoundersFounderIdUpdatesUpdatesIndexRoute,
}

const DashboardAdminRouteWithChildren = DashboardAdminRoute._addFileChildren(
  DashboardAdminRouteChildren,
)

interface DashboardDesignerRouteChildren {
  DashboardDesignerDesignerIndexRoute: typeof DashboardDesignerDesignerIndexRoute
  DashboardDesignerProjectsProjectsIndexRoute: typeof DashboardDesignerProjectsProjectsIndexRoute
  DashboardDesignerProjectsProjectIdProjectIdIndexRoute: typeof DashboardDesignerProjectsProjectIdProjectIdIndexRoute
  DashboardDesignerProjectsProjectIdUpdatesUpdateIdNextRoute: typeof DashboardDesignerProjectsProjectIdUpdatesUpdateIdNextRoute
  DashboardDesignerProjectsProjectIdUpdatesUpdateIdPreviewRoute: typeof DashboardDesignerProjectsProjectIdUpdatesUpdateIdPreviewRoute
  DashboardDesignerProjectsProjectIdUpdatesUpdateIdIndexRoute: typeof DashboardDesignerProjectsProjectIdUpdatesUpdateIdIndexRoute
  DashboardDesignerProjectsProjectIdUpdatesUpdatesIndexRoute: typeof DashboardDesignerProjectsProjectIdUpdatesUpdatesIndexRoute
}

const DashboardDesignerRouteChildren: DashboardDesignerRouteChildren = {
  DashboardDesignerDesignerIndexRoute: DashboardDesignerDesignerIndexRoute,
  DashboardDesignerProjectsProjectsIndexRoute:
    DashboardDesignerProjectsProjectsIndexRoute,
  DashboardDesignerProjectsProjectIdProjectIdIndexRoute:
    DashboardDesignerProjectsProjectIdProjectIdIndexRoute,
  DashboardDesignerProjectsProjectIdUpdatesUpdateIdNextRoute:
    DashboardDesignerProjectsProjectIdUpdatesUpdateIdNextRoute,
  DashboardDesignerProjectsProjectIdUpdatesUpdateIdPreviewRoute:
    DashboardDesignerProjectsProjectIdUpdatesUpdateIdPreviewRoute,
  DashboardDesignerProjectsProjectIdUpdatesUpdateIdIndexRoute:
    DashboardDesignerProjectsProjectIdUpdatesUpdateIdIndexRoute,
  DashboardDesignerProjectsProjectIdUpdatesUpdatesIndexRoute:
    DashboardDesignerProjectsProjectIdUpdatesUpdatesIndexRoute,
}

const DashboardDesignerRouteWithChildren =
  DashboardDesignerRoute._addFileChildren(DashboardDesignerRouteChildren)

interface DashboardFounderRouteChildren {
  DashboardFounderAgreementRoute: typeof DashboardFounderAgreementRoute
  DashboardFounderCompanyFormRoute: typeof DashboardFounderCompanyFormRoute
  DashboardFounderInvoiceRoute: typeof DashboardFounderInvoiceRoute
  DashboardFounderProposalsRoute: typeof DashboardFounderProposalsRoute
  DashboardFounderReceiptRoute: typeof DashboardFounderReceiptRoute
  DashboardFounderInvoiceDetailRoute: typeof DashboardFounderInvoiceDetailRoute
  DashboardFounderProposalsIncludedRoute: typeof DashboardFounderProposalsIncludedRoute
  DashboardFounderFounderIndexRoute: typeof DashboardFounderFounderIndexRoute
  DashboardFounderUpdatesUpdateIdFeedbackRoute: typeof DashboardFounderUpdatesUpdateIdFeedbackRoute
  DashboardFounderUpdatesUpdateIdRateRoute: typeof DashboardFounderUpdatesUpdateIdRateRoute
  DashboardFounderUpdatesUpdatesThanksRoute: typeof DashboardFounderUpdatesUpdatesThanksRoute
  DashboardFounderUpdatesUpdateIdIndexRoute: typeof DashboardFounderUpdatesUpdateIdIndexRoute
  DashboardFounderUpdatesUpdatesIndexRoute: typeof DashboardFounderUpdatesUpdatesIndexRoute
}

const DashboardFounderRouteChildren: DashboardFounderRouteChildren = {
  DashboardFounderAgreementRoute: DashboardFounderAgreementRoute,
  DashboardFounderCompanyFormRoute: DashboardFounderCompanyFormRoute,
  DashboardFounderInvoiceRoute: DashboardFounderInvoiceRoute,
  DashboardFounderProposalsRoute: DashboardFounderProposalsRoute,
  DashboardFounderReceiptRoute: DashboardFounderReceiptRoute,
  DashboardFounderInvoiceDetailRoute: DashboardFounderInvoiceDetailRoute,
  DashboardFounderProposalsIncludedRoute:
    DashboardFounderProposalsIncludedRoute,
  DashboardFounderFounderIndexRoute: DashboardFounderFounderIndexRoute,
  DashboardFounderUpdatesUpdateIdFeedbackRoute:
    DashboardFounderUpdatesUpdateIdFeedbackRoute,
  DashboardFounderUpdatesUpdateIdRateRoute:
    DashboardFounderUpdatesUpdateIdRateRoute,
  DashboardFounderUpdatesUpdatesThanksRoute:
    DashboardFounderUpdatesUpdatesThanksRoute,
  DashboardFounderUpdatesUpdateIdIndexRoute:
    DashboardFounderUpdatesUpdateIdIndexRoute,
  DashboardFounderUpdatesUpdatesIndexRoute:
    DashboardFounderUpdatesUpdatesIndexRoute,
}

const DashboardFounderRouteWithChildren =
  DashboardFounderRoute._addFileChildren(DashboardFounderRouteChildren)

interface DashboardRouteChildren {
  DashboardAdminRoute: typeof DashboardAdminRouteWithChildren
  DashboardDesignerRoute: typeof DashboardDesignerRouteWithChildren
  DashboardFounderRoute: typeof DashboardFounderRouteWithChildren
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardAdminRoute: DashboardAdminRouteWithChildren,
  DashboardDesignerRoute: DashboardDesignerRouteWithChildren,
  DashboardFounderRoute: DashboardFounderRouteWithChildren,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

interface NewDesignerRouteChildren {
  NewDesignerCheckMailRoute: typeof NewDesignerCheckMailRoute
  NewDesignerEmailRoute: typeof NewDesignerEmailRoute
  NewDesignerLinksRoute: typeof NewDesignerLinksRoute
  NewDesignerSamplesRoute: typeof NewDesignerSamplesRoute
  NewDesignerTimeRoute: typeof NewDesignerTimeRoute
  NewDesignerIndexRoute: typeof NewDesignerIndexRoute
}

const NewDesignerRouteChildren: NewDesignerRouteChildren = {
  NewDesignerCheckMailRoute: NewDesignerCheckMailRoute,
  NewDesignerEmailRoute: NewDesignerEmailRoute,
  NewDesignerLinksRoute: NewDesignerLinksRoute,
  NewDesignerSamplesRoute: NewDesignerSamplesRoute,
  NewDesignerTimeRoute: NewDesignerTimeRoute,
  NewDesignerIndexRoute: NewDesignerIndexRoute,
}

const NewDesignerRouteWithChildren = NewDesignerRoute._addFileChildren(
  NewDesignerRouteChildren,
)

interface NewFounderRouteChildren {
  NewFounderCheckEmailRoute: typeof NewFounderCheckEmailRoute
  NewFounderEmailRoute: typeof NewFounderEmailRoute
  NewFounderPackagesRoute: typeof NewFounderPackagesRoute
  NewFounderPackagesAndPlanRoute: typeof NewFounderPackagesAndPlanRoute
  NewFounderPlansRoute: typeof NewFounderPlansRoute
  NewFounderTimeRoute: typeof NewFounderTimeRoute
  NewFounderIndexRoute: typeof NewFounderIndexRoute
  NewFounderPackagesDurationsRoute: typeof NewFounderPackagesDurationsRoute
}

const NewFounderRouteChildren: NewFounderRouteChildren = {
  NewFounderCheckEmailRoute: NewFounderCheckEmailRoute,
  NewFounderEmailRoute: NewFounderEmailRoute,
  NewFounderPackagesRoute: NewFounderPackagesRoute,
  NewFounderPackagesAndPlanRoute: NewFounderPackagesAndPlanRoute,
  NewFounderPlansRoute: NewFounderPlansRoute,
  NewFounderTimeRoute: NewFounderTimeRoute,
  NewFounderIndexRoute: NewFounderIndexRoute,
  NewFounderPackagesDurationsRoute: NewFounderPackagesDurationsRoute,
}

const NewFounderRouteWithChildren = NewFounderRoute._addFileChildren(
  NewFounderRouteChildren,
)

interface NewRouteChildren {
  NewDesignerRoute: typeof NewDesignerRouteWithChildren
  NewFounderRoute: typeof NewFounderRouteWithChildren
  NewIndexRoute: typeof NewIndexRoute
}

const NewRouteChildren: NewRouteChildren = {
  NewDesignerRoute: NewDesignerRouteWithChildren,
  NewFounderRoute: NewFounderRouteWithChildren,
  NewIndexRoute: NewIndexRoute,
}

const NewRouteWithChildren = NewRoute._addFileChildren(NewRouteChildren)

interface OnboardingRouteChildren {
  OnboardingFounderCompanyRoute: typeof OnboardingFounderCompanyRoute
  OnboardingFounderLinksRoute: typeof OnboardingFounderLinksRoute
  OnboardingFounderMailPreferenceRoute: typeof OnboardingFounderMailPreferenceRoute
  OnboardingFounderOutcomeRoute: typeof OnboardingFounderOutcomeRoute
  OnboardingFounderTimezoneRoute: typeof OnboardingFounderTimezoneRoute
  OnboardingFounderIndexRoute: typeof OnboardingFounderIndexRoute
  OnboardingCreativesTypeLevelsRoute: typeof OnboardingCreativesTypeLevelsRoute
  OnboardingCreativesTypeTimeZoneRoute: typeof OnboardingCreativesTypeTimeZoneRoute
  OnboardingCreativesTypeIndexRoute: typeof OnboardingCreativesTypeIndexRoute
}

const OnboardingRouteChildren: OnboardingRouteChildren = {
  OnboardingFounderCompanyRoute: OnboardingFounderCompanyRoute,
  OnboardingFounderLinksRoute: OnboardingFounderLinksRoute,
  OnboardingFounderMailPreferenceRoute: OnboardingFounderMailPreferenceRoute,
  OnboardingFounderOutcomeRoute: OnboardingFounderOutcomeRoute,
  OnboardingFounderTimezoneRoute: OnboardingFounderTimezoneRoute,
  OnboardingFounderIndexRoute: OnboardingFounderIndexRoute,
  OnboardingCreativesTypeLevelsRoute: OnboardingCreativesTypeLevelsRoute,
  OnboardingCreativesTypeTimeZoneRoute: OnboardingCreativesTypeTimeZoneRoute,
  OnboardingCreativesTypeIndexRoute: OnboardingCreativesTypeIndexRoute,
}

const OnboardingRouteWithChildren = OnboardingRoute._addFileChildren(
  OnboardingRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/login': typeof LoginRoute
  '/new': typeof NewRouteWithChildren
  '/onboarding': typeof OnboardingRouteWithChildren
  '/about': typeof MiscAboutRoute
  '/api/redirect': typeof ApiRedirectRoute
  '/dashboard/admin': typeof DashboardAdminRouteWithChildren
  '/dashboard/designer': typeof DashboardDesignerRouteWithChildren
  '/dashboard/founder': typeof DashboardFounderRouteWithChildren
  '/new/designer': typeof NewDesignerRouteWithChildren
  '/new/founder': typeof NewFounderRouteWithChildren
  '/presentation/creatives': typeof PresentationCreativesRoute
  '/presentation/founder': typeof PresentationFounderRoute
  '/new/': typeof NewIndexRoute
  '/confirmed': typeof MiscNewsletterConfirmedRoute
  '/updates': typeof MiscNewsletterUpdatesRoute
  '/about/mission': typeof MiscAboutMissionRoute
  '/dashboard/admin/create-project': typeof DashboardAdminCreateProjectRoute
  '/dashboard/admin/creatives': typeof DashboardAdminCreativesRoute
  '/dashboard/admin/site-reports': typeof DashboardAdminSiteReportsRoute
  '/dashboard/founder/agreement': typeof DashboardFounderAgreementRoute
  '/dashboard/founder/company-form': typeof DashboardFounderCompanyFormRoute
  '/dashboard/founder/invoice': typeof DashboardFounderInvoiceRoute
  '/dashboard/founder/proposals': typeof DashboardFounderProposalsRoute
  '/dashboard/founder/receipt': typeof DashboardFounderReceiptRoute
  '/new/designer/check-mail': typeof NewDesignerCheckMailRoute
  '/new/designer/email': typeof NewDesignerEmailRoute
  '/new/designer/links': typeof NewDesignerLinksRoute
  '/new/designer/samples': typeof NewDesignerSamplesRoute
  '/new/designer/time': typeof NewDesignerTimeRoute
  '/new/founder/check-email': typeof NewFounderCheckEmailRoute
  '/new/founder/email': typeof NewFounderEmailRoute
  '/new/founder/packages': typeof NewFounderPackagesRoute
  '/new/founder/packages-and-plan': typeof NewFounderPackagesAndPlanRoute
  '/new/founder/plans': typeof NewFounderPlansRoute
  '/new/founder/time': typeof NewFounderTimeRoute
  '/onboarding/founder/company': typeof OnboardingFounderCompanyRoute
  '/onboarding/founder/links': typeof OnboardingFounderLinksRoute
  '/onboarding/founder/mail-preference': typeof OnboardingFounderMailPreferenceRoute
  '/onboarding/founder/outcome': typeof OnboardingFounderOutcomeRoute
  '/onboarding/founder/timezone': typeof OnboardingFounderTimezoneRoute
  '/new/designer/': typeof NewDesignerIndexRoute
  '/new/founder/': typeof NewFounderIndexRoute
  '/onboarding/founder': typeof OnboardingFounderIndexRoute
  '/samples': typeof SamplesSamplesIndexRoute
  '/dashboard/founder/invoice/detail': typeof DashboardFounderInvoiceDetailRoute
  '/dashboard/founder/proposals/included': typeof DashboardFounderProposalsIncludedRoute
  '/new/founder/packages/durations': typeof NewFounderPackagesDurationsRoute
  '/onboarding/creatives/$type/levels': typeof OnboardingCreativesTypeLevelsRoute
  '/onboarding/creatives/$type/time-zone': typeof OnboardingCreativesTypeTimeZoneRoute
  '/dashboard/admin/': typeof DashboardAdminAdminIndexRoute
  '/dashboard/designer/': typeof DashboardDesignerDesignerIndexRoute
  '/dashboard/founder/': typeof DashboardFounderFounderIndexRoute
  '/onboarding/creatives/$type': typeof OnboardingCreativesTypeIndexRoute
  '/dashboard/admin/designers/$designerId/assign': typeof DashboardAdminDesignersDesignerIdAssignRoute
  '/dashboard/admin/designers/$designerId/info': typeof DashboardAdminDesignersDesignerIdInfoRoute
  '/dashboard/admin/designers/$designerId/rate': typeof DashboardAdminDesignersDesignerIdRateRoute
  '/dashboard/admin/founders/$founderId/edit': typeof DashboardAdminFoundersFounderIdEditRoute
  '/dashboard/admin/founders/$founderId/info': typeof DashboardAdminFoundersFounderIdInfoRoute
  '/dashboard/admin/founders/$founderId/invoice': typeof DashboardAdminFoundersFounderIdInvoiceRoute
  '/dashboard/admin/founders/$founderId/proposal': typeof DashboardAdminFoundersFounderIdProposalRoute
  '/dashboard/founder/updates/$updateId/feedback': typeof DashboardFounderUpdatesUpdateIdFeedbackRoute
  '/dashboard/founder/updates/$updateId/rate': typeof DashboardFounderUpdatesUpdateIdRateRoute
  '/dashboard/founder/updates/thanks': typeof DashboardFounderUpdatesUpdatesThanksRoute
  '/dashboard/admin/designers/$designerId': typeof DashboardAdminDesignersDesignerIdIndexRoute
  '/dashboard/admin/founders': typeof DashboardAdminFoundersFoundersIndexRoute
  '/dashboard/designer/projects': typeof DashboardDesignerProjectsProjectsIndexRoute
  '/dashboard/founder/updates/$updateId': typeof DashboardFounderUpdatesUpdateIdIndexRoute
  '/dashboard/founder/updates': typeof DashboardFounderUpdatesUpdatesIndexRoute
  '/dashboard/admin/founders/$founderId': typeof DashboardAdminFoundersFounderIdFounderIdIndexRoute
  '/dashboard/designer/projects/$projectId': typeof DashboardDesignerProjectsProjectIdProjectIdIndexRoute
  '/dashboard/admin/founders/$founderId/updates/$updateId/next': typeof DashboardAdminFoundersFounderIdUpdatesUpdateIdNextRoute
  '/dashboard/admin/founders/$founderId/updates/$updateId/preview': typeof DashboardAdminFoundersFounderIdUpdatesUpdateIdPreviewRoute
  '/dashboard/designer/projects/$projectId/updates/$updateId/next': typeof DashboardDesignerProjectsProjectIdUpdatesUpdateIdNextRoute
  '/dashboard/designer/projects/$projectId/updates/$updateId/preview': typeof DashboardDesignerProjectsProjectIdUpdatesUpdateIdPreviewRoute
  '/dashboard/admin/founders/$founderId/updates/$updateId': typeof DashboardAdminFoundersFounderIdUpdatesUpdateIdIndexRoute
  '/dashboard/admin/founders/$founderId/updates': typeof DashboardAdminFoundersFounderIdUpdatesUpdatesIndexRoute
  '/dashboard/designer/projects/$projectId/updates/$updateId': typeof DashboardDesignerProjectsProjectIdUpdatesUpdateIdIndexRoute
  '/dashboard/designer/projects/$projectId/updates': typeof DashboardDesignerProjectsProjectIdUpdatesUpdatesIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/login': typeof LoginRoute
  '/onboarding': typeof OnboardingRouteWithChildren
  '/about': typeof MiscAboutRoute
  '/api/redirect': typeof ApiRedirectRoute
  '/presentation/creatives': typeof PresentationCreativesRoute
  '/presentation/founder': typeof PresentationFounderRoute
  '/new': typeof NewIndexRoute
  '/confirmed': typeof MiscNewsletterConfirmedRoute
  '/updates': typeof MiscNewsletterUpdatesRoute
  '/about/mission': typeof MiscAboutMissionRoute
  '/dashboard/admin/create-project': typeof DashboardAdminCreateProjectRoute
  '/dashboard/admin/creatives': typeof DashboardAdminCreativesRoute
  '/dashboard/admin/site-reports': typeof DashboardAdminSiteReportsRoute
  '/dashboard/founder/agreement': typeof DashboardFounderAgreementRoute
  '/dashboard/founder/company-form': typeof DashboardFounderCompanyFormRoute
  '/dashboard/founder/invoice': typeof DashboardFounderInvoiceRoute
  '/dashboard/founder/proposals': typeof DashboardFounderProposalsRoute
  '/dashboard/founder/receipt': typeof DashboardFounderReceiptRoute
  '/new/designer/check-mail': typeof NewDesignerCheckMailRoute
  '/new/designer/email': typeof NewDesignerEmailRoute
  '/new/designer/links': typeof NewDesignerLinksRoute
  '/new/designer/samples': typeof NewDesignerSamplesRoute
  '/new/designer/time': typeof NewDesignerTimeRoute
  '/new/founder/check-email': typeof NewFounderCheckEmailRoute
  '/new/founder/email': typeof NewFounderEmailRoute
  '/new/founder/packages': typeof NewFounderPackagesRoute
  '/new/founder/packages-and-plan': typeof NewFounderPackagesAndPlanRoute
  '/new/founder/plans': typeof NewFounderPlansRoute
  '/new/founder/time': typeof NewFounderTimeRoute
  '/onboarding/founder/company': typeof OnboardingFounderCompanyRoute
  '/onboarding/founder/links': typeof OnboardingFounderLinksRoute
  '/onboarding/founder/mail-preference': typeof OnboardingFounderMailPreferenceRoute
  '/onboarding/founder/outcome': typeof OnboardingFounderOutcomeRoute
  '/onboarding/founder/timezone': typeof OnboardingFounderTimezoneRoute
  '/new/designer': typeof NewDesignerIndexRoute
  '/new/founder': typeof NewFounderIndexRoute
  '/onboarding/founder': typeof OnboardingFounderIndexRoute
  '/samples': typeof SamplesSamplesIndexRoute
  '/dashboard/founder/invoice/detail': typeof DashboardFounderInvoiceDetailRoute
  '/dashboard/founder/proposals/included': typeof DashboardFounderProposalsIncludedRoute
  '/new/founder/packages/durations': typeof NewFounderPackagesDurationsRoute
  '/onboarding/creatives/$type/levels': typeof OnboardingCreativesTypeLevelsRoute
  '/onboarding/creatives/$type/time-zone': typeof OnboardingCreativesTypeTimeZoneRoute
  '/dashboard/admin': typeof DashboardAdminAdminIndexRoute
  '/dashboard/designer': typeof DashboardDesignerDesignerIndexRoute
  '/dashboard/founder': typeof DashboardFounderFounderIndexRoute
  '/onboarding/creatives/$type': typeof OnboardingCreativesTypeIndexRoute
  '/dashboard/admin/designers/$designerId/assign': typeof DashboardAdminDesignersDesignerIdAssignRoute
  '/dashboard/admin/designers/$designerId/info': typeof DashboardAdminDesignersDesignerIdInfoRoute
  '/dashboard/admin/designers/$designerId/rate': typeof DashboardAdminDesignersDesignerIdRateRoute
  '/dashboard/admin/founders/$founderId/edit': typeof DashboardAdminFoundersFounderIdEditRoute
  '/dashboard/admin/founders/$founderId/info': typeof DashboardAdminFoundersFounderIdInfoRoute
  '/dashboard/admin/founders/$founderId/invoice': typeof DashboardAdminFoundersFounderIdInvoiceRoute
  '/dashboard/admin/founders/$founderId/proposal': typeof DashboardAdminFoundersFounderIdProposalRoute
  '/dashboard/founder/updates/$updateId/feedback': typeof DashboardFounderUpdatesUpdateIdFeedbackRoute
  '/dashboard/founder/updates/$updateId/rate': typeof DashboardFounderUpdatesUpdateIdRateRoute
  '/dashboard/founder/updates/thanks': typeof DashboardFounderUpdatesUpdatesThanksRoute
  '/dashboard/admin/designers/$designerId': typeof DashboardAdminDesignersDesignerIdIndexRoute
  '/dashboard/admin/founders': typeof DashboardAdminFoundersFoundersIndexRoute
  '/dashboard/designer/projects': typeof DashboardDesignerProjectsProjectsIndexRoute
  '/dashboard/founder/updates/$updateId': typeof DashboardFounderUpdatesUpdateIdIndexRoute
  '/dashboard/founder/updates': typeof DashboardFounderUpdatesUpdatesIndexRoute
  '/dashboard/admin/founders/$founderId': typeof DashboardAdminFoundersFounderIdFounderIdIndexRoute
  '/dashboard/designer/projects/$projectId': typeof DashboardDesignerProjectsProjectIdProjectIdIndexRoute
  '/dashboard/admin/founders/$founderId/updates/$updateId/next': typeof DashboardAdminFoundersFounderIdUpdatesUpdateIdNextRoute
  '/dashboard/admin/founders/$founderId/updates/$updateId/preview': typeof DashboardAdminFoundersFounderIdUpdatesUpdateIdPreviewRoute
  '/dashboard/designer/projects/$projectId/updates/$updateId/next': typeof DashboardDesignerProjectsProjectIdUpdatesUpdateIdNextRoute
  '/dashboard/designer/projects/$projectId/updates/$updateId/preview': typeof DashboardDesignerProjectsProjectIdUpdatesUpdateIdPreviewRoute
  '/dashboard/admin/founders/$founderId/updates/$updateId': typeof DashboardAdminFoundersFounderIdUpdatesUpdateIdIndexRoute
  '/dashboard/admin/founders/$founderId/updates': typeof DashboardAdminFoundersFounderIdUpdatesUpdatesIndexRoute
  '/dashboard/designer/projects/$projectId/updates/$updateId': typeof DashboardDesignerProjectsProjectIdUpdatesUpdateIdIndexRoute
  '/dashboard/designer/projects/$projectId/updates': typeof DashboardDesignerProjectsProjectIdUpdatesUpdatesIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/login': typeof LoginRoute
  '/new': typeof NewRouteWithChildren
  '/onboarding': typeof OnboardingRouteWithChildren
  '/_misc/about': typeof MiscAboutRoute
  '/api/redirect': typeof ApiRedirectRoute
  '/dashboard/admin': typeof DashboardAdminRouteWithChildren
  '/dashboard/designer': typeof DashboardDesignerRouteWithChildren
  '/dashboard/founder': typeof DashboardFounderRouteWithChildren
  '/new/designer': typeof NewDesignerRouteWithChildren
  '/new/founder': typeof NewFounderRouteWithChildren
  '/presentation/creatives': typeof PresentationCreativesRoute
  '/presentation/founder': typeof PresentationFounderRoute
  '/new/': typeof NewIndexRoute
  '/_misc/_newsletter/confirmed': typeof MiscNewsletterConfirmedRoute
  '/_misc/_newsletter/updates': typeof MiscNewsletterUpdatesRoute
  '/_misc/about_/mission': typeof MiscAboutMissionRoute
  '/dashboard/admin/create-project': typeof DashboardAdminCreateProjectRoute
  '/dashboard/admin/creatives': typeof DashboardAdminCreativesRoute
  '/dashboard/admin/site-reports': typeof DashboardAdminSiteReportsRoute
  '/dashboard/founder/agreement': typeof DashboardFounderAgreementRoute
  '/dashboard/founder/company-form': typeof DashboardFounderCompanyFormRoute
  '/dashboard/founder/invoice': typeof DashboardFounderInvoiceRoute
  '/dashboard/founder/proposals': typeof DashboardFounderProposalsRoute
  '/dashboard/founder/receipt': typeof DashboardFounderReceiptRoute
  '/new/designer/check-mail': typeof NewDesignerCheckMailRoute
  '/new/designer/email': typeof NewDesignerEmailRoute
  '/new/designer/links': typeof NewDesignerLinksRoute
  '/new/designer/samples': typeof NewDesignerSamplesRoute
  '/new/designer/time': typeof NewDesignerTimeRoute
  '/new/founder/check-email': typeof NewFounderCheckEmailRoute
  '/new/founder/email': typeof NewFounderEmailRoute
  '/new/founder/packages': typeof NewFounderPackagesRoute
  '/new/founder/packages-and-plan': typeof NewFounderPackagesAndPlanRoute
  '/new/founder/plans': typeof NewFounderPlansRoute
  '/new/founder/time': typeof NewFounderTimeRoute
  '/onboarding/founder/company': typeof OnboardingFounderCompanyRoute
  '/onboarding/founder/links': typeof OnboardingFounderLinksRoute
  '/onboarding/founder/mail-preference': typeof OnboardingFounderMailPreferenceRoute
  '/onboarding/founder/outcome': typeof OnboardingFounderOutcomeRoute
  '/onboarding/founder/timezone': typeof OnboardingFounderTimezoneRoute
  '/new/designer/': typeof NewDesignerIndexRoute
  '/new/founder/': typeof NewFounderIndexRoute
  '/onboarding/founder/': typeof OnboardingFounderIndexRoute
  '/samples/_samples/': typeof SamplesSamplesIndexRoute
  '/dashboard/founder/invoice_/detail': typeof DashboardFounderInvoiceDetailRoute
  '/dashboard/founder/proposals_/included': typeof DashboardFounderProposalsIncludedRoute
  '/new/founder/packages_/durations': typeof NewFounderPackagesDurationsRoute
  '/onboarding/creatives/$type/levels': typeof OnboardingCreativesTypeLevelsRoute
  '/onboarding/creatives/$type/time-zone': typeof OnboardingCreativesTypeTimeZoneRoute
  '/dashboard/admin/_admin/': typeof DashboardAdminAdminIndexRoute
  '/dashboard/designer/_designer/': typeof DashboardDesignerDesignerIndexRoute
  '/dashboard/founder/_founder/': typeof DashboardFounderFounderIndexRoute
  '/onboarding/creatives/$type/': typeof OnboardingCreativesTypeIndexRoute
  '/dashboard/admin/designers/$designerId/assign': typeof DashboardAdminDesignersDesignerIdAssignRoute
  '/dashboard/admin/designers/$designerId/info': typeof DashboardAdminDesignersDesignerIdInfoRoute
  '/dashboard/admin/designers/$designerId/rate': typeof DashboardAdminDesignersDesignerIdRateRoute
  '/dashboard/admin/founders/$founderId/edit': typeof DashboardAdminFoundersFounderIdEditRoute
  '/dashboard/admin/founders/$founderId/info': typeof DashboardAdminFoundersFounderIdInfoRoute
  '/dashboard/admin/founders/$founderId/invoice': typeof DashboardAdminFoundersFounderIdInvoiceRoute
  '/dashboard/admin/founders/$founderId/proposal': typeof DashboardAdminFoundersFounderIdProposalRoute
  '/dashboard/founder/updates/$updateId/feedback': typeof DashboardFounderUpdatesUpdateIdFeedbackRoute
  '/dashboard/founder/updates/$updateId/rate': typeof DashboardFounderUpdatesUpdateIdRateRoute
  '/dashboard/founder/updates/_updates/thanks': typeof DashboardFounderUpdatesUpdatesThanksRoute
  '/dashboard/admin/designers/$designerId/': typeof DashboardAdminDesignersDesignerIdIndexRoute
  '/dashboard/admin/founders/_founders/': typeof DashboardAdminFoundersFoundersIndexRoute
  '/dashboard/designer/projects/_projects/': typeof DashboardDesignerProjectsProjectsIndexRoute
  '/dashboard/founder/updates/$updateId/': typeof DashboardFounderUpdatesUpdateIdIndexRoute
  '/dashboard/founder/updates/_updates/': typeof DashboardFounderUpdatesUpdatesIndexRoute
  '/dashboard/admin/founders/$founderId/_$founderId/': typeof DashboardAdminFoundersFounderIdFounderIdIndexRoute
  '/dashboard/designer/projects/$projectId/_$projectId/': typeof DashboardDesignerProjectsProjectIdProjectIdIndexRoute
  '/dashboard/admin/founders/$founderId/updates/$updateId/next': typeof DashboardAdminFoundersFounderIdUpdatesUpdateIdNextRoute
  '/dashboard/admin/founders/$founderId/updates/$updateId/preview': typeof DashboardAdminFoundersFounderIdUpdatesUpdateIdPreviewRoute
  '/dashboard/designer/projects/$projectId/updates/$updateId/next': typeof DashboardDesignerProjectsProjectIdUpdatesUpdateIdNextRoute
  '/dashboard/designer/projects/$projectId/updates/$updateId/preview': typeof DashboardDesignerProjectsProjectIdUpdatesUpdateIdPreviewRoute
  '/dashboard/admin/founders/$founderId/updates/$updateId/': typeof DashboardAdminFoundersFounderIdUpdatesUpdateIdIndexRoute
  '/dashboard/admin/founders/$founderId/updates/_updates/': typeof DashboardAdminFoundersFounderIdUpdatesUpdatesIndexRoute
  '/dashboard/designer/projects/$projectId/updates/$updateId/': typeof DashboardDesignerProjectsProjectIdUpdatesUpdateIdIndexRoute
  '/dashboard/designer/projects/$projectId/updates/_updates/': typeof DashboardDesignerProjectsProjectIdUpdatesUpdatesIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/dashboard'
    | '/login'
    | '/new'
    | '/onboarding'
    | '/about'
    | '/api/redirect'
    | '/dashboard/admin'
    | '/dashboard/designer'
    | '/dashboard/founder'
    | '/new/designer'
    | '/new/founder'
    | '/presentation/creatives'
    | '/presentation/founder'
    | '/new/'
    | '/confirmed'
    | '/updates'
    | '/about/mission'
    | '/dashboard/admin/create-project'
    | '/dashboard/admin/creatives'
    | '/dashboard/admin/site-reports'
    | '/dashboard/founder/agreement'
    | '/dashboard/founder/company-form'
    | '/dashboard/founder/invoice'
    | '/dashboard/founder/proposals'
    | '/dashboard/founder/receipt'
    | '/new/designer/check-mail'
    | '/new/designer/email'
    | '/new/designer/links'
    | '/new/designer/samples'
    | '/new/designer/time'
    | '/new/founder/check-email'
    | '/new/founder/email'
    | '/new/founder/packages'
    | '/new/founder/packages-and-plan'
    | '/new/founder/plans'
    | '/new/founder/time'
    | '/onboarding/founder/company'
    | '/onboarding/founder/links'
    | '/onboarding/founder/mail-preference'
    | '/onboarding/founder/outcome'
    | '/onboarding/founder/timezone'
    | '/new/designer/'
    | '/new/founder/'
    | '/onboarding/founder'
    | '/samples'
    | '/dashboard/founder/invoice/detail'
    | '/dashboard/founder/proposals/included'
    | '/new/founder/packages/durations'
    | '/onboarding/creatives/$type/levels'
    | '/onboarding/creatives/$type/time-zone'
    | '/dashboard/admin/'
    | '/dashboard/designer/'
    | '/dashboard/founder/'
    | '/onboarding/creatives/$type'
    | '/dashboard/admin/designers/$designerId/assign'
    | '/dashboard/admin/designers/$designerId/info'
    | '/dashboard/admin/designers/$designerId/rate'
    | '/dashboard/admin/founders/$founderId/edit'
    | '/dashboard/admin/founders/$founderId/info'
    | '/dashboard/admin/founders/$founderId/invoice'
    | '/dashboard/admin/founders/$founderId/proposal'
    | '/dashboard/founder/updates/$updateId/feedback'
    | '/dashboard/founder/updates/$updateId/rate'
    | '/dashboard/founder/updates/thanks'
    | '/dashboard/admin/designers/$designerId'
    | '/dashboard/admin/founders'
    | '/dashboard/designer/projects'
    | '/dashboard/founder/updates/$updateId'
    | '/dashboard/founder/updates'
    | '/dashboard/admin/founders/$founderId'
    | '/dashboard/designer/projects/$projectId'
    | '/dashboard/admin/founders/$founderId/updates/$updateId/next'
    | '/dashboard/admin/founders/$founderId/updates/$updateId/preview'
    | '/dashboard/designer/projects/$projectId/updates/$updateId/next'
    | '/dashboard/designer/projects/$projectId/updates/$updateId/preview'
    | '/dashboard/admin/founders/$founderId/updates/$updateId'
    | '/dashboard/admin/founders/$founderId/updates'
    | '/dashboard/designer/projects/$projectId/updates/$updateId'
    | '/dashboard/designer/projects/$projectId/updates'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/dashboard'
    | '/login'
    | '/onboarding'
    | '/about'
    | '/api/redirect'
    | '/presentation/creatives'
    | '/presentation/founder'
    | '/new'
    | '/confirmed'
    | '/updates'
    | '/about/mission'
    | '/dashboard/admin/create-project'
    | '/dashboard/admin/creatives'
    | '/dashboard/admin/site-reports'
    | '/dashboard/founder/agreement'
    | '/dashboard/founder/company-form'
    | '/dashboard/founder/invoice'
    | '/dashboard/founder/proposals'
    | '/dashboard/founder/receipt'
    | '/new/designer/check-mail'
    | '/new/designer/email'
    | '/new/designer/links'
    | '/new/designer/samples'
    | '/new/designer/time'
    | '/new/founder/check-email'
    | '/new/founder/email'
    | '/new/founder/packages'
    | '/new/founder/packages-and-plan'
    | '/new/founder/plans'
    | '/new/founder/time'
    | '/onboarding/founder/company'
    | '/onboarding/founder/links'
    | '/onboarding/founder/mail-preference'
    | '/onboarding/founder/outcome'
    | '/onboarding/founder/timezone'
    | '/new/designer'
    | '/new/founder'
    | '/onboarding/founder'
    | '/samples'
    | '/dashboard/founder/invoice/detail'
    | '/dashboard/founder/proposals/included'
    | '/new/founder/packages/durations'
    | '/onboarding/creatives/$type/levels'
    | '/onboarding/creatives/$type/time-zone'
    | '/dashboard/admin'
    | '/dashboard/designer'
    | '/dashboard/founder'
    | '/onboarding/creatives/$type'
    | '/dashboard/admin/designers/$designerId/assign'
    | '/dashboard/admin/designers/$designerId/info'
    | '/dashboard/admin/designers/$designerId/rate'
    | '/dashboard/admin/founders/$founderId/edit'
    | '/dashboard/admin/founders/$founderId/info'
    | '/dashboard/admin/founders/$founderId/invoice'
    | '/dashboard/admin/founders/$founderId/proposal'
    | '/dashboard/founder/updates/$updateId/feedback'
    | '/dashboard/founder/updates/$updateId/rate'
    | '/dashboard/founder/updates/thanks'
    | '/dashboard/admin/designers/$designerId'
    | '/dashboard/admin/founders'
    | '/dashboard/designer/projects'
    | '/dashboard/founder/updates/$updateId'
    | '/dashboard/founder/updates'
    | '/dashboard/admin/founders/$founderId'
    | '/dashboard/designer/projects/$projectId'
    | '/dashboard/admin/founders/$founderId/updates/$updateId/next'
    | '/dashboard/admin/founders/$founderId/updates/$updateId/preview'
    | '/dashboard/designer/projects/$projectId/updates/$updateId/next'
    | '/dashboard/designer/projects/$projectId/updates/$updateId/preview'
    | '/dashboard/admin/founders/$founderId/updates/$updateId'
    | '/dashboard/admin/founders/$founderId/updates'
    | '/dashboard/designer/projects/$projectId/updates/$updateId'
    | '/dashboard/designer/projects/$projectId/updates'
  id:
    | '__root__'
    | '/'
    | '/dashboard'
    | '/login'
    | '/new'
    | '/onboarding'
    | '/_misc/about'
    | '/api/redirect'
    | '/dashboard/admin'
    | '/dashboard/designer'
    | '/dashboard/founder'
    | '/new/designer'
    | '/new/founder'
    | '/presentation/creatives'
    | '/presentation/founder'
    | '/new/'
    | '/_misc/_newsletter/confirmed'
    | '/_misc/_newsletter/updates'
    | '/_misc/about_/mission'
    | '/dashboard/admin/create-project'
    | '/dashboard/admin/creatives'
    | '/dashboard/admin/site-reports'
    | '/dashboard/founder/agreement'
    | '/dashboard/founder/company-form'
    | '/dashboard/founder/invoice'
    | '/dashboard/founder/proposals'
    | '/dashboard/founder/receipt'
    | '/new/designer/check-mail'
    | '/new/designer/email'
    | '/new/designer/links'
    | '/new/designer/samples'
    | '/new/designer/time'
    | '/new/founder/check-email'
    | '/new/founder/email'
    | '/new/founder/packages'
    | '/new/founder/packages-and-plan'
    | '/new/founder/plans'
    | '/new/founder/time'
    | '/onboarding/founder/company'
    | '/onboarding/founder/links'
    | '/onboarding/founder/mail-preference'
    | '/onboarding/founder/outcome'
    | '/onboarding/founder/timezone'
    | '/new/designer/'
    | '/new/founder/'
    | '/onboarding/founder/'
    | '/samples/_samples/'
    | '/dashboard/founder/invoice_/detail'
    | '/dashboard/founder/proposals_/included'
    | '/new/founder/packages_/durations'
    | '/onboarding/creatives/$type/levels'
    | '/onboarding/creatives/$type/time-zone'
    | '/dashboard/admin/_admin/'
    | '/dashboard/designer/_designer/'
    | '/dashboard/founder/_founder/'
    | '/onboarding/creatives/$type/'
    | '/dashboard/admin/designers/$designerId/assign'
    | '/dashboard/admin/designers/$designerId/info'
    | '/dashboard/admin/designers/$designerId/rate'
    | '/dashboard/admin/founders/$founderId/edit'
    | '/dashboard/admin/founders/$founderId/info'
    | '/dashboard/admin/founders/$founderId/invoice'
    | '/dashboard/admin/founders/$founderId/proposal'
    | '/dashboard/founder/updates/$updateId/feedback'
    | '/dashboard/founder/updates/$updateId/rate'
    | '/dashboard/founder/updates/_updates/thanks'
    | '/dashboard/admin/designers/$designerId/'
    | '/dashboard/admin/founders/_founders/'
    | '/dashboard/designer/projects/_projects/'
    | '/dashboard/founder/updates/$updateId/'
    | '/dashboard/founder/updates/_updates/'
    | '/dashboard/admin/founders/$founderId/_$founderId/'
    | '/dashboard/designer/projects/$projectId/_$projectId/'
    | '/dashboard/admin/founders/$founderId/updates/$updateId/next'
    | '/dashboard/admin/founders/$founderId/updates/$updateId/preview'
    | '/dashboard/designer/projects/$projectId/updates/$updateId/next'
    | '/dashboard/designer/projects/$projectId/updates/$updateId/preview'
    | '/dashboard/admin/founders/$founderId/updates/$updateId/'
    | '/dashboard/admin/founders/$founderId/updates/_updates/'
    | '/dashboard/designer/projects/$projectId/updates/$updateId/'
    | '/dashboard/designer/projects/$projectId/updates/_updates/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  DashboardRoute: typeof DashboardRouteWithChildren
  LoginRoute: typeof LoginRoute
  NewRoute: typeof NewRouteWithChildren
  OnboardingRoute: typeof OnboardingRouteWithChildren
  MiscAboutRoute: typeof MiscAboutRoute
  ApiRedirectRoute: typeof ApiRedirectRoute
  PresentationCreativesRoute: typeof PresentationCreativesRoute
  PresentationFounderRoute: typeof PresentationFounderRoute
  MiscNewsletterConfirmedRoute: typeof MiscNewsletterConfirmedRoute
  MiscNewsletterUpdatesRoute: typeof MiscNewsletterUpdatesRoute
  MiscAboutMissionRoute: typeof MiscAboutMissionRoute
  SamplesSamplesIndexRoute: typeof SamplesSamplesIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  DashboardRoute: DashboardRouteWithChildren,
  LoginRoute: LoginRoute,
  NewRoute: NewRouteWithChildren,
  OnboardingRoute: OnboardingRouteWithChildren,
  MiscAboutRoute: MiscAboutRoute,
  ApiRedirectRoute: ApiRedirectRoute,
  PresentationCreativesRoute: PresentationCreativesRoute,
  PresentationFounderRoute: PresentationFounderRoute,
  MiscNewsletterConfirmedRoute: MiscNewsletterConfirmedRoute,
  MiscNewsletterUpdatesRoute: MiscNewsletterUpdatesRoute,
  MiscAboutMissionRoute: MiscAboutMissionRoute,
  SamplesSamplesIndexRoute: SamplesSamplesIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/dashboard",
        "/login",
        "/new",
        "/onboarding",
        "/_misc/about",
        "/api/redirect",
        "/presentation/creatives",
        "/presentation/founder",
        "/_misc/_newsletter/confirmed",
        "/_misc/_newsletter/updates",
        "/_misc/about_/mission",
        "/samples/_samples/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/dashboard": {
      "filePath": "dashboard.tsx",
      "children": [
        "/dashboard/admin",
        "/dashboard/designer",
        "/dashboard/founder"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/new": {
      "filePath": "new.tsx",
      "children": [
        "/new/designer",
        "/new/founder",
        "/new/"
      ]
    },
    "/onboarding": {
      "filePath": "onboarding.tsx",
      "children": [
        "/onboarding/founder/company",
        "/onboarding/founder/links",
        "/onboarding/founder/mail-preference",
        "/onboarding/founder/outcome",
        "/onboarding/founder/timezone",
        "/onboarding/founder/",
        "/onboarding/creatives/$type/levels",
        "/onboarding/creatives/$type/time-zone",
        "/onboarding/creatives/$type/"
      ]
    },
    "/_misc/about": {
      "filePath": "_misc/about.tsx"
    },
    "/api/redirect": {
      "filePath": "api/redirect.tsx"
    },
    "/dashboard/admin": {
      "filePath": "dashboard/admin.tsx",
      "parent": "/dashboard",
      "children": [
        "/dashboard/admin/create-project",
        "/dashboard/admin/creatives",
        "/dashboard/admin/site-reports",
        "/dashboard/admin/_admin/",
        "/dashboard/admin/designers/$designerId/assign",
        "/dashboard/admin/designers/$designerId/info",
        "/dashboard/admin/designers/$designerId/rate",
        "/dashboard/admin/founders/$founderId/edit",
        "/dashboard/admin/founders/$founderId/info",
        "/dashboard/admin/founders/$founderId/invoice",
        "/dashboard/admin/founders/$founderId/proposal",
        "/dashboard/admin/designers/$designerId/",
        "/dashboard/admin/founders/_founders/",
        "/dashboard/admin/founders/$founderId/_$founderId/",
        "/dashboard/admin/founders/$founderId/updates/$updateId/next",
        "/dashboard/admin/founders/$founderId/updates/$updateId/preview",
        "/dashboard/admin/founders/$founderId/updates/$updateId/",
        "/dashboard/admin/founders/$founderId/updates/_updates/"
      ]
    },
    "/dashboard/designer": {
      "filePath": "dashboard/designer.tsx",
      "parent": "/dashboard",
      "children": [
        "/dashboard/designer/_designer/",
        "/dashboard/designer/projects/_projects/",
        "/dashboard/designer/projects/$projectId/_$projectId/",
        "/dashboard/designer/projects/$projectId/updates/$updateId/next",
        "/dashboard/designer/projects/$projectId/updates/$updateId/preview",
        "/dashboard/designer/projects/$projectId/updates/$updateId/",
        "/dashboard/designer/projects/$projectId/updates/_updates/"
      ]
    },
    "/dashboard/founder": {
      "filePath": "dashboard/founder.tsx",
      "parent": "/dashboard",
      "children": [
        "/dashboard/founder/agreement",
        "/dashboard/founder/company-form",
        "/dashboard/founder/invoice",
        "/dashboard/founder/proposals",
        "/dashboard/founder/receipt",
        "/dashboard/founder/invoice_/detail",
        "/dashboard/founder/proposals_/included",
        "/dashboard/founder/_founder/",
        "/dashboard/founder/updates/$updateId/feedback",
        "/dashboard/founder/updates/$updateId/rate",
        "/dashboard/founder/updates/_updates/thanks",
        "/dashboard/founder/updates/$updateId/",
        "/dashboard/founder/updates/_updates/"
      ]
    },
    "/new/designer": {
      "filePath": "new/designer.tsx",
      "parent": "/new",
      "children": [
        "/new/designer/check-mail",
        "/new/designer/email",
        "/new/designer/links",
        "/new/designer/samples",
        "/new/designer/time",
        "/new/designer/"
      ]
    },
    "/new/founder": {
      "filePath": "new/founder.tsx",
      "parent": "/new",
      "children": [
        "/new/founder/check-email",
        "/new/founder/email",
        "/new/founder/packages",
        "/new/founder/packages-and-plan",
        "/new/founder/plans",
        "/new/founder/time",
        "/new/founder/",
        "/new/founder/packages_/durations"
      ]
    },
    "/presentation/creatives": {
      "filePath": "presentation.creatives.tsx"
    },
    "/presentation/founder": {
      "filePath": "presentation.founder.tsx"
    },
    "/new/": {
      "filePath": "new/index.tsx",
      "parent": "/new"
    },
    "/_misc/_newsletter/confirmed": {
      "filePath": "_misc/_newsletter.confirmed.tsx"
    },
    "/_misc/_newsletter/updates": {
      "filePath": "_misc/_newsletter.updates.tsx"
    },
    "/_misc/about_/mission": {
      "filePath": "_misc/about_.mission.tsx"
    },
    "/dashboard/admin/create-project": {
      "filePath": "dashboard/admin/create-project.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/creatives": {
      "filePath": "dashboard/admin/creatives.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/site-reports": {
      "filePath": "dashboard/admin/site-reports.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/founder/agreement": {
      "filePath": "dashboard/founder/agreement.tsx",
      "parent": "/dashboard/founder"
    },
    "/dashboard/founder/company-form": {
      "filePath": "dashboard/founder/company-form.tsx",
      "parent": "/dashboard/founder"
    },
    "/dashboard/founder/invoice": {
      "filePath": "dashboard/founder/invoice.tsx",
      "parent": "/dashboard/founder"
    },
    "/dashboard/founder/proposals": {
      "filePath": "dashboard/founder/proposals.tsx",
      "parent": "/dashboard/founder"
    },
    "/dashboard/founder/receipt": {
      "filePath": "dashboard/founder/receipt.tsx",
      "parent": "/dashboard/founder"
    },
    "/new/designer/check-mail": {
      "filePath": "new/designer/check-mail.tsx",
      "parent": "/new/designer"
    },
    "/new/designer/email": {
      "filePath": "new/designer/email.tsx",
      "parent": "/new/designer"
    },
    "/new/designer/links": {
      "filePath": "new/designer/links.tsx",
      "parent": "/new/designer"
    },
    "/new/designer/samples": {
      "filePath": "new/designer/samples.tsx",
      "parent": "/new/designer"
    },
    "/new/designer/time": {
      "filePath": "new/designer/time.tsx",
      "parent": "/new/designer"
    },
    "/new/founder/check-email": {
      "filePath": "new/founder/check-email.tsx",
      "parent": "/new/founder"
    },
    "/new/founder/email": {
      "filePath": "new/founder/email.tsx",
      "parent": "/new/founder"
    },
    "/new/founder/packages": {
      "filePath": "new/founder/packages.tsx",
      "parent": "/new/founder"
    },
    "/new/founder/packages-and-plan": {
      "filePath": "new/founder/packages-and-plan.tsx",
      "parent": "/new/founder"
    },
    "/new/founder/plans": {
      "filePath": "new/founder/plans.tsx",
      "parent": "/new/founder"
    },
    "/new/founder/time": {
      "filePath": "new/founder/time.tsx",
      "parent": "/new/founder"
    },
    "/onboarding/founder/company": {
      "filePath": "onboarding/founder/company.tsx",
      "parent": "/onboarding"
    },
    "/onboarding/founder/links": {
      "filePath": "onboarding/founder/links.tsx",
      "parent": "/onboarding"
    },
    "/onboarding/founder/mail-preference": {
      "filePath": "onboarding/founder/mail-preference.tsx",
      "parent": "/onboarding"
    },
    "/onboarding/founder/outcome": {
      "filePath": "onboarding/founder/outcome.tsx",
      "parent": "/onboarding"
    },
    "/onboarding/founder/timezone": {
      "filePath": "onboarding/founder/timezone.tsx",
      "parent": "/onboarding"
    },
    "/new/designer/": {
      "filePath": "new/designer/index.tsx",
      "parent": "/new/designer"
    },
    "/new/founder/": {
      "filePath": "new/founder/index.tsx",
      "parent": "/new/founder"
    },
    "/onboarding/founder/": {
      "filePath": "onboarding/founder/index.tsx",
      "parent": "/onboarding"
    },
    "/samples/_samples/": {
      "filePath": "samples/_samples.index.tsx"
    },
    "/dashboard/founder/invoice_/detail": {
      "filePath": "dashboard/founder/invoice_.detail.tsx",
      "parent": "/dashboard/founder"
    },
    "/dashboard/founder/proposals_/included": {
      "filePath": "dashboard/founder/proposals_.included.tsx",
      "parent": "/dashboard/founder"
    },
    "/new/founder/packages_/durations": {
      "filePath": "new/founder/packages_.durations.tsx",
      "parent": "/new/founder"
    },
    "/onboarding/creatives/$type/levels": {
      "filePath": "onboarding/creatives.$type/levels.tsx",
      "parent": "/onboarding"
    },
    "/onboarding/creatives/$type/time-zone": {
      "filePath": "onboarding/creatives.$type/time-zone.tsx",
      "parent": "/onboarding"
    },
    "/dashboard/admin/_admin/": {
      "filePath": "dashboard/admin/_admin.index.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/designer/_designer/": {
      "filePath": "dashboard/designer/_designer.index.tsx",
      "parent": "/dashboard/designer"
    },
    "/dashboard/founder/_founder/": {
      "filePath": "dashboard/founder/_founder.index.tsx",
      "parent": "/dashboard/founder"
    },
    "/onboarding/creatives/$type/": {
      "filePath": "onboarding/creatives.$type/index.tsx",
      "parent": "/onboarding"
    },
    "/dashboard/admin/designers/$designerId/assign": {
      "filePath": "dashboard/admin/designers.$designerId/assign.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/designers/$designerId/info": {
      "filePath": "dashboard/admin/designers.$designerId/info.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/designers/$designerId/rate": {
      "filePath": "dashboard/admin/designers.$designerId/rate.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/founders/$founderId/edit": {
      "filePath": "dashboard/admin/founders/$founderId/edit.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/founders/$founderId/info": {
      "filePath": "dashboard/admin/founders/$founderId/info.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/founders/$founderId/invoice": {
      "filePath": "dashboard/admin/founders/$founderId/invoice.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/founders/$founderId/proposal": {
      "filePath": "dashboard/admin/founders/$founderId/proposal.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/founder/updates/$updateId/feedback": {
      "filePath": "dashboard/founder/updates/$updateId.feedback.tsx",
      "parent": "/dashboard/founder"
    },
    "/dashboard/founder/updates/$updateId/rate": {
      "filePath": "dashboard/founder/updates/$updateId.rate.tsx",
      "parent": "/dashboard/founder"
    },
    "/dashboard/founder/updates/_updates/thanks": {
      "filePath": "dashboard/founder/updates/_updates.thanks.tsx",
      "parent": "/dashboard/founder"
    },
    "/dashboard/admin/designers/$designerId/": {
      "filePath": "dashboard/admin/designers.$designerId/index.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/founders/_founders/": {
      "filePath": "dashboard/admin/founders/_founders.index.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/designer/projects/_projects/": {
      "filePath": "dashboard/designer/projects/_projects.index.tsx",
      "parent": "/dashboard/designer"
    },
    "/dashboard/founder/updates/$updateId/": {
      "filePath": "dashboard/founder/updates/$updateId.index.tsx",
      "parent": "/dashboard/founder"
    },
    "/dashboard/founder/updates/_updates/": {
      "filePath": "dashboard/founder/updates/_updates.index.tsx",
      "parent": "/dashboard/founder"
    },
    "/dashboard/admin/founders/$founderId/_$founderId/": {
      "filePath": "dashboard/admin/founders/$founderId/_$founderId.index.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/designer/projects/$projectId/_$projectId/": {
      "filePath": "dashboard/designer/projects/$projectId/_$projectId.index.tsx",
      "parent": "/dashboard/designer"
    },
    "/dashboard/admin/founders/$founderId/updates/$updateId/next": {
      "filePath": "dashboard/admin/founders/$founderId/updates/$updateId.next.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/founders/$founderId/updates/$updateId/preview": {
      "filePath": "dashboard/admin/founders/$founderId/updates/$updateId.preview.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/designer/projects/$projectId/updates/$updateId/next": {
      "filePath": "dashboard/designer/projects/$projectId/updates/$updateId.next.tsx",
      "parent": "/dashboard/designer"
    },
    "/dashboard/designer/projects/$projectId/updates/$updateId/preview": {
      "filePath": "dashboard/designer/projects/$projectId/updates/$updateId.preview.tsx",
      "parent": "/dashboard/designer"
    },
    "/dashboard/admin/founders/$founderId/updates/$updateId/": {
      "filePath": "dashboard/admin/founders/$founderId/updates/$updateId.index.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/founders/$founderId/updates/_updates/": {
      "filePath": "dashboard/admin/founders/$founderId/updates/_updates.index.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/designer/projects/$projectId/updates/$updateId/": {
      "filePath": "dashboard/designer/projects/$projectId/updates/$updateId.index.tsx",
      "parent": "/dashboard/designer"
    },
    "/dashboard/designer/projects/$projectId/updates/_updates/": {
      "filePath": "dashboard/designer/projects/$projectId/updates/_updates.index.tsx",
      "parent": "/dashboard/designer"
    }
  }
}
ROUTE_MANIFEST_END */
