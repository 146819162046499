import { createFileRoute, Link } from "@tanstack/react-router";

import { FounderService } from "@/api/services/founder.service";
import { UpdatesService } from "@/api/services/updates.service";
import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { Button } from "@/components/ui/button";
import { queryClient } from "@/lib/query-client";
import { cn, getRatingColor } from "@/lib/utils";

export const Route = createFileRoute("/dashboard/founder/updates/_updates/")({
  loader: async () => {
    const founder = await queryClient.ensureQueryData(
      FounderService.getCurrentFounder(),
    );
    const updates = await queryClient.ensureQueryData(
      UpdatesService.getUpdates(founder.id),
    );

    return { updates };
  },
  component: function Page() {
    const { updates } = Route.useLoaderData();

    return (
      <>
        <div className="flex flex-col items-center gap-4">
          {updates.length > 0 ? (
            updates.map((update) => (
              <Button
                asChild
                key={update.id}
                className={cn({
                  "border-gray-200": update.founder_rating === null,
                })}
              >
                <Link
                  to="/dashboard/founder/updates/$updateId"
                  params={{
                    updateId: update.id.toString(),
                  }}
                >
                  <span className="flex flex-col items-center gap-2">
                    {update.founder_rating !== null && (
                      <span
                        className={cn(
                          "size-2 rounded-full",
                          getRatingColor(update.founder_rating),
                        )}
                      ></span>
                    )}
                    <span>{update.order}</span>
                  </span>
                </Link>
              </Button>
            ))
          ) : (
            <p>No updates yet</p>
          )}
        </div>

        <BottomNavigation left={<BackButton />} />
      </>
    );
  },
});
