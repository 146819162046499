import { createFileRoute, Link, redirect } from "@tanstack/react-router";

import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { Button } from "@/components/ui/button";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { useIsMobile } from "@/hooks/use-is-mobile";
import { useUmamiPageEvent } from "@/lib/umami";
import { priceFormatter } from "@/lib/utils";
import {
  founderNewState$,
  isFounderNewCompleted$,
} from "@/store/founder.state";

export const Route = createFileRoute("/new/founder/packages-and-plan")({
  beforeLoad: () => {
    if (!isFounderNewCompleted$.peek()) {
      return redirect({ to: "/new/founder" });
    }
  },

  component: function Page() {
    const { isMobile } = useIsMobile();
    useUmamiPageEvent("Founder - Packages or Plans?");

    return (
      <div>
        <section className="flex w-full flex-col items-center justify-center px-10 text-center">
          <h1 className="mt-10">
            What works best for you? <br /> <br /> Choose Packages to pick what
            you need. <br /> Choose Plans to view our end-to-end offerings.
          </h1>

          <div className="mt-10 w-screen md:mt-20 md:w-full">
            <Carousel
              opts={{
                containScroll: isMobile ? false : "keepSnaps",
                active: isMobile,
              }}
            >
              <CarouselContent>
                <CarouselItem className="basis-[80%] md:basis-1/2">
                  <Button
                    data-cy="packages"
                    size="xl"
                    className="flex-col items-center gap-6"
                    asChild
                    onClick={() => {
                      founderNewState$.showServiceHighlight.set(false);
                    }}
                  >
                    <Link to={"/new/founder/packages"}>
                      <h1>Packages</h1>

                      <video
                        className="size-28 overflow-hidden rounded-full"
                        muted
                        loop
                        autoPlay
                        playsInline
                        disablePictureInPicture
                        disableRemotePlayback
                      >
                        <source src="/packages.mp4" type="video/mp4" />
                      </video>

                      <div className="flex flex-col items-center">
                        <span>Starting</span>
                        <span>{priceFormatter.format(30_000)}</span>
                      </div>
                    </Link>
                  </Button>
                </CarouselItem>

                <CarouselItem className="basis-[80%] md:basis-1/2">
                  <Button
                    data-cy="plans"
                    size="xl"
                    className="flex-col items-center gap-6"
                    asChild
                    onClick={() => {
                      /*  dispatch({
                          ...founder,
                          services: [],
                        }); */
                      founderNewState$.services.set([]);
                    }}
                  >
                    <Link to={"/new/founder/plans"}>
                      <h1>Plans</h1>

                      <video
                        className="size-28 overflow-hidden rounded-full"
                        muted
                        loop
                        autoPlay
                        playsInline
                        disablePictureInPicture
                        disableRemotePlayback
                      >
                        <source src="/plans.mp4" type="video/mp4" />
                      </video>

                      <div className="flex flex-col items-center">
                        <span>Starting</span>
                        <span>{priceFormatter.format(60_000)}</span>
                      </div>
                    </Link>
                  </Button>
                </CarouselItem>
              </CarouselContent>
            </Carousel>
          </div>
        </section>

        <BottomNavigation left={<BackButton native />} />
      </div>
    );
  },
});
