import { Link, LinkProps } from "@tanstack/react-router";
import React, { forwardRef, PropsWithChildren } from "react";

import { Button, ButtonProps } from "@/components/ui/button";
import { cn } from "@/lib/utils";

export interface LinkButtonProps extends LinkProps {
  className?: ButtonProps["className"];
  children: React.ReactNode;
  loading?: ButtonProps["loading"];
  disabled?: ButtonProps["disabled"];
  size?: ButtonProps["size"];
  type?: ButtonProps["type"];
  onClick?: ButtonProps["onClick"];
}

export const LinkButton = forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<LinkButtonProps>
>(({ children, className, onClick, type, ...props }, ref) => {
  return (
    <Button
      asChild
      className={cn("", className)}
      type={type}
      size={props.size}
      loading={props.loading}
      disabled={props.disabled}
      onClick={onClick}
    >
      <Link {...props} ref={ref}>
        {children}
      </Link>
    </Button>
  );
});

LinkButton.displayName = "LinkButton";
