import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { useState } from "react";

import { AdminService } from "@/api/services/admin.service";
import { BottomNavigation } from "@/components/bottom-navigation";
import { CopyEmailButton } from "@/components/copy-email-button";
import { FoundersTag } from "@/components/founders-tag";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { Links } from "@/components/links";
import { Spinner } from "@/components/spinner";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";

export const Route = createFileRoute(
  "/dashboard/admin/founders/$founderId/edit",
)({
  loader: async ({ params, context: { queryClient } }) => {
    /*   const founder = await AdminService.fetchFounder(params.founderId);

    return { founder }; */
    const founder = await queryClient.ensureQueryData(
      AdminService.fetchFounder(params.founderId),
    );

    return { founder };
  },
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: function Page() {
    const { founderId } = Route.useParams();

    const founderQuery = useSuspenseQuery(AdminService.fetchFounder(founderId));

    const initialFounder = founderQuery.data;

    const [founder, setFounder] = useState(initialFounder);
    const [newLink, setNewLink] = useState("");
    const navigate = Route.useNavigate();

    const updateMutation = useMutation({
      mutationFn: async () => {
        await AdminService.updateFounder(founder.id, {
          project_outcome_description: founder.project_outcome_description,
          links: founder.links,
          notes: founder.notes,
        });

        navigate({ to: "../info", replace: true });
      },
    });

    return (
      <>
        <div className="flex flex-col items-center pb-52">
          <FoundersTag
            className="mb-20"
            stage={founder.stage}
            services={founder.services}
            plan={founder.plan}
          />

          <CopyEmailButton email={founder.email} text={founder.name} />
          <p className="underline">{founder.company_name}</p>

          <label htmlFor="project-description" className="mb-6 mt-20">
            Desired outcome
          </label>

          <Textarea
            value={founder.project_outcome_description}
            onChange={(e) => {
              setFounder({
                ...founder,
                project_outcome_description: e.target.value,
              });
            }}
            placeholder="Description"
            className="w-full"
            id="project-description"
            inputMode="text"
          />

          <label htmlFor="resources" className="mt-10">
            Links
          </label>
          <Links
            currentLink={newLink}
            className="mt-4"
            linkList={founder.links}
            setCurrentLink={setNewLink}
            setLinkList={(links) =>
              setFounder({
                ...founder,
                links: links as string[],
              })
            }
          />

          <label htmlFor="notes" className="mb-6 mt-10">
            Notes
          </label>
          <Textarea
            value={founder.notes ?? ""}
            onChange={(e) => {
              setFounder({ ...founder, notes: e.target.value });
            }}
            placeholder="Add notes"
            className="w-full self-center"
            id="notes"
            inputMode="text"
          />
        </div>

        <BottomNavigation
          left={
            <Button size={"sm"} asChild disabled={updateMutation.isPending}>
              <Link
                to="/dashboard/admin/founders/$founderId/info"
                params={{ founderId: founder.id }}
              >
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={
            <Button
              loading={updateMutation.isPending}
              onClick={() => updateMutation.mutate()}
            >
              Save
            </Button>
          }
        />
      </>
    );
  },
});
