import { ExternalToast, toast as toastPrimitive } from "sonner";

export const toast = (
  message: string | React.ReactNode,
  data?: ExternalToast,
) => {
  return toastPrimitive.custom(
    () => (
      <div className="z-30 flex min-w-[320px] items-center justify-center md:min-w-[360px]">
        <div className="rounded-2xl bg-black p-4 text-center text-white">
          {message}
        </div>
      </div>
    ),
    {
      position: "top-center",
      ...data,
    },
  );
};
