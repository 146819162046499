import "@/lib/setup-pdf";

import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";

import { SSOTokens } from "@/api/services/auth.service";
import { ChatDialog } from "@/features/chat/views/chat-dialog";
import { toast } from "@/lib/utils/toast";

export const Route = createFileRoute("/dashboard")({
  beforeLoad: ({ location }) => {
    // this will run only dashboard/ route
    if (!location.pathname.match(/\/dashboard\/?$/)) return;

    const cookieStore = window.localStorage;

    const userType = cookieStore.getItem("userType") as
      | SSOTokens["user_type"]
      | undefined;

    if (!userType) {
      toast("You are not authorized to access this page.");
      return redirect({ to: "/login" });
    }

    switch (userType) {
      case "admin":
        return redirect({
          to: "/dashboard/admin",
          search: { filter: undefined },
        });
      case "client":
        return redirect({ to: "/dashboard/founder" });
      case "designer":
        return redirect({ to: "/dashboard/designer" });

      case "engineer":
        throw new Error("Not implemented");
    }
  },

  component: function Layout() {
    return (
      <>
        <Outlet />
        <ChatDialog />
      </>
    );
  },
});
