"use client";

import { Link } from "@tanstack/react-router";

import { FeatueContainer, FeatureGroup } from "@/components/feature-groups";
import { StatusCircle } from "@/components/status-circle";
import { Creative } from "@/lib/global-types";
import { formatTimezone, generateKickOffDateLabel } from "@/lib/utils";

const StatusOrders: Creative["status"][] = [
  "onboarded",
  "introCallRequestSent",
  "introCallBooked",
  "introCallStarted",
  "introCallEnded",

  // TODO: Add more statuses
];

export const CreativeListItem = ({ creative }: { creative: Creative }) => {
  const percentage =
    (StatusOrders.indexOf(creative.status) / (StatusOrders.length - 1)) * 100;

  return (
    <StatusCircle percentage={percentage}>
      <Link
        to="/dashboard/admin/designers/$designerId"
        preload={false}
        params={{ designerId: creative.id }}
        className="flex flex-col items-center justify-center gap-6 text-center"
      >
        <div className="text-center">
          <h2>{creative.name}</h2>
          {creative.time_zone ? (
            <h3>{formatTimezone(creative.time_zone)}</h3>
          ) : null}
        </div>

        <FeatueContainer className="scale-75">
          {creative.services.map((service) => {
            return (
              <FeatureGroup key={service}>
                {service.at(0)?.toUpperCase()}
              </FeatureGroup>
            );
          })}
        </FeatueContainer>

        <div>
          <span>{generateKickOffDateLabel(creative.availability)}</span>
          {/* // TODO: add requested rate */}
        </div>
      </Link>
    </StatusCircle>
  );
};
