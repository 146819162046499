import { observable } from "@legendapp/state";
import { DialogTitle } from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";

import { Dialog, DialogContent } from "@/components/ui/dialog";

export const helpDialog$ = observable(false);

interface Props {
  onOpenChange?: (open: boolean) => void;
}

export const HelpDialog = ({ onOpenChange }: Props) => {
  const open = helpDialog$.get();

  const handleClose = () => {
    helpDialog$.set(false);
    onOpenChange?.(false);
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <VisuallyHidden asChild>
        <DialogTitle>Help dialog</DialogTitle>
      </VisuallyHidden>
      <DialogContent aria-describedby={undefined}>
        <div className="flex flex-col items-center justify-center">
          <p>Got a question?</p>
          <a href="mailto:hello@openpurpose.com" className="underline">
            hello@openpurpose.com
          </a>
        </div>
      </DialogContent>
    </Dialog>
  );
};

HelpDialog.displayName = "HelpDialog";
