import { createFileRoute, Link } from "@tanstack/react-router";

import { BottomNavigation } from "@/components/bottom-navigation";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { TimezoneSelector } from "@/components/timezone-selector";
import { Button } from "@/components/ui/button";
import { founderOnboardingState$ } from "@/store/founder.state";

export const Route = createFileRoute("/onboarding/founder/timezone")({
  component: function Page() {
    const timeZone = founderOnboardingState$.time_zone.get();

    const navigate = Route.useNavigate();

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();

      navigate({
        to: "/onboarding/founder/company",
      });
    };

    return (
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center text-center"
      >
        <h1>
          {`Good to meet you, ${name}`} <br />
          {"Where are you located?"}
        </h1>

        <div className="mt-10 md:mt-20">
          <TimezoneSelector
            defaultValue={timeZone!}
            onChange={(value) => {
              founderOnboardingState$.time_zone.set(value);
            }}
          />
        </div>

        <BottomNavigation
          left={
            <Button size="sm" asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={timeZone && <Button type="submit">Next</Button>}
        />
      </form>
    );
  },
});
