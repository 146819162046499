"use client";
import Cal, { getCalApi } from "@calcom/embed-react";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { DesignerService } from "@/api/services/designer.service";
import { FounderService } from "@/api/services/founder.service";
import { CALCOM_URL } from "@/lib/constants";

interface CalcomEmbedProps {
  name?: string;
  email?: string;
  callType: "kickoff_call" | "first_call";
  userType: "founder" | "designer";
  onSuccess?: () => void;
}

export const CalcomEmbed = ({
  name = "",
  email = "",
  userType,
  callType,
  onSuccess,
}: CalcomEmbedProps) => {
  const callQuery = useQuery({
    queryKey: ["calcom"],
    queryFn: () => getCalApi({}),
  });

  useEffect(() => {
    if (callQuery.data) {
      const cal = callQuery.data;

      cal("ui", {
        styles: { branding: { brandColor: "#000000" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
      cal("on", {
        action: "bookingSuccessful",
        // TODO: Fix any type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback: async (e: any) => {
          const { data } = e.detail;

          switch (userType) {
            case "founder": {
              await FounderService.bookCall({
                id: data.booking.id.toString(),
                date: data.date,
                type: callType,
                video_call_id: data.booking.iCalUID.split("@")[0],
                duration: data.duration,
              });

              onSuccess?.();
              break;
            }
            case "designer": {
              await DesignerService.bookCall({
                id: data.booking.id.toString(),
                date: data.date,
                type: callType,
                video_call_id: data.booking.iCalUID.split("@")[0],
                duration: data.duration,
              });

              onSuccess?.();
              break;
            }
          }
        },
      });
    }
  });

  return (
    <Cal
      calLink={`${CALCOM_URL}?name=${name}&email=${email}`}
      style={{ width: "100%", height: "100%", scrollbarWidth: "none" }}
      config={{ layout: "month_view" }}
    />
  );
};

CalcomEmbed.displayName = "CalcomEmbed";
