import { createFileRoute, Link, redirect } from "@tanstack/react-router";

import { BottomNavigation } from "@/components/bottom-navigation";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { TimezoneSelector } from "@/components/timezone-selector";
import { Button } from "@/components/ui/button";
import {
  creativeOnboardingState$,
  isCreativeOnboardingCompleted$,
} from "@/store/creatives.state";

export const Route = createFileRoute("/onboarding/creatives/$type/time-zone")({
  beforeLoad: ({ params }) => {
    if (!isCreativeOnboardingCompleted$.peek()) {
      return redirect({ to: "/onboarding/creatives/$type", params });
    }
  },
  component: function Page() {
    const { type } = Route.useParams();
    const name = creativeOnboardingState$.name.get();
    const timeZone = creativeOnboardingState$.time_zone.get();

    return (
      <div className="text-center">
        <h1>
          Good to meet you, {name}. <br />
          Where are you located?
        </h1>

        <div className="mt-10 md:mt-20">
          <TimezoneSelector
            onChange={(value) => creativeOnboardingState$.time_zone.set(value)}
            defaultValue={timeZone}
          />
        </div>

        <BottomNavigation
          left={
            <Button size={"sm"} asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={
            timeZone && (
              <Button asChild>
                <Link to="/onboarding/creatives/$type/levels" params={{ type }}>
                  Next
                </Link>
              </Button>
            )
          }
        />
      </div>
    );
  },
});
