import { createFileRoute } from "@tanstack/react-router";
import { motion, MotionProps } from "framer-motion";
import { useMemo, useState } from "react";

import { BottomNavigation } from "@/components/bottom-navigation";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import {
  SlideContainer,
  SlideContainerProps,
} from "@/components/slide-container";
import { Button } from "@/components/ui/button";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { animation$, direction$ } from "@/store/page.state";

export const Route = createFileRoute("/new/")({
  component: function Page() {
    const [isShowCreatives, setIsShowCreatives] = useState(false);
    const animation = animation$.get();
    const direction = direction$.get();

    const navigate = Route.useNavigate();

    const Container = useMemo(() => {
      switch (animation) {
        case "slide-up":
          // eslint-disable-next-line react/display-name
          return (props: MotionProps) => {
            return (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.2,
                }}
                {...props}
              />
            );
          };
        case "slide-right":
          // eslint-disable-next-line react/display-name
          return (props: SlideContainerProps) => {
            return (
              <SlideContainer
                animationKey={direction}
                direction={direction}
                {...props}
              />
            );
          };
      }
    }, [animation]);

    return (
      <div className="h-svh pt-[88px] md:pt-[144px]">
        <Container>
          <h1 className="text-center">
            How would you like to <br />
            collaborate with us?
          </h1>
          <div className="mt-10 md:mt-20">
            <ToggleGroup
              type="single"
              onValueChange={(value) => {
                if (value === "creatives") {
                  setIsShowCreatives(true);
                  return;
                }
                if (value === "founder") {
                  navigate({ to: "/new/founder" });
                  /*  return router.push("/new/founder"); */
                }
                if (value === "engineer") {
                  /*   return router.push("/new/engineer"); */
                }

                if (value === "designer") {
                  navigate({ to: "/new/designer" });
                  /*   return router.push("/new/designer"); */
                }
              }}
              className="flex-wrap"
            >
              {/* {!isShowCreatives && (
            <ToggleGroupItem value="founder">Founder</ToggleGroupItem>
          )}
          {isShowCreatives ? (
            <>
              <ToggleGroupItem value="designer">Designer</ToggleGroupItem>
              <ToggleGroupItem value="engineer">
                Engineer
              </ToggleGroupItem>{" "}
            </>
          ) : (
            <ToggleGroupItem value="creatives">Creatives</ToggleGroupItem>
          )} */}
              <ToggleGroupItem value="founder">Founder</ToggleGroupItem>
              <ToggleGroupItem value="designer">Creative</ToggleGroupItem>
            </ToggleGroup>
          </div>
        </Container>
        <BottomNavigation
          left={
            <Button
              size="sm"
              onClick={() => {
                if (isShowCreatives) {
                  setIsShowCreatives(false);
                  return;
                }
                /*  router.back(); */
                navigate({ to: "/" });
              }}
            >
              <IconBackArrow />
            </Button>
          }
        />
      </div>
    );
  },
});
