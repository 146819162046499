import { Link } from "@tanstack/react-router";
import { Document, Page } from "react-pdf";

import { BottomNavigation } from "@/components/bottom-navigation";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { OPLogo } from "@/components/op-logo";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/solid-dialog";
import { useIsMobile } from "@/hooks/use-is-mobile";
import { cn } from "@/lib/utils";

type Props = {
  url?: string;
  open: boolean;
  footer?: React.ReactNode;
  onOpenChange: (open: boolean) => void;
};
export const ReceiptViewer = ({ open, onOpenChange, url, footer }: Props) => {
  const { isMobile } = useIsMobile();
  if (!url) return null;

  const pdfWidth = isMobile ? window?.innerWidth || 600 : 800;

  const renderContent = () => {
    const extention = url.split(".").pop();

    switch (extention) {
      case "pdf":
        return (
          <Document file={url}>
            <Page width={pdfWidth} pageNumber={1} />
          </Document>
        );
      // we assume that user will upload image or pdf
      default:
        return <img src={url} alt="receipt" width={600} height={800} />;
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <div className="flex max-h-screen w-[100vw] flex-col items-center gap-5 overflow-y-scroll pb-20 md:gap-10 md:pb-40">
          <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-black mix-blend-difference md:top-10">
            <Link to="/dashboard">
              <OPLogo />
            </Link>
          </header>

          <div className="mt-40 flex flex-col items-center">
            <h2 className="text-center">
              View and confirm <br /> receipt
            </h2>
            <div className="mt-10 border border-black/10">
              {renderContent()}
            </div>
          </div>

          <BottomNavigation
            left={
              <Button
                className={cn({
                  footer: "absolute -left-16 top-1/2 -translate-y-1/2",
                })}
                size={"sm"}
                onClick={() => onOpenChange(false)}
              >
                <IconBackArrow />
              </Button>
            }
            middle={footer}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
