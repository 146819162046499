import { createFileRoute, Link } from "@tanstack/react-router";

import { UpdatesService } from "@/api/services/updates.service";
import { BottomNavigation } from "@/components/bottom-navigation";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { Button } from "@/components/ui/button";
import { queryClient } from "@/lib/query-client";
import { cn, getRatingColor } from "@/lib/utils";

export const Route = createFileRoute(
  "/dashboard/designer/projects/$projectId/updates/$updateId/preview",
)({
  loader: async ({ params: { projectId } }) => {
    const update = await queryClient.fetchQuery(
      UpdatesService.getCurrentUpdate(projectId),
    );

    return { update };
  },

  component: function Page() {
    const { update } = Route.useLoaderData();
    return (
      <>
        <div className="flex flex-col items-center gap-20">
          <div className="flex flex-col items-center gap-10">
            <Button
              className={cn({
                "border-gray-200": update.founder_rating === null,
              })}
            >
              <span className="flex flex-col items-center gap-2">
                {update.founder_rating !== null && (
                  <span
                    className={cn(
                      "size-2 rounded-full",
                      getRatingColor(update.founder_rating),
                    )}
                  ></span>
                )}
                <span>{update.order || "1"}</span>
              </span>
            </Button>
            <p className="max-w-[450px] text-center">{update.description}</p>
          </div>

          <div className="flex flex-col items-center gap-10">
            {update.images?.map((imageSource, index) => (
              <div
                key={imageSource}
                className={cn(
                  "group relative flex aspect-video w-[90vw] max-w-[960px] items-center justify-center overflow-hidden rounded-2xl ring-1 ring-gray-200",
                )}
              >
                <img
                  height={540}
                  width={960}
                  src={imageSource}
                  alt={`preview of image ${index}`}
                />
              </div>
            ))}
          </div>
          {update.founder_feedback && (
            <p className="max-w-[450px] text-center">
              {update.founder_feedback}
            </p>
          )}
        </div>

        <BottomNavigation
          left={
            <Button size="sm" asChild>
              <Link
                to="/dashboard/designer/projects/$projectId/updates/$updateId"
                params={{
                  projectId: String(update.founder_id),
                  updateId: String(update.id),
                }}
              >
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={
            <Button asChild>
              <Link
                to="/dashboard/designer/projects/$projectId/updates/$updateId/next"
                params={{
                  projectId: String(update.founder_id),
                  updateId: String(update.id),
                }}
              >
                Confirm
              </Link>
            </Button>
          }
        />
      </>
    );
  },
});
