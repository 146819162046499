import { queryOptions } from "@tanstack/react-query";

import { HOST } from "@/lib/constants";
import { Call, CallBooked, Creative, Founder } from "@/lib/global-types";
import { unFormatTimezone } from "@/lib/utils";
import { CreativeOnboardingPayload } from "@/store/creatives.state";
import { DesignerCreate } from "@/store/designer.state";

import { get, post } from "../client";

export class DesignerService {
  static getCurrentDesigner() {
    return queryOptions({
      queryKey: ["designer_me"],
      queryFn: () => get<Creative>("/designers/me"),
    });
  }

  static async createDesigner(designer: DesignerCreate) {
    const res = await post("/designers", {
      services: designer.services,
      email: designer.email,
      availability: designer.availability,
      samples: designer.samples,
      links: designer.links,
      dev_base_url: HOST,
    });

    return res;
  }

  static async onboardDesigner(designer: CreativeOnboardingPayload) {
    const res = await post<Creative>("/designers/details", {
      ...designer,
      time_zone: unFormatTimezone(designer.time_zone!),
    });

    const cookieStore = window.localStorage;

    cookieStore.setItem(
      "isOnboardingCompleted",
      String(res.is_onboarding_completed),
    );

    return res;
  }

  static getCurrentDesignerBookings() {
    return queryOptions({
      queryKey: ["designer_bookings"],
      queryFn: () => get<CallBooked[]>(`/creatives/bookings/me`),
    });
  }

  static async bookCall(call: Call) {
    const res = await post(`/creatives/book-call`, {
      external_id: call.id,
      date: call.date,
      type: call.type,
      video_call_id: call.video_call_id,
      duration: call.duration,
    });

    return res;
  }

  static getProject(projectId: string) {
    return queryOptions({
      queryKey: ["founder", projectId],
      queryFn: () => get<Founder>(`/designers/founders/${projectId}`),
    });
  }

  static getProjects() {
    return queryOptions({
      queryKey: ["founders"],
      queryFn: () => get<Founder[]>(`/designers/founders`),
    });
  }
}
