import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { get } from "@/api/client";
import { FadeContainer } from "@/components/fade-container";
import {
  FeatueContainer,
  FeatureDot,
  FeatureGroup,
} from "@/components/feature-groups";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/solid-dialog";
import { Plan, Service, ServiceType } from "@/lib/global-types";
import {
  cn,
  mountToSize,
  ORDER_PLANS,
  PartialBy,
  sizeToMountCount,
} from "@/lib/utils";

export type ServicesPayload = {
  selectedServices: Service[];
  selectedPlan: Plan | null | undefined;
};

const groupByType = (services: Service[]) => {
  return services.reduce(
    (acc, service) => {
      if (!acc[service.type]) {
        acc[service.type] = [];
      }

      acc[service.type].push(service);

      return acc;
    },
    {} as Record<ServiceType, Service[]>,
  );
};

type Props = {
  open: boolean;
  onClose: () => void;
  payload: ServicesPayload;
  setPayload: (payload: ServicesPayload | null) => void;
};
const OfferDialogInner = ({ payload, setPayload, open, onClose }: Props) => {
  const { selectedServices, selectedPlan } = payload;

  const plansAndServiceQuery = useQuery({
    queryKey: ["plansAndServices"],
    queryFn: () => {
      return Promise.all([get<Plan[]>("/plans"), get<Service[]>("/services")]);
    },
    initialData: [[], []],
  });

  const [plans, services] = plansAndServiceQuery.data;

  const groupedServices = groupByType(services);
  const [selectedType, setSelectedType] = useState<"packages" | "plans">(
    selectedPlan ? "plans" : "packages",
  );

  const handleClose = () => {
    if (!selectedPlan && selectedServices.length === 0) {
      setPayload(null);
    }
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogContent>
        <div className="flex max-h-screen w-[100vw] flex-col items-center gap-5 overflow-y-scroll py-20 pb-32 md:gap-10 md:pb-40">
          <h1>Pick your offers</h1>

          <div className="flex items-center gap-4">
            <Button
              className={cn({
                "bg-primary text-white": selectedType === "packages",
              })}
              onClick={() => setSelectedType("packages")}
            >
              Packages
            </Button>
            <Button
              className={cn({
                "bg-primary text-white": selectedType === "plans",
              })}
              onClick={() => setSelectedType("plans")}
            >
              Plans
            </Button>
          </div>

          {selectedType === "packages" && (
            <div className="flex flex-col items-center gap-4 md:flex-row">
              {Object.entries(groupedServices).map(([type, services]) => {
                const currentService = selectedServices.find(
                  (s) => s.type === type,
                );
                const count = !currentService
                  ? 0
                  : sizeToMountCount(currentService.size);

                const dots = [...Array(count)].map((_, i) => (
                  <FeatureDot key={i} animation />
                ));

                return (
                  <Button
                    size={"lg"}
                    key={type}
                    className="group"
                    onClick={() => {
                      const service = services.find((s) => s.type === type);
                      const rest = selectedServices.filter(
                        (s) => s.type !== type,
                      );
                      if (!service) return;

                      const nextMount = mountToSize((count % 4) + 1);

                      const nextSelectedServices = [...rest];

                      if (nextMount) {
                        const nextService = services.find(
                          (s) => s.size === nextMount && s.type === type,
                        );
                        if (nextService) nextSelectedServices.push(nextService);
                      }

                      setPayload({
                        ...payload,
                        selectedServices: nextSelectedServices,
                        selectedPlan: null,
                      });
                    }}
                  >
                    <FeatueContainer>
                      <FeatureGroup>
                        <span className="uppercase">{type.charAt(0)}</span>
                      </FeatureGroup>

                      {dots.length > 0 && (
                        <FeatureGroup className="gap-1">{dots}</FeatureGroup>
                      )}
                    </FeatueContainer>
                  </Button>
                );
              })}
            </div>
          )}

          {selectedType === "plans" && (
            <div className="flex flex-col items-center gap-4 md:flex-row">
              {plans
                .sort(
                  (a, b) =>
                    ORDER_PLANS.indexOf(a.name) - ORDER_PLANS.indexOf(b.name),
                )
                .map((plan) => (
                  <Button
                    key={plan.id}
                    size={"lg"}
                    className={cn(
                      {
                        "is-active bg-primary text-white":
                          plan.name === selectedPlan?.name,
                      },
                      "group-[.is-active] group",
                    )}
                    onClick={() => {
                      const isSamePlan = selectedPlan?.name === plan.name;
                      setPayload({
                        ...payload,
                        selectedPlan: isSamePlan ? null : plan,
                        selectedServices: [],
                      });
                    }}
                  >
                    <FeatueContainer className="group-[.is-active]:border-white group-[.is-active]:after:bg-white">
                      <FeatureGroup className="before:group-[.is-active]:bg-white first:before:group-[.is-active]:bg-white last:after:group-[.is-active]:bg-white">
                        <img
                          src={`/${plan.name}.png`}
                          alt={plan.name as string}
                          className="mx-1 mb-1 size-4 rounded-full"
                          width={16}
                          height={16}
                        />
                      </FeatureGroup>
                    </FeatueContainer>
                  </Button>
                ))}
            </div>
          )}

          <div className="absolute bottom-4 z-10">
            <FadeContainer className="flex items-center justify-center gap-4">
              <Button onClick={handleClose}>Confirm</Button>
            </FadeContainer>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const OfferDialog = ({
  payload,
  ...rest
}: PartialBy<Props, "payload">) => {
  if (!payload) return null;
  return <OfferDialogInner payload={payload} {...rest} />;
};
