import { observable } from "@legendapp/state";

import { HOST } from "@/lib/constants";
import {
  FounderOnboardingPayload,
  FounderPayload,
  KickoffDate,
  ServiceType,
  Stage,
} from "@/lib/global-types";

export type MonthlyEmailPreference = "yes" | "no" | undefined;

export type FounderNewState = Omit<FounderPayload, "stage" | "kickoff_date"> & {
  stage: Stage | null;
  showDuration?: boolean;
  requireDurationServiceTypes: ServiceType[];
  showServiceHighlight?: boolean;
  kickoff_date: KickoffDate | null;
};

export const founderNewState$ = observable<FounderNewState>({
  dev_base_url: HOST,
  email: "",
  kickoff_date: null,
  plan: undefined,
  services: [],
  stage: null,
  showDuration: false,
  requireDurationServiceTypes: [],
  showServiceHighlight: false,
});

export const isFounderNewCompleted$ = observable(() => {
  return (
    founderNewState$.stage.get() !== null ||
    founderNewState$.services.get().length > 0 ||
    founderNewState$.plan.get() !== undefined ||
    founderNewState$.kickoff_date.get() !== null
  );
});

export type FounderOnboardingState = Omit<
  FounderOnboardingPayload,
  "is_monthly_mail_allowed"
> & {
  is_monthly_mail_allowed: MonthlyEmailPreference;
};

export const founderOnboardingState$ = observable<FounderOnboardingState>({
  company_name: "",
  name: "",
  project_outcome_description: "",
  links: [],
  is_monthly_mail_allowed: undefined,
  profile_picture: null,
  time_zone: null,
});
