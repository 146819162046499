import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import dayjs from "dayjs";
import { useCallback, useState } from "react";

import { DesignerService } from "@/api/services/designer.service";
import { BottomNavigation } from "@/components/bottom-navigation";
import { CoundownTimer } from "@/components/countdown-timer";
import { helpDialog$ } from "@/components/dialogs/common/help";
import { IntroCallRequest } from "@/components/founder/intro-call-request";
import { LinkButton } from "@/components/link-button";
import { SlideContainer } from "@/components/slide-container";
import { Spinner } from "@/components/spinner";
import { StatusCircle } from "@/components/status-circle";
import { Button } from "@/components/ui/button";
import { useWebsocket } from "@/hooks/use-websocket";
import { DAYS, IS_DEV } from "@/lib/constants";
import { queryClient } from "@/lib/query-client";

export const Route = createFileRoute("/dashboard/designer/_designer/")({
  loader: async () => {
    const [designer, bookings] = await Promise.all([
      queryClient.ensureQueryData(DesignerService.getCurrentDesigner()),
      queryClient.ensureQueryData(DesignerService.getCurrentDesignerBookings()),
      queryClient.ensureQueryData(DesignerService.getProjects()),
    ]);

    return {
      designer,
      bookings,
    };
  },
  pendingComponent: () => (
    <div className="grid h-[100dvh] w-[100dvw] place-items-center">
      <Spinner />
    </div>
  ),
  component: function Page() {
    const { designer: initialDesigner, bookings } = Route.useLoaderData();
    const navigate = Route.useNavigate();

    const [designer, setDesigner] = useState(initialDesigner);
    const introCall = bookings.find((c) => c.type === "first_call");

    const projectQuery = useSuspenseQuery(DesignerService.getProjects());

    const assignedProjects = projectQuery.data;

    useWebsocket(designer.id, "DESIGNER_STATUS_UPDATED", setDesigner);

    const renderActionButton = useCallback(() => {
      switch (designer.status) {
        case "onboarded":
          return (
            <>
              <p>
                Thank you. <br />
                Give us a few days to <br /> review your profile.
              </p>
              <StatusCircle className="mt-10 md:mt-20" percentage={10}>
                Reviewing..
              </StatusCircle>
            </>
          );

        case "introCallRequestSent":
          return (
            <IntroCallRequest
              userType="designer"
              name={designer.name}
              email={designer.email}
              onSuccess={() => navigate({ to: "." })}
            />
          );

        case "introCallBooked":
          if (!introCall) return;
          return (
            <CoundownTimer
              startDate={dayjs(introCall.created_at)}
              finishDate={dayjs(introCall.date)}
              timeZone={designer.time_zone!}
            >
              First sync on {DAYS[dayjs(introCall.date).day()]} <br />
              {dayjs(introCall.date).format("DD MMM HH:mm")}
            </CoundownTimer>
          );

        case "introCallStarted":
          return (
            <div className="flex flex-col items-center gap-10 md:gap-20">
              <p className="text-center">It’s time. See you there.</p>
              <Button size="lg" asChild>
                <a
                  href={`https://app.cal.com/video/${introCall?.video_call_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Join the call
                </a>
              </Button>
            </div>
          );

        case "introCallEnded":
          if (assignedProjects.length > 0) {
            return (
              <LinkButton to="/dashboard/designer/projects" size="lg">
                View Assigned Projects
              </LinkButton>
            );
          }

          return (
            <Button size="lg" disabled>
              Waiting to project assignment
            </Button>
          );
      }
      return null;
    }, [
      assignedProjects,
      designer.email,
      designer.name,
      designer.status,
      designer.time_zone,
      introCall,
      navigate,
    ]);

    return (
      <div className="flex flex-col items-center gap-10 text-center md:gap-20">
        <SlideContainer animationKey={designer.status}>
          {renderActionButton()}
        </SlideContainer>

        {IS_DEV && (
          <code className="fixed left-5 top-5 flex flex-col items-start text-xs opacity-50">
            <span>email: {designer.email}</span>
            <span>id: {designer.id}</span>
            <span>status: {designer.status}</span>
          </code>
        )}

        <BottomNavigation
          left={
            <Button size={"sm"} onClick={() => helpDialog$.set(true)}>
              ?
            </Button>
          }
        />
      </div>
    );
  },
});
