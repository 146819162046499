import { useMemo } from "react";

import { ServicesPayload } from "@/components/proposals/offer-dialog";
import { ProposalResult, Propsal } from "@/lib/global-types";

const getServices = (proposal: ProposalResult["proposals"][number]) => {
  const plan = proposal.proposal_items.find((i) => i.type === "plan");

  if (plan?.plan) return plan.plan.services;
  else return proposal.proposal_items.map((i) => i.service!);
};

export const useProposals = (proposal: ProposalResult) => {
  return useMemo(() => {
    const proposals = {} as Record<Propsal["type"], ServicesPayload>;

    const requestProposal = proposal.proposals.find(
      (p) => p.type === "requested",
    );
    const recommendedProposal = proposal.proposals.find(
      (p) => p.type === "recommended",
    );
    const suggestedProposal = proposal.proposals.find(
      (p) => p.type === "suggested",
    );

    if (requestProposal) {
      const plan = requestProposal.proposal_items.find(
        (i) => i.type === "plan",
      );

      proposals.requested = {
        selectedPlan: plan?.plan
          ? {
              description: plan.plan.description,
              name: plan.plan.name,
              price: plan.plan.price,
              services: plan.plan.services,
              id: plan.id + "",
            }
          : null,
        selectedServices: getServices(requestProposal),
      };
    }

    if (recommendedProposal) {
      const plan = recommendedProposal.proposal_items.find(
        (i) => i.type === "plan",
      );

      proposals.recommended = {
        selectedPlan: plan?.plan
          ? {
              description: plan.plan.description,
              name: plan.plan.name,
              price: plan.plan.price,
              services: plan.plan.services,
              id: plan.id + "",
            }
          : null,
        selectedServices: getServices(recommendedProposal),
      };
    }

    if (suggestedProposal) {
      const plan = suggestedProposal.proposal_items.find(
        (i) => i.type === "plan",
      );

      proposals.suggested = {
        selectedPlan: plan?.plan
          ? {
              description: plan.plan.description,
              name: plan.plan.name,
              price: plan.plan.price,
              services: plan.plan.services,
              id: plan.id + "",
            }
          : null,
        selectedServices: getServices(suggestedProposal),
      };
    }

    return proposals;
  }, [proposal]);
};
