import { queryOptions, useMutation } from "@tanstack/react-query";

import { IS_DEV } from "@/lib/constants";
import { Update } from "@/lib/global-types";

import { get, patch, post } from "../client";

export class UpdatesService {
  static async getUpdate(updateId: number) {
    const res = await get<Update>(`/updates/${updateId}`);
    return res;
  }

  static getUpdates(founderId: string) {
    return queryOptions({
      queryKey: ["founder", founderId, "updates"],
      queryFn: () => get<Update[]>(`/founders/${founderId}/updates`),
    });
  }

  static getCurrentUpdate(founderId: string) {
    return queryOptions({
      queryKey: ["founder", founderId, "currentUpdate"],
      queryFn: () => get<Update>(`/founders/${founderId}/updates/current`),
    });
  }

  static async upsertUpdate(options: UpsertUpdatePayload) {
    const res = await patch<Update>(`/updates/${options.updateId}`, {
      description: options.description,
      images: options.images,
      next_steps: options.nextSteps,
      bypass_day_restriction: IS_DEV,
    });

    return res;
  }

  static async rateUpdate(options: RateUpdatePayload) {
    const res = await post<Update>(`/updates/${options.updateId}/rate`, {
      founder_rating: options.rating,
      founder_feedback: options.feedback,
    });

    return res;
  }

  static async sendUpdate(options: { updateId: Update["id"] }) {
    const res = await post<Update>(`/updates/${options.updateId}/send`, {
      bypass_day_restriction: IS_DEV,
    });

    return res;
  }

  static async markUpdateAsSeen(updateId: Update["id"]) {
    const res = await patch<Update>(`/updates/${updateId}/seen`);

    return res;
  }
}

export const useUpsertUpdate = () => {
  return useMutation({
    mutationFn: UpdatesService.upsertUpdate,
  });
};

export const useRateUpdate = () => {
  return useMutation({
    mutationFn: UpdatesService.rateUpdate,
  });
};

export const useSendUpdate = () => {
  return useMutation({
    mutationFn: UpdatesService.sendUpdate,
  });
};

export const useMarkUpdateAsSeen = () => {
  return useMutation({
    mutationFn: UpdatesService.markUpdateAsSeen,
  });
};

export type UpsertUpdatePayload = {
  updateId: Update["id"];
  description?: Update["description"];
  nextSteps?: Update["next_steps"];
  images?: string[];
};

export type RateUpdatePayload = {
  updateId: Update["id"];
  rating: number;
  feedback: string;
};
