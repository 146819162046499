import { useMemo } from "react";

import { SERVICE_SIZE_VALUES } from "@/lib/constants";
import { Founder } from "@/lib/global-types";
import { sizeToMountCount } from "@/lib/utils";

export const useFounderInfo = (founder: Founder) => {
  const hasPlan = founder.plan !== null;

  const allServices = useMemo(() => {
    if (founder.plan) return founder.plan.services;
    else return founder.services;
  }, [founder.plan, founder.services]);

  const maxDurationService = allServices.sort(
    (a, b) => SERVICE_SIZE_VALUES[b?.size] - SERVICE_SIZE_VALUES[a.size],
  )[0];

  const maxDurationMonths = useMemo(() => {
    return sizeToMountCount(maxDurationService?.size) as number;
  }, [maxDurationService?.size]);

  const price = useMemo(() => {
    if (founder.plan) return founder.plan.price;
    else
      return founder.services.reduce((acc, service) => acc + service.price, 0);
  }, [founder.plan, founder.services]);

  return {
    founder,
    hasPlan,
    allServices,
    maxDurationMonths,
    maxDurationService,
    price,
  };
};
