import { queryOptions } from "@tanstack/react-query";

import { HOST } from "@/lib/constants";
import {
  CallBooked,
  Creative,
  Founder,
  FounderOnboardingPayload,
  ProposalPayload,
  ProposalResult,
  UpdateableFounder,
} from "@/lib/global-types";

import { get, patch, post } from "../client";

export type FounderFilterOptions = {
  statuses?: Founder["status"][];
  offset?: number;
  limit?: number;
  is_onboarding_completed?: boolean;
};

export type DesignerFilterOptions = {
  statuses?: Creative["status"][];
  offset?: number;
  limit?: number;
};

export const PAGE_LIMIT = 15;
export type Count<Status> = { status: Status; count: number }[];

export class AdminService {
  static fetchFilteredFounderList({
    limit = PAGE_LIMIT,
    offset = 0,
    statuses = [],
    is_onboarding_completed = false,
  }: FounderFilterOptions) {
    return queryOptions({
      queryKey: ["founders", statuses, limit, offset, is_onboarding_completed],
      queryFn: () =>
        get<Founder[]>(`/admins/founders/filter`, {
          params: {
            limit,
            offset,
            statuses,
            is_onboarding_completed,
          },
        }),
    });
  }

  static async fetchFilteredDesignerList({
    limit = PAGE_LIMIT,
    offset = 0,
    statuses = [],
  }: DesignerFilterOptions) {
    const res = await get<Creative[]>(`/admins/designers/filter`, {
      params: {
        limit,
        offset,
        statuses,
      },
    });

    return res;
  }

  static fetchFounderCount() {
    return queryOptions({
      queryKey: ["founders", "count"],
      queryFn: () => get<Count<Founder["status"]>>(`/admins/founders/count`),
    });
  }

  static async fetchDesignerCount() {
    const res = await get<Count<Creative["status"]>>("/admins/designers/count");

    return res;
  }

  static fetchFounder(id: string) {
    return queryOptions({
      queryKey: ["founder", id],
      queryFn: () => get<Founder>(`/admins/founders/${id}`),
    });
  }

  static fetchDesigner(id: string) {
    return queryOptions({
      queryKey: ["designer", id],
      queryFn: () => get<Creative>(`/admins/designers/${id}`),
    });
  }

  static fetchFounderBookings(id: string) {
    return queryOptions({
      queryKey: ["founder", id, "bookings"],
      queryFn: () => get<CallBooked[]>(`/admins/founders/${id}/bookings`),
    });
  }

  static fetchCreativeBookings(creativeId: string) {
    return queryOptions({
      queryKey: ["creative", creativeId, "bookings"],
      queryFn: () =>
        get<CallBooked[]>(`/admins/creatives/${creativeId}/bookings`),
    });
  }

  static async updateFounderStatus(id: string, status: Founder["status"]) {
    const res = await patch<unknown>(`/admins/founders/${id}/update-status`, {
      status,
    });

    return res;
  }

  static async approveFounder(id: string) {
    const res = await post(`/admins/founders/${id}/approve`, {
      dev_base_url: HOST,
    });

    return res;
  }

  static async approveDesigner(id: string) {
    const res = await post(`/admins/designers/${id}/approve`, {
      dev_base_url: HOST,
    });

    return res;
  }

  static async rejectDesigner(id: string) {
    const res = await post(`/admins/designers/${id}/reject`, {
      dev_base_url: HOST,
    });

    return res;
  }

  static async updateFounder(id: string, data: UpdateableFounder) {
    const res = await patch(`/admins/founders/${id}/project-details`, data);

    return res;
  }

  static fetchFounderProposal(id: string) {
    return queryOptions({
      queryKey: ["founder", id, "proposals"],
      queryFn: () => get<ProposalResult>(`/admins/founders/${id}/proposals`),
      gcTime: 0,
    });
  }

  static async saveProposal(id: string, proposal: ProposalPayload) {
    const res = await patch(`/admins/founders/${id}/proposals`, proposal);

    return res;
  }

  static async publishProposal(id: string) {
    const res = await post(`/admins/founders/${id}/proposals/send`, {});

    return res;
  }

  static async approveReceipt(id: string) {
    const res = await patch(`/admins/founders/${id}/receipt-approved`, {});

    return res;
  }

  static assignDesignerToProject({
    founderId,
    designerId,
  }: {
    founderId: string;
    designerId: string;
  }) {
    return post(`/admins/founders/${founderId}/assign`, {
      creativeId: parseInt(designerId, 10),
    });
  }

  static createProject(
    payload: Omit<
      FounderOnboardingPayload,
      "is_monthly_mail_allowed" | "profile_picture"
    >,
  ) {
    return post<Founder>(`/admins/founders`, payload);
  }

  static async DEV_resetToOnboarding(id: string) {
    return await post(`/dev/founders/${id}/reset-to-onboarding`, {});
  }
}
