import { createFileRoute, Link } from '@tanstack/react-router'

import { FounderService } from '@/api/services/founder.service'
import { BottomNavigation } from '@/components/bottom-navigation'
import { FadeScaleContainer } from '@/components/fade-scale-container'
import {
  FeatueContainer,
  FeatureDot,
  FeatureGroup,
} from '@/components/feature-groups'
import { IconBackArrow } from '@/components/icons/icon-back-arrow'
import { ServicesPayload } from '@/components/proposals/offer-dialog'
import { Button } from '@/components/ui/button'
import { useProposals } from '@/hooks/use-proposal'
import { queryClient } from '@/lib/query-client'
import {
  capitalize,
  mountToSize,
  priceFormatter,
  sizeToMonth,
  sizeToMountCount,
} from '@/lib/utils'

export const Route = createFileRoute('/dashboard/founder/proposals_/included')({
  loader: async () => {
    const proposal = await queryClient.fetchQuery(
      FounderService.fetchFounderProposal(),
    )

    return { proposal }
  },
  component: function Page() {
    const { proposal } = Route.useLoaderData()

    const allProposals = useProposals(proposal)

    const renderIncludes = (prop: ServicesPayload) => {
      return prop.selectedServices.map((service) => {
        const serviceDuration = sizeToMountCount(service.size)
        const dots = [...Array(serviceDuration)].map((_, i) => (
          <FeatureDot key={i} />
        ))

        return (
          <div key={service.id} className="mb-20 flex flex-col items-center">
            <FeatueContainer>
              <FeatureGroup className="w-7">
                {service.type.at(0)?.toUpperCase()}
              </FeatureGroup>
              <FeatureGroup className="gap-2">{dots}</FeatureGroup>
            </FeatueContainer>

            <p className="mt-10 capitalize">{service.type}</p>
            <p className="mt-2">{sizeToMonth(service.size)}</p>
            <p>{priceFormatter.format(service.price)}</p>

            <p className="mt-6">Deliverables</p>
            <p>{service.description}</p>
          </div>
        )
      })
    }

    const renderHeader = (prop: ServicesPayload) => {
      const maxDuration = prop.selectedServices.reduce(
        (acc, service) => Math.max(acc, sizeToMountCount(service.size)),
        0,
      )

      const dots = [...Array(maxDuration)].map((_, i) => <FeatureDot key={i} />)
      const price = prop.selectedPlan
        ? prop.selectedPlan.price
        : prop.selectedServices.reduce((acc, service) => acc + service.price, 0)

      return (
        <div className="mt-10 flex flex-col items-center">
          <FeatueContainer>
            {prop.selectedPlan ? (
              <FeatureGroup>
                <img
                  src={`/${prop.selectedPlan.name}.png`}
                  alt={prop.selectedPlan.name as string}
                  className="mx-1 mb-1 size-4 rounded-full"
                  width={16}
                  height={16}
                />
              </FeatureGroup>
            ) : (
              prop.selectedServices.map((service) => (
                <FeatureGroup key={service.id} className="w-7">
                  {service.type.at(0)?.toUpperCase()}
                </FeatureGroup>
              ))
            )}
            {prop.selectedPlan ? null : (
              <FeatureGroup className="gap-2">{dots}</FeatureGroup>
            )}
          </FeatueContainer>

          <p className="mt-10">
            {prop.selectedServices
              .map((service) => capitalize(service.type))
              .join(' + ')}
          </p>
          <p>{sizeToMonth(mountToSize(maxDuration)!)}</p>
          <p>{priceFormatter.format(price)}</p>
        </div>
      )
    }

    return (
      <>
        <FadeScaleContainer className="flex w-screen flex-col pb-20 md:flex-row">
          <section className="mx-auto flex flex-1 flex-col items-center">
            <h1>Request</h1>
            {renderHeader(allProposals.requested)}
            <p className="my-20">Includes</p>
            {renderIncludes(allProposals.requested)}
          </section>

          <section className="mx-auto flex flex-1 flex-col items-center">
            <h1>Recommendation</h1>
            {renderHeader(allProposals.recommended)}
            <p className="my-20">Includes</p>
            {renderIncludes(allProposals.recommended)}
          </section>

          <section className="mx-auto flex flex-1 flex-col items-center">
            <h1>Suggestion</h1>
            {renderHeader(allProposals.suggested)}
            <p className="my-20">Includes</p>
            {renderIncludes(allProposals.suggested)}
          </section>
        </FadeScaleContainer>
        <BottomNavigation
          middle={
            <Button size={'sm'} asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
        />
      </>
    )
  },
})
