import { useMutation } from "@tanstack/react-query";
import { createFileRoute, redirect } from "@tanstack/react-router";

import { FounderService } from "@/api/services/founder.service";
import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useUmamiPageEvent } from "@/lib/umami";
import {
  founderNewState$,
  isFounderNewCompleted$,
} from "@/store/founder.state";

export const Route = createFileRoute("/new/founder/email")({
  beforeLoad: () => {
    if (!isFounderNewCompleted$.peek()) {
      return redirect({ to: "/new/founder" });
    }
  },
  component: function Page() {
    const email = founderNewState$.email.get();
    const navigate = Route.useNavigate();
    useUmamiPageEvent("Founder - What’s your email?");

    const saveFounderMutation = useMutation({
      mutationFn: FounderService.createFounder,
    });

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      const founder = founderNewState$.get();
      await saveFounderMutation.mutateAsync({
        stage: founder.stage!,
        plan: founder.plan,
        services: founder.services,
        kickoff_date: founder.kickoff_date!,
        email: founder.email,
        dev_base_url: founder.dev_base_url,
      });

      navigate({ to: "/new/founder/check-email" });
    };

    return (
      <form onSubmit={handleSubmit}>
        <section className="flex flex-col items-center justify-center text-center">
          <h1 className="mt-10">What’s your email?</h1>

          <Input
            className="mt-10 md:mt-20"
            autoFocus
            name="email"
            id="email"
            type={"email"}
            onChange={(e) => founderNewState$.email.set(e.target.value)}
            placeholder={"@"}
            value={email}
            size={1}
          />
        </section>

        <BottomNavigation
          middle={
            email && (
              <Button type="submit" loading={saveFounderMutation.isPending}>
                Submit
              </Button>
            )
          }
          left={
            <BackButton
              type="button"
              disabled={saveFounderMutation.isPending}
              size={"sm"}
              native
            />
          }
        />
      </form>
    );
  },
});
