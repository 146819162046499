import { useMutation } from "@tanstack/react-query";

import { get } from "../client";

type SignFileParam = {
  name: string;
  type: string;
};

type SignFileResponse = {
  writeUrl: string;
  readUrl: string;
};

type PutFileParam = {
  writeUrl: string;
  file: File;
};

export class UploadService {
  static async signFile(options: SignFileParam) {
    const res = await get<SignFileResponse>(
      `/storage/signed-put-url?filename=${options.name}&fileType=${options.type}`,
    );
    return res;
  }

  static async putFile(options: PutFileParam) {
    const res = await fetch(options.writeUrl, {
      method: "PUT",
      body: options.file,
    });

    return res;
  }
}

export const useSignFile = () => {
  return useMutation({
    mutationFn: UploadService.signFile,
  });
};

export const usePutFile = () => {
  return useMutation({
    mutationFn: UploadService.putFile,
  });
};
