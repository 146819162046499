import "./sentry.client.config";

import ReactDOM from "react-dom/client";

import { Bootstrap } from "./bootstrap";

const rootElement = document.getElementById("app")!;

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<Bootstrap />);
}
