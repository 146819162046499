import { useSuspenseQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";

import { UpdatesService } from "@/api/services/updates.service";
import { Button } from "@/components/ui/button";
import { useUpdateTime } from "@/hooks/use-update-times";
import { Founder } from "@/lib/global-types";

type Props = {
  founder: Founder;
};

export const UpdateAction = ({ founder }: Props) => {
  const updatesQuery = useSuspenseQuery(
    UpdatesService.getCurrentUpdate(founder.id),
  );

  const currentUpdate = updatesQuery.data;

  const { nextUpdateText } = useUpdateTime(
    founder.time_zone!,
    founder.time_zone!, // TODO: chnage timezone when assign feature is implemented
  );

  return (
    <>
      <Button asChild size="lg" disabled={!currentUpdate}>
        <Link
          to="/dashboard/admin/founders/$founderId/updates/$updateId"
          params={{
            founderId: founder.id,
            updateId: String(currentUpdate.id),
          }}
        >
          {nextUpdateText}
        </Link>
      </Button>
      <Button asChild>
        <Link
          to="/dashboard/admin/founders/$founderId/updates"
          params={{ founderId: founder.id }}
        >
          Updates
        </Link>
      </Button>
    </>
  );
};
