import "../chat.scss";

import { useMountOnce } from "@legendapp/state/react";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { Channel, Chat, MessageInput, MessageList } from "stream-chat-react";

import { Spinner } from "@/components/spinner";

import { chatClient, chatService } from "../chat.service";
import { chatState$ } from "../chat.state";
import { CustomMessage } from "../components/custom-message";

export const ChatView = () => {
  const startQueryMutation = useMutation({
    mutationFn: async () => {
      await chatService.init();
      await chatService.connectUser();

      switch (chatService.userType) {
        case "client":
          await chatService.joinChannelAsFounder();
          break;
        case "admin": {
          const targetFounder = chatState$.targetFounder.peek();
          if (!targetFounder) {
            throw new Error("Target founder not found");
          }
          await chatService.joinChannelAsAdmin(targetFounder);
          break;
        }
        default:
          throw new Error(
            `Not implemented for user type: ${chatService.userType}`,
          );
      }
    },
    onError: () => {
      chatState$.dialogOpen.set(false);
    },
  });

  const channel = useMemo(() => {
    if (startQueryMutation.isPending) return null;

    try {
      return chatService.channel;
    } catch (_) {
      return null;
    }
  }, [startQueryMutation.isPending]);

  useMountOnce(() => {
    startQueryMutation.mutate();
  });

  useEffect(() => {
    return () => {
      chatService.disconnectUser();
    };
  }, []);

  const loading = startQueryMutation.isPending;

  if (loading || !channel)
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner />
      </div>
    );

  return (
    <Chat client={chatClient}>
      <Channel channel={channel}>
        <MessageList Message={CustomMessage} />
        <MessageInput />
      </Channel>
    </Chat>
  );
};
