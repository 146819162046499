import { observable } from "@legendapp/state";

export type CreativesTiers = "L1" | "L2" | "L3";
export type CreativeOnboardingPayload = {
  name: string;
  time_zone: string;
  tier: CreativesTiers | null;
  monthly_rate: number | null;
};

export const creativeOnboardingState$ = observable<CreativeOnboardingPayload>({
  name: "",
  time_zone: "",
  tier: null,
  monthly_rate: null,
});

export const isCreativeOnboardingCompleted$ = observable(() => {
  return (
    creativeOnboardingState$.name.get().length > 0 ||
    creativeOnboardingState$.time_zone.get().length > 0 ||
    creativeOnboardingState$.tier.get() !== null ||
    creativeOnboardingState$.monthly_rate.get() !== null
  );
});
