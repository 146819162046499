import { createFileRoute, Link, Outlet } from "@tanstack/react-router";

import { OPLogo } from "@/components/op-logo";

export const Route = createFileRoute("/dashboard/founder")({
  component: () => (
    <div className="h-svh">
      <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
        <Link to="/">
          <OPLogo />
        </Link>
      </header>

      <div className="h-full pb-40 pt-36">
        <Outlet />
      </div>
    </div>
  ),
});
