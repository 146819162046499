import { useInfiniteQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import { AdminService } from "@/api/services/admin.service";
import { CreativeListItem } from "@/components/admin/creative-list-item";
import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { Spinner } from "@/components/spinner";
import { Creative } from "@/lib/global-types";

import { Filter } from "./_admin.index";

export const CREATIVE_FILTERS: Record<Filter, Creative["status"][]> = {
  next: ["onboarded"],
  past: ["rejected"],
  present: [
    "introCallBooked",
    "introCallStarted",
    "introCallRequestSent",
    "introCallEnded",
  ],
};

export const Route = createFileRoute("/dashboard/admin/creatives")({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      filter: search.filter as Filter | undefined,
    };
  },
  loaderDeps: ({ search: { filter } }) => ({ filter }),
  loader: async ({ deps: { filter } }) => {
    const statuses = filter ? CREATIVE_FILTERS[filter] : CREATIVE_FILTERS.next;
    const creatives = await AdminService.fetchFilteredDesignerList({
      statuses,
    });

    const countList = await AdminService.fetchDesignerCount();

    return { creatives, countList };
  },
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: function Page() {
    const { creatives, countList } = Route.useLoaderData();
    const { filter } = Route.useSearch();
    const { ref, inView } = useInView();

    const statuses = filter ? CREATIVE_FILTERS[filter] : CREATIVE_FILTERS.next;
    const filters = CREATIVE_FILTERS[filter as keyof typeof CREATIVE_FILTERS];

    const currentFilterTotalCount = countList.reduce(
      (prevItem, currentItem) => {
        if (filters.includes(currentItem.status)) {
          return prevItem + currentItem.count;
        } else {
          return prevItem;
        }
      },
      0,
    );

    const designersQuery = useInfiniteQuery({
      queryKey: ["designers", statuses],
      queryFn: ({ pageParam }) => {
        return AdminService.fetchFilteredDesignerList({
          statuses,
          offset: pageParam,
        });
      },
      initialData: () => ({
        pageParams: [0],
        pages: [creatives],
      }),
      initialPageParam: 0,
      getNextPageParam: (_, allPages) => {
        const total = allPages.flat().length;
        if (total < currentFilterTotalCount) {
          return total;
        }

        return undefined;
      },
    });

    const flatDesigners = designersQuery.data.pages.flat();

    useEffect(() => {
      if (inView) {
        designersQuery.fetchNextPage();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView, designersQuery.data.pageParams]);

    return (
      <div className="flex flex-col items-center">
        <span>{currentFilterTotalCount} Creatives</span>

        <div className="flex flex-wrap justify-center gap-4 pt-20">
          {flatDesigners.map((designer, index) => (
            <motion.div
              key={designer.id}
              animate={{ y: 0, opacity: 1, scale: 1 }}
              initial={{ y: 10, opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.2, delay: index * 0.05 }}
            >
              <CreativeListItem creative={designer} key={designer.id} />
            </motion.div>
          ))}
        </div>

        {designersQuery.hasNextPage && (
          <div ref={ref} className="mt-10 text-center">
            <Spinner />
          </div>
        )}

        <BottomNavigation left={<BackButton search={{ filter }} />} />
      </div>
    );
  },
});
