import {
  createFileRoute,
  Outlet,
  useRouterState,
} from "@tanstack/react-router";

import { Tags } from "@/components/founder/Tags";
import { SlideContainer } from "@/components/slide-container";
import { direction$ } from "@/store/page.state";

export const Route = createFileRoute("/new/founder")({
  component: function Page() {
    const direction = direction$.get();
    const currentPathName = useRouterState({
      select: (state) => state.location.pathname,
    });

    return (
      <section className="flex h-full flex-col items-center justify-center pt-[88px] md:pt-[144px]">
        <Tags />

        <SlideContainer
          className="h-full"
          animationKey={currentPathName}
          direction={direction}
        >
          <Outlet />
        </SlideContainer>
      </section>
    );
  },
});
