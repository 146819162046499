import { createFileRoute } from "@tanstack/react-router";

import { BottomNavigation } from "@/components/bottom-navigation";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { creativeOnboardingState$ } from "@/store/creatives.state";

export const Route = createFileRoute("/onboarding/creatives/$type/")({
  component: function Page() {
    const { type } = Route.useParams();
    const name = creativeOnboardingState$.name.get();
    const navigate = Route.useNavigate();

    return (
      <form
        className="text-center"
        onSubmit={(e) => {
          e.preventDefault();
          navigate({
            to: "/onboarding/creatives/$type/time-zone",
            params: { type: type },
          });
        }}
      >
        <h1>
          {"Welcome to OP."} <br />
          {"What should we call you?"}
        </h1>

        <div className="mt-10 md:mt-20">
          <Input
            autoFocus
            type={"text"}
            onChange={(e) => {
              creativeOnboardingState$.name.set(e.target.value);
            }}
            placeholder={"Your name"}
            value={name}
            size={1}
          />
        </div>

        <BottomNavigation
          middle={name && <Button type="submit">Next</Button>}
        />
      </form>
    );
  },
});
