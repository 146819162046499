import { Link } from "@tanstack/react-router";

import { FoundersTag } from "@/components/founders-tag";
import { StatusCircle } from "@/components/status-circle";
import { useFounderInfo } from "@/hooks/use-founder-info";
import { Founder } from "@/lib/global-types";
import { generateKickOffDateLabel, priceFormatter } from "@/lib/utils";

const StatusOrders: Founder["status"][] = [
  "rejected",
  "onboarding",
  "onboarded",
  "introCallRequestSent",
  "introCallBooked",
  "introCallStarted",
  "introCallEnded",
  "proposalSent",
  "proposalCreated",
  "proposalConfirmed",
  "agreementAccepted",
  "companyDetailsSubmitted",
  "invoiceSent",
  "paymentMade",
  "kickoffCallRequestSent",
  "kickoffCallBooked",
  "kickoffCallStarted",
  "projectStarted",
];

type Props = {
  founder: Founder;
};
export const FounderItem = ({ founder }: Props) => {
  const status = founder.status;

  const percentage =
    (StatusOrders.indexOf(status) / (StatusOrders.length - 1)) * 100;

  const { price } = useFounderInfo(founder);

  return (
    <StatusCircle percentage={percentage}>
      <Link
        to="/dashboard/admin/founders/$founderId"
        params={{ founderId: founder.id }}
        className="flex flex-col items-center justify-center gap-4 text-center"
      >
        <div>
          <h2 className="text-ellips max-h-7 overflow-hidden break-all">
            {founder.company_name}
          </h2>
          <h1>{founder.name}</h1>
        </div>
        <FoundersTag
          stage={founder.stage}
          services={founder.services}
          plan={founder.plan}
          className="scale-75"
        />

        <div>
          <h3>{priceFormatter.format(price)}</h3>
          <h3>{generateKickOffDateLabel(founder.kickoff_date)}</h3>
        </div>
      </Link>
    </StatusCircle>
  );
};
