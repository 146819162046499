import { AnimatePresence, motion } from "framer-motion";
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";

import { cn } from "@/lib/utils";

export function NewsletterSignup() {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [width, setWidth] = useState(83);
  const emailWidthSpanRef = useRef<HTMLSpanElement>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setIsValidEmail(validateEmail(event.target.value));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidEmail) return;
    setIsSubmitted(true);

    const formData = {
      data: {
        type: "subscription",
        attributes: {
          custom_source: "openpurpose.com",
          profile: {
            data: {
              type: "profile",
              attributes: {
                email: email,
              },
            },
          },
        },
        relationships: {
          list: {
            data: {
              type: "list",
              id: "SSr6MQ",
            },
          },
        },
      },
    };

    const response = await fetch(
      "https://a.klaviyo.com/client/subscriptions/?company_id=UmKH2U",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          revision: "2024-07-15",
        },
        body: JSON.stringify(formData),
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    if (response.ok) {
      setIsSuccess(true);
    }
  };

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  useEffect(() => {
    if (emailWidthSpanRef.current) {
      if (emailWidthSpanRef.current.offsetWidth < 200)
        setWidth(emailWidthSpanRef.current.offsetWidth);
    }
  }, [email]);

  return (
    <form
      id="email-form"
      onSubmit={handleSubmit}
      className="relative inline-block h-10 text-center"
    >
      <span
        ref={emailWidthSpanRef}
        key="email-width-span"
        className="invisible absolute whitespace-nowrap text-base outline-none"
      >
        {email || "Subscribe"}
      </span>
      <div className="relative inline-block">
        {!isSuccess && (
          <div
            className={cn(
              "absolute left-1/2 top-1/2 h-10 -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-full border",
              !isSubmitted && "border-black",
              isSubmitted &&
                "animate-pulse border-dashed border-black transition-all [transition-duration:300ms]",
            )}
            style={
              isSubmitted
                ? { width: "32px", height: "32px" }
                : {
                    width: `calc(${width}px + 16px * 2 + 4px + ${
                      isValidEmail ? 28 : 0
                    }px`,
                  }
            }
          >
            <AnimatePresence mode="popLayout">
              {!isSubmitted && (
                <motion.input
                  type="email"
                  value={email}
                  onChange={handleChange}
                  onFocus={() => setIsInputActive(true)}
                  onBlur={() => setIsInputActive(false)}
                  placeholder="Subscribe"
                  name="email"
                  key="email-input"
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ type: "spring", duration: 0.3, bounce: 0 }}
                  style={{
                    width: `calc(${width}px + 16px * 2 + 4px + ${
                      isValidEmail ? 20 : 0
                    }px)`,
                    padding: "16px 16px",
                    paddingRight: isValidEmail ? "38px" : "16px",
                  }}
                  className={cn(
                    "size-full bg-transparent text-center text-base outline-none placeholder:text-tertiary placeholder:transition-colors hover:placeholder:text-tertiary",
                    !isInputActive && "placeholder:text-black",
                  )}
                />
              )}

              {isValidEmail && !isSubmitted && (
                <motion.button
                  className={cn(
                    "absolute right-1 top-[4px] flex size-[30px] cursor-pointer items-center justify-center rounded-full border-none bg-black text-white transition-colors",
                  )}
                  style={{ right: isSubmitted ? "calc(50% - 15px)" : "4px" }}
                  key="submit-button"
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ type: "spring", duration: 0.3, bounce: 0 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </motion.button>
              )}
            </AnimatePresence>
          </div>
        )}

        {isSuccess && (
          <motion.div
            key="success-message"
            transition={{ type: "spring", duration: 0.3, bounce: 0 }}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
          >
            Check your inbox.
          </motion.div>
        )}
      </div>
    </form>
  );
}
