export const IconClose = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9697 1.96967L1.96967 12.9697L3.03033 14.0303L14.0303 3.03033L12.9697 1.96967ZM1.96967 3.03033L12.9697 14.0303L14.0303 12.9697L3.03033 1.96967L1.96967 3.03033Z"
        fill="currentColor"
      />
    </svg>
  );
};
