import { type ClassValue, clsx } from "clsx";
import dayjs from "dayjs";
import timezoneplugin from "dayjs/plugin/timezone";
import utcplugin from "dayjs/plugin/utc";
import DOMPurify from "isomorphic-dompurify";
import parsePhoneNumber from "libphonenumber-js";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

import { BACKEND_URL, PackageDetail, PACKAGES } from "@/lib/constants";

import {
  Founder,
  KickoffDate,
  PlanName,
  Service,
  ServiceSize,
  ServiceType,
  Stage,
} from "../global-types";

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const validateEmail = (email: string | undefined) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const replaceAndSanitize = (
  string: string,
  searchValue: string | RegExp,
  replaceValue: string,
) => {
  return DOMPurify.sanitize(string.replaceAll(searchValue, replaceValue));
};

export const generateKickOffDateLabel = (date: KickoffDate) => {
  switch (date) {
    case "nextWeek":
      return "Next week";
    case "nextMonth":
      return "Next month";
    case "later":
      return "Later";
    default:
      return "Later";
  }
};

export const priceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const signFile = async (fileName: string, fileType: string) => {
  const res = await fetch(
    `${BACKEND_URL}/storage/signed-put-url?filename=${fileName}&fileType=${fileType}`,
  );

  const json = await res.json();

  if (!res.ok) throw new Error("An error has occured with the file sign!");

  return json;
};

export const putFile = async (writeUrl: string, file: File) => {
  const res = await fetch(writeUrl, {
    method: "PUT",
    body: file,
  });

  if (!res.ok) throw new Error("An error has occured with the file upload!");
};

export const getPackagePrice = (packageDetail: PackageDetail, stage: Stage) => {
  switch (stage) {
    case "preSeed":
      return packageDetail.preSeedPrice;
    case "seed":
      return packageDetail.seedPrice;
    case "seriesAplus":
      return packageDetail.seriesAPlusPrice;
    default:
      return 0;
  }
};

export const urlSchema = z
  .string()
  .refine(
    (value) => /^(https?):\/\/(?=.*\.[a-z]{2,})[^\s$.?#].[^\s]*$/i.test(value),
    {
      message: "Please enter a valid URL",
    },
  );

export const formatTimezone = (input: string) => {
  return input.replaceAll("_", " ").replaceAll("/", " / ");
};

export const unFormatTimezone = (input: string) => {
  return input.trim().replaceAll(" / ", "/").replaceAll(" ", "_");
};

export const timeFormatter = (
  date?: string | number | dayjs.Dayjs | Date | null,
  timezone?: string,
) => {
  dayjs.extend(utcplugin);
  dayjs.extend(timezoneplugin);

  if (timezone) {
    return dayjs(date).tz(timezone);
  }

  return dayjs(date);
};

/**
 * Zod schema that validates a phone number using `libphonenumber-js`.
 * Attempts to parse the provided value with a default country of `TR`.
 *
 * If the phone number is valid, the schema transforms the phone number into
 * an international format (e.g. `+358401234567`).
 */
export const zPhoneNumber = z.string().transform((value, ctx) => {
  const phoneNumber = parsePhoneNumber(value, {
    defaultCountry: "TR",
  });

  if (!phoneNumber?.isValid()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Invalid phone number",
    });
    return z.NEVER;
  }

  return phoneNumber.formatInternational();
});

const sizeMap = (size: ServiceSize) => {
  switch (size) {
    case "s":
      return "small";
    case "m":
      return "medium";
    case "l":
      return "large";
    default:
      return undefined;
  }
};

const calculatePrice = (founder: Founder) => {
  let price = 0;

  for (const currentService of founder.services) {
    const currentPackage = PACKAGES.find(
      (service) => service.name === currentService.type,
    );
    if (!currentPackage) return -1;
    const currentSize = sizeMap(currentService.size);
    if (currentSize === undefined) return -1;
    const currentPackageDetail = currentPackage.details[currentSize];
    price += getPackagePrice(currentPackageDetail, founder.stage);
  }
  return price;
};

export const getProjectCost = (founder: Founder) => {
  return calculatePrice(founder) < 0
    ? "Not sure"
    : priceFormatter.format(calculatePrice(founder));
};

export const groupByType = (services: Service[]) => {
  return services.reduce(
    (acc, service) => {
      if (!acc[service.type]) {
        acc[service.type] = [];
      }

      acc[service.type].push(service);

      return acc;
    },
    {} as Record<ServiceType, Service[]>,
  );
};

export const sizeToMountCount = (size: ServiceSize) => {
  switch (size) {
    case "s":
      return 1;
    case "m":
      return 2;
    case "l":
      return 3;
    default:
      return 0;
  }
};

export const mountToSize = (mount: number) => {
  switch (mount) {
    case 1:
      return "s";
    case 2:
      return "m";
    case 3:
      return "l";
    default:
      return null;
  }
};

export const sizeToMonth = (size: ServiceSize) => {
  switch (size) {
    case "s":
      return "1 month";
    case "m":
      return "2 months";
    case "l":
      return "3 months";
  }
};

export const capitalize = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const ORDER_PLANS: PlanName[] = ["essential", "extended", "beyond"];
export const SIZE_ORDER = ["s", "m", "l"] as ServiceSize[];

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const getRatingColor = (rating?: number) => {
  switch (rating) {
    case -1:
      return "bg-red";
    case 0:
      return "bg-yellow";
    case 1:
      return "bg-green";
    default:
      return "";
  }
};
