import { createFileRoute, Link } from "@tanstack/react-router";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";

import { BottomNavigation } from "@/components/bottom-navigation";
import { FadeSection } from "@/components/fade-section";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { OPLogoLarge } from "@/components/op-logo-large";
import ProjectFeed from "@/components/samples/project-feed";
import { PROJECTS } from "@/components/samples/projects";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

const FILTERS = [
  "brand",
  "product",
  "website",
  "B2B",
  "B2C",
  "web3",
  /* "startup",
  "companies",
  "enterprise", */
] as const;

type FilterDialogProps = {
  open: boolean;
  selected: (typeof FILTERS)[number] | undefined;
  onSelect: (option: (typeof FILTERS)[number]) => void;
  onClose: () => void;
};
export const FilterDialog = ({
  open,
  onClose,
  onSelect,
  selected,
}: FilterDialogProps) => {
  return (
    <AnimatePresence>
      {open && (
        <div className="fixed inset-0 z-40 flex w-full flex-col bg-white data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
          <motion.div
            className="h-full"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{
              ease: "easeInOut",
              duration: 0.2,
            }}
          >
            <div className="flex h-full w-full flex-col items-center pt-[120px] md:pt-[144px]">
              {/* // create 3x3 grid with buttons */}
              <h1>
                Filter by selecting <br />
                one of the options below.
              </h1>
              <div className="mt-10 grid grid-cols-3 gap-4 md:mt-20">
                {FILTERS.map((filter) => (
                  <Button
                    key={filter}
                    className={cn({
                      "bg-black text-white": filter === selected,
                      "bg-white text-black": filter !== selected,
                    })}
                    onClick={() => onSelect(filter)}
                  >
                    <p className="capitalize">{filter}</p>
                  </Button>
                ))}
              </div>
            </div>

            <BottomNavigation
              className="bg-transparent"
              left={
                <Button size="sm" onClick={onClose}>
                  <IconBackArrow />
                </Button>
              }
            />
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export const Route = createFileRoute("/samples/_samples/")({
  validateSearch: (search: Record<string, unknown>) => {
    const isValid =
      "filter" in search && FILTERS.includes(search.filter as never);

    return {
      filter: isValid ? (search.filter as (typeof FILTERS)[number]) : undefined,
    };
  },
  component: function SamplePage() {
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const navitate = Route.useNavigate();
    const { filter } = Route.useSearch();

    const filteredProjects = useMemo(() => {
      switch (filter) {
        case "brand":
        case "website":
        case "product": {
          const firstLetter = filter[0].toUpperCase();
          return PROJECTS.filter((p) => p.tags.includes(firstLetter));
        }
        /*    case "startup":
        case "companies":
        case "enterprise": {
          const stepMap = {
            startup: "0 - 1",
            companies: "1 - N",
            enterprise: "N - XYZ",
          };

          return PROJECTS.filter((p) => p.step === stepMap[filter]);
        } */
        case "B2B":
        case "B2C":
        case "web3": {
          return PROJECTS.filter((p) => p.type === filter);
        }
        default:
          return PROJECTS;
      }
    }, [filter]);

    useEffect(() => {
      if (filterModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }, [filterModalOpen]);

    return (
      <main>
        <header className="fixed left-0 right-0 top-6 z-50 flex justify-center text-white mix-blend-difference md:top-10">
          <Link to="/">
            <OPLogoLarge />
          </Link>
        </header>
        <motion.section key={filter} className="pb-44 pt-[120px] md:pt-[144px]">
          <FadeSection>
            {!filter && (
              <>
                <Button asChild>
                  <Link to="..">Samples</Link>
                </Button>
              </>
            )}

            <div
              className={cn("container px-1", {
                "mt-20": !filter,
              })}
            >
              <ProjectFeed
                projects={filteredProjects.sort(() => 0.5 - Math.random())}
              />
            </div>
          </FadeSection>
        </motion.section>

        <FilterDialog
          selected={filter}
          open={filterModalOpen}
          onClose={() => setFilterModalOpen(false)}
          onSelect={(option) => {
            setFilterModalOpen(false);

            document.documentElement.scrollTo({
              top: 0,
              behavior: "instant",
            });
            if (option === filter)
              return navitate({
                to: ".",
                search: { filter: undefined },
                replace: true,
              });

            return navitate({
              to: ".",
              search: { filter: option },
              replace: true,
            });
          }}
        />

        <BottomNavigation
          className="bg-transparent"
          left={
            <Button size="sm" asChild>
              <Link to="/">
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={
            <Button
              className="capitalize"
              onClick={() => {
                setFilterModalOpen(true);
              }}
            >
              {filter ? filter : "Filter"}
            </Button>
          }
        />
      </main>
    );
  },
});
