import { createFileRoute, Link } from "@tanstack/react-router";
import { motion } from "framer-motion";
import { useEffect } from "react";

import { FadeSection } from "@/components/fade-section";
import { FEATURE_BRANDS_LOGOS } from "@/components/featured-brands";
import { NewsletterSignup } from "@/components/newsletter-signup";
import { OPLogo } from "@/components/op-logo";
import { OPLogoLarge } from "@/components/op-logo-large";
import StartButton from "@/components/start-button";
import { Button } from "@/components/ui/button";
import { animation$ } from "@/store/page.state";

export const Route = createFileRoute("/")({
  component: HomeComponent,
});

function HomeComponent() {
  useEffect(() => {
    // prefetch featured brands
    FEATURE_BRANDS_LOGOS.forEach((logo) => {
      const image = new Image();
      image.src = logo;
    });
  }, []);

  return (
    <main className="grid h-svh place-items-center">
      <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
        <Link to="/">
          <OPLogoLarge />
        </Link>
      </header>

      <motion.section className="pt-[100px] md:pb-[100px]">
        <FadeSection>
          <div className="flex flex-row items-center justify-center gap-4">
            <Button asChild>
              <Link to="/about">About</Link>
            </Button>

            <Button asChild>
              <Link to="/samples" search={{ filter: undefined }}>
                Samples
              </Link>
            </Button>
          </div>

          <Link
            to="/new"
            className="mt-10 inline-block md:mt-10"
            onClick={() => animation$.set("slide-up")}
          >
            <StartButton />
          </Link>

          <div className="mt-10 flex flex-row items-center justify-center gap-2 md:mt-10">
            <Button asChild size={"sm"}>
              <a
                href="https://x.com/openpurpose"
                target="_blank"
                rel="noreferrer"
              >
                X
              </a>
            </Button>
            <Button asChild size={"sm"}>
              <a
                href="https://www.instagram.com/openpurpose/"
                target="_blank"
                rel="noreferrer"
              >
                IG
              </a>
            </Button>
            <Button asChild size={"sm"}>
              <a
                href="https://www.youtube.com/@openpurpose"
                target="_blank"
                rel="noreferrer"
              >
                YT
              </a>
            </Button>
          </div>

          <div className="mt-10">
            <NewsletterSignup />
          </div>
        </FadeSection>
      </motion.section>

      <footer className="bottom-6 left-0 right-0 mt-10 md:fixed md:mt-0">
        <Link to="/" className="mx-auto inline-block">
          <OPLogo />
        </Link>
      </footer>
    </main>
  );
}
