import { createFileRoute, Link } from "@tanstack/react-router";
import { useState } from "react";

import {
  UpdatesService,
  useUpsertUpdate,
} from "@/api/services/updates.service";
import { usePutFile, useSignFile } from "@/api/services/upload.service";
import { BottomNavigation } from "@/components/bottom-navigation";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { cn, getRatingColor } from "@/lib/utils";
import { toast } from "@/lib/utils/toast";

export const Route = createFileRoute(
  "/dashboard/admin/founders/$founderId/updates/$updateId/",
)({
  loader: async ({ params: { updateId } }) => {
    const update = await UpdatesService.getUpdate(parseInt(updateId));

    return { update };
  },
  component: function Page() {
    const { update } = Route.useLoaderData();

    const [description, setDescription] = useState(update.description || "");
    const [imageList, setImageList] = useState<File[]>([]);
    const [imageSourceList, setImageSourceList] = useState<string[]>(
      update.images || [],
    );

    const signFileMutation = useSignFile();
    const putFileMutation = usePutFile();
    const upsertUpdateMutation = useUpsertUpdate();

    const navigate = Route.useNavigate();

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedImages = e.target.files;
      if (!selectedImages) return;
      if (selectedImages.length + imageList.length > 50) {
        toast("You can only upload a maximum of 50 files");
        return;
      }

      const newImageSourceList = Array.from(selectedImages).map((image) =>
        URL.createObjectURL(image),
      );

      setImageList([...imageList, ...selectedImages]);
      setImageSourceList([...imageSourceList, ...newImageSourceList]);
    };

    const isMutationsPending =
      upsertUpdateMutation.isPending ||
      signFileMutation.isPending ||
      putFileMutation.isPending;

    return (
      <>
        <div className="flex flex-col items-center gap-20">
          <div className="flex flex-col items-center gap-10">
            <Button
              className={cn({
                "border-gray-200": update.founder_rating === null,
              })}
            >
              <span className="flex flex-col items-center gap-2">
                {update.founder_rating !== null &&
                  update.founder_rating !== undefined && (
                    <span
                      className={cn(
                        "size-2 rounded-full",
                        getRatingColor(update.founder_rating),
                      )}
                    ></span>
                  )}
                <span>{update.order}</span>
              </span>
            </Button>

            <Textarea
              className="min-w-[230px]"
              placeholder="Describe your update"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="flex flex-col items-center gap-10">
            {imageSourceList.map((imageSource, index) => (
              <div
                key={imageSource}
                className={cn(
                  "group relative flex aspect-video w-[90vw] max-w-[960px] cursor-pointer items-center justify-center overflow-hidden rounded-2xl ring-1 ring-gray-200",
                )}
              >
                <img
                  height={540}
                  width={960}
                  src={imageSource}
                  alt={`preview of image ${index}`}
                />

                <div className="absolute inset-0 z-10 size-auto border-none opacity-0 backdrop-blur-[2px] transition-all group-hover:opacity-100">
                  <div className="absolute inset-0 z-0 flex items-center justify-center bg-black/10">
                    <Button
                      loading={isMutationsPending}
                      onClick={() => {
                        const filteredImageSourceList = imageSourceList.filter(
                          (previousImage) => previousImage !== imageSource,
                        );

                        setImageSourceList([...filteredImageSourceList]);
                      }}
                      className="absolute z-10"
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            ))}

            <div className="relative flex aspect-video w-[90vw] max-w-[960px] items-center justify-center overflow-hidden rounded-2xl border border-dashed border-tertiary">
              <span className="text-secondary">Upload image</span>

              <input
                type="file"
                className="absolute size-full cursor-pointer opacity-0"
                onChange={handleImageChange}
                accept="image/png, image/jpeg"
                multiple
              />
            </div>
          </div>
        </div>

        <BottomNavigation
          left={
            <Button size="sm" disabled={isMutationsPending} asChild>
              <Link
                to="/dashboard/admin/founders/$founderId"
                params={{
                  founderId: String(update.founder_id),
                }}
              >
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={
            <Button
              loading={isMutationsPending}
              onClick={async () => {
                const sampleList: string[] = [];
                const signedWriteUrlList: string[] = [];

                for (const image of imageList) {
                  const signedFile = await signFileMutation.mutateAsync({
                    name: image.name,
                    type: image.type,
                  });

                  if (!signedFile) return;
                  sampleList.push(signedFile.readUrl);
                  signedWriteUrlList.push(signedFile.writeUrl);
                }

                let index = 0;
                for (const image of imageList) {
                  await putFileMutation.mutateAsync({
                    writeUrl: signedWriteUrlList[index],
                    file: image,
                  });

                  index++;
                }

                const newUpdate = await upsertUpdateMutation.mutateAsync({
                  updateId: update.id,
                  description,
                  images: sampleList,
                });

                if (!newUpdate) return;

                navigate({
                  to: "/dashboard/admin/founders/$founderId/updates/$updateId/preview",
                  params: {
                    founderId: String(newUpdate.founder_id),
                    updateId: String(newUpdate.id),
                  },
                });
              }}
            >
              Preview
            </Button>
          }
        />
      </>
    );
  },
});
