import { createFileRoute, Link } from '@tanstack/react-router'
import { useMemo } from 'react'

import { FounderService } from '@/api/services/founder.service'
import { BottomNavigation } from '@/components/bottom-navigation'
import {
  FeatueContainer,
  FeatureDot,
  FeatureGroup,
} from '@/components/feature-groups'
import { IconBackArrow } from '@/components/icons/icon-back-arrow'
import { Button } from '@/components/ui/button'
import { queryClient } from '@/lib/query-client'
import {
  capitalize,
  priceFormatter,
  sizeToMountCount,
  timeFormatter,
} from '@/lib/utils'

export const Route = createFileRoute('/dashboard/founder/invoice_/detail')({
  loader: async () => {
    const founder = await queryClient.fetchQuery(
      FounderService.getCurrentFounder(),
    )

    return { founder }
  },

  component: function Page() {
    const { founder } = Route.useLoaderData()

    const allServices = useMemo(() => {
      if (founder.plan) return founder.plan.services
      else return founder.services
    }, [founder.plan, founder.services])

    const price = useMemo(() => {
      if (founder.plan) return founder.plan.price
      else return allServices.reduce((acc, service) => acc + service.price, 0)
    }, [allServices, founder.plan])

    const hasPlan = !!founder.plan

    const maxDurationService = allServices.sort(
      (a, b) => sizeToMountCount(b.size) - sizeToMountCount(a.size),
    )[0]

    const dotCount = sizeToMountCount(maxDurationService.size)

    const dots = Array.from({ length: dotCount }, (_, i) => (
      <FeatureDot key={i} />
    ))

    const servicesText = allServices.map((s) => capitalize(s.type)).join(' + ')

    return (
      <section>
        <div className="mx-auto flex max-w-[320px] flex-col items-center gap-10 pb-52 md:max-w-[420px]">
          <div className="flex flex-col items-center text-center">
            <p># {founder.invoice_number}</p>
            <p>
              Issued {timeFormatter(founder.updated_at).format('DD MMM YYYY')}
            </p>
            <p className="text-red">
              Due{' '}
              {timeFormatter(founder.updated_at)
                .add(14, 'days')
                .format('DD MMM YYYY')}
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <p>From</p>
            <p>O P U F SOFTWARE HOUSE L.L.C.</p>
            <p>Office # G-01-449, Al Khabeesi, Al Ittihad,</p>
            <p> Dubai, United Arab Emirates</p>
          </div>
          <div className="flex flex-col items-center text-center">
            <p>To</p>
            <p>{founder.billing?.legal_entity_name}</p>
            <p>{founder.billing?.company_address}</p>
            <p>{founder.billing?.country}</p>
            <p>{founder.billing?.company_phone}</p>
          </div>

          <Button
            size={'xl'}
            className="flex cursor-default flex-col items-center gap-10 hover:bg-white hover:text-primary"
          >
            <FeatueContainer>
              {hasPlan
                ? founder.plan?.services.map((service) => {
                    return (
                      <FeatureGroup key={service.id}>
                        {service.type.at(0)?.toUpperCase()}
                      </FeatureGroup>
                    )
                  })
                : allServices.map((service) => {
                    return (
                      <FeatureGroup key={service.id}>
                        {service.type.at(0)?.toUpperCase()}
                      </FeatureGroup>
                    )
                  })}

              {dots && <FeatureGroup className="gap-2">{dots}</FeatureGroup>}
            </FeatueContainer>

            <div>
              <h2>Services</h2>
              <p>{servicesText}</p>
            </div>

            <div>
              <h2>Total</h2>
              <p>{priceFormatter.format(price)}</p>
            </div>
          </Button>

          <div className="flex flex-col items-center text-center">
            <p>Bank details</p>
            <br />
            <p>O P U F SOFTWARE HOUSE L.L.C.</p>
            <p>IBAN: AE920330000019101408346</p>
            <p>MASHREQBANK PSC. DUBAI</p>
            <p>Account: 019101408346</p>
            <p>SWIFT: BOMLAEAD</p>
          </div>
        </div>

        <BottomNavigation
          left={
            <Button size={'sm'} asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={
            <Button size={'default'} asChild>
              <a
                href={founder.invoice_url || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </a>
            </Button>
          }
        />
      </section>
    )
  },
})
