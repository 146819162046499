/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment } from "react";

import { cn } from "@/lib/utils";

export default function ProjectCode({
  step,
  tags,
  duration,
  plan = false,
}: {
  step?: "0 - 1" | "1 - N" | "N - XYZ";
  tags?: string[];
  duration?: number;
  plan?: boolean;
}) {
  return (
    <div
      className={cn(
        "relative flex h-8 w-auto min-w-10 scale-75 items-center justify-center text-base leading-7 md:scale-100",
      )}
    >
      <div
        className={
          "absolute inset-x-0 bottom-0 left-1/2 h-0.5 w-full -translate-x-1/2 bg-primary"
        }
      />
      <div className={"absolute bottom-0 left-0 h-4 w-0.5 bg-primary"} />
      <div className={"absolute -right-0.5 bottom-0 h-4 w-0.5 bg-primary"} />

      {step === "0 - 1" && (
        <div className="flex w-[96px] items-center justify-between px-2.5">
          <span>0</span>
          <span>→</span>
          <span>1</span>
        </div>
      )}

      {step === "1 - N" && (
        <div className="flex w-[106px] items-center justify-between px-2.5">
          <span>1</span>
          <span>→</span>
          <span>N</span>
        </div>
      )}

      {step === "N - XYZ" && (
        <div className="flex w-[115px] items-center justify-between px-2.5">
          <span>N</span>
          <span>→</span>
          <span>XYZ</span>
        </div>
      )}

      {tags && tags.length > 0 && (
        <div
          className="relative flex items-center justify-between"
          style={{ width: tags.length * 44 }}
        >
          {tags.map((tag: any, index: number) => (
            <Fragment key={tag}>
              <div
                className={"absolute -bottom-0.5 left-0 h-4 w-0.5 bg-primary"}
              />
              <span className="w-[44px] text-center uppercase">
                {tag.charAt(0).toUpperCase()}
              </span>
              <div
                className="absolute -bottom-0.5 h-4 w-0.5 bg-primary"
                style={{
                  right: index * 44,
                }}
              />
            </Fragment>
          ))}
        </div>
      )}

      <div
        className="relative flex h-full items-center justify-center gap-1.5 px-2.5"
        data-duration={duration}
        data-plan={plan}
        style={{
          width: plan
            ? "38px"
            : duration === 1
              ? "38px"
              : duration === 2
                ? "56px"
                : "74px",
        }}
      >
        {plan && duration === 1 && (
          <img
            src="/essential.png"
            alt="Essential"
            className="size-4 rounded-full object-cover"
            width={16}
            height={16}
          />
        )}
        {plan && duration === 2 && (
          <img
            src="/extended.png"
            alt="Extended"
            className="size-4 rounded-full object-cover"
            width={16}
            height={16}
          />
        )}
        {plan && duration === 3 && (
          <img
            src="/beyond.png"
            alt="Beyond"
            className="size-4 rounded-full object-cover"
            width={16}
            height={16}
          />
        )}

        {!plan && (
          <div key="1month" className="size-3 rounded-full bg-primary" />
        )}
        {!plan && duration && duration > 1 && (
          <div key="2month" className="size-3 rounded-full bg-primary" />
        )}
        {!plan && duration && duration > 2 && (
          <div key="3month" className="size-3 rounded-full bg-primary" />
        )}
      </div>
    </div>
  );
}
