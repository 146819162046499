import { createFileRoute, Link, redirect } from "@tanstack/react-router";
import { useState } from "react";

import { usePutFile, useSignFile } from "@/api/services/upload.service";
import { BottomNavigation } from "@/components/bottom-navigation";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { toast } from "@/lib/utils/toast";
import {
  designerCreateState$,
  isDesignerCreateCompleted$,
} from "@/store/designer.state";

export const Route = createFileRoute("/new/designer/samples")({
  beforeLoad: () => {
    if (!isDesignerCreateCompleted$.peek()) {
      return redirect({ to: "/new/designer" });
    }
  },
  component: function Page() {
    const [files, setFiles] = useState<File[]>([]);
    const signFileMutation = useSignFile();
    const uploadFileMutation = usePutFile();

    const navigation = Route.useNavigate();

    const addImage = (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedImages = e.target.files;
      if (!selectedImages) return;
      if (selectedImages.length + files.length > 3) {
        toast("You can only upload a maximum of 3 files");
        return;
      }

      setFiles([...files, ...selectedImages]);
    };

    const onClickNext = async () => {
      if (files.length < 3) {
        toast("Please upload at least 3 images");
        return;
      }

      designerCreateState$.samples.set([]);

      for (const image of files) {
        const { readUrl, writeUrl } = await signFileMutation.mutateAsync({
          name: image.name,
          type: image.type,
        });

        designerCreateState$.samples.push(readUrl);

        await uploadFileMutation.mutateAsync({ writeUrl, file: image });
      }

      navigation({ to: "/new/designer/links" });
    };

    return (
      <div className="pb-52 text-center">
        <h1 className="mb-4">
          We set high standards for ourselves. <br />
          Share 3 screenshots of your best work. <br />
          1920x1080px — White background.
        </h1>
        <a
          className="underline"
          target="_blank"
          href="https://www.figma.com/design/q0pfjl1NZkV1HTUgcFnTm0/OP-Template?node-id=0-1&t=KDnzWlasyeYbqUUn-1"
          rel="noreferrer"
        >
          View examples
        </a>

        <div className="mx-auto mt-10 flex w-[90%] flex-wrap gap-4 pb-10 md:mt-20 md:w-[50%] md:flex-row md:pb-0">
          {files.map((image, index) => (
            <div
              key={image.name + index}
              className={cn(
                "border-border border-primary/8 group relative w-full overflow-hidden rounded-lg border md:rounded-3xl",
              )}
            >
              <img
                src={URL.createObjectURL(image)}
                alt={`preview of image ${index}`}
                className="w-full"
              />
              <Button
                onClick={() => {
                  const filteredImages = files.filter(
                    (previousImage) => previousImage !== image,
                  );

                  setFiles([...filteredImages]);
                }}
                className="abosoute absolute inset-0 left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform opacity-0 transition-all group-hover:opacity-100"
              >
                Remove
              </Button>
            </div>
          ))}
          {files.length < 3 && (
            <div className="relative flex aspect-video w-full items-center justify-center overflow-hidden rounded-lg border border-dashed border-tertiary">
              <Button>Add</Button>

              <input
                type="file"
                className="absolute size-full cursor-pointer opacity-0"
                onChange={addImage}
                accept="image/png, image/jpeg"
                multiple
              />
            </div>
          )}
        </div>

        <BottomNavigation
          className="bg-transparent"
          middle={
            files.length >= 3 && (
              <Button
                loading={
                  signFileMutation.isPending || uploadFileMutation.isPending
                }
                onClick={onClickNext}
              >
                Next
              </Button>
            )
          }
          left={
            <Button
              type="button"
              disabled={
                signFileMutation.isPending || uploadFileMutation.isPending
              }
              size={"sm"}
              asChild
            >
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
        />
      </div>
    );
  },
});
