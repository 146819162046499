"use client";

import { motion } from "framer-motion";

import {
  FeatueContainer,
  FeatureDot,
  FeatureGroup,
} from "@/components/feature-groups";
import { SERVICE_SIZE_VALUES } from "@/lib/constants";
import { cn } from "@/lib/utils";
import { founderNewState$ } from "@/store/founder.state";

export const Tags = () => {
  const founder = founderNewState$.get();

  const {
    stage,
    services,
    plan,
    showDuration,
    requireDurationServiceTypes,
    showServiceHighlight,
  } = founder;

  const currentDurationSelectionServiceType = requireDurationServiceTypes[0];

  const renderStage = () => {
    switch (stage) {
      case "preSeed":
        return (
          <FeatureGroup className="flex items-center justify-between px-2.5">
            <motion.div
              key={stage}
              initial={{ opacity: 0, y: "1rem" }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: "-1rem" }}
            >
              <span>0</span>
              <span className="mx-6">→</span>
              <span>1</span>
            </motion.div>
          </FeatureGroup>
        );
      case "seed":
        return (
          <FeatureGroup
            key={stage}
            className="flex items-center justify-between px-2.5"
          >
            <motion.div
              key={stage}
              initial={{ opacity: 0, y: "1rem" }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: "-1rem" }}
            >
              <span>1</span>
              <span className="mx-6">→</span>
              <span>N</span>
            </motion.div>
          </FeatureGroup>
        );
      case "seriesAplus":
        return (
          <FeatureGroup
            key={stage}
            className="flex items-center justify-between px-2.5"
          >
            <motion.div
              key={stage}
              initial={{ opacity: 0, y: "1rem" }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: "-1rem" }}
            >
              <span>N</span>
              <span className="mx-6">→</span>
              <span>XYZ</span>
            </motion.div>
          </FeatureGroup>
        );
      default:
        return (
          <FeatureGroup>
            <div className="h-6 w-24" />
          </FeatureGroup>
        );
    }
  };

  const renderServices = () => {
    const order = ["brand", "website", "product"];

    if (founder.plan) return null;
    return founder.services
      .sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type))
      .map((service) => {
        return (
          <FeatureGroup key={`${service.type}`} className="w-7 uppercase">
            <motion.div
              key={`${service.type}`}
              initial={{ opacity: 0, y: "1rem" }}
              animate={{ opacity: 1, y: 0 }}
              className={cn({
                "text-black/20":
                  currentDurationSelectionServiceType !== service.type &&
                  showServiceHighlight,
              })}
            >
              {service.type.charAt(0)}
            </motion.div>
          </FeatureGroup>
        );
      });
  };

  const renderDuration = () => {
    if (!showDuration) return null;
    const allServices = services;
    if (plan) return;

    const maxDurationService = allServices.sort(
      (a, b) => SERVICE_SIZE_VALUES[b.size!] - SERVICE_SIZE_VALUES[a.size!],
    )[0];

    if (!maxDurationService?.size) return null;

    const maxMount = SERVICE_SIZE_VALUES[maxDurationService.size];
    const dots = Array.from({ length: maxMount }, (_, i) => (
      <FeatureDot key={i} />
    ));

    return (
      <FeatureGroup data-cy="new-founder-tag-durations">
        <motion.div
          className="flex items-center gap-2"
          key={maxDurationService.size}
          initial={{ opacity: 0, y: "1rem" }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "-1rem" }}
        >
          {dots}
        </motion.div>
      </FeatureGroup>
    );
  };

  const renderPlan = () => {
    if (!plan) return null;
    return (
      <FeatureGroup className="flex items-center justify-between">
        <img
          src={`/${plan}.png`}
          alt={plan}
          className="mx-1 mb-1 size-4 rounded-full"
          width={10}
          height={10}
        />
      </FeatureGroup>
    );
  };

  return (
    <FeatueContainer data-cy="new-founder-tag">
      {renderStage()} {renderPlan()} {renderServices()} {renderDuration()}
    </FeatueContainer>
  );
};
