import { createFileRoute, useNavigate } from "@tanstack/react-router";
import umami from "@umami/node";

import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { Stage } from "@/lib/global-types";
import { useUmamiPageEvent } from "@/lib/umami";
import { founderNewState$ } from "@/store/founder.state";
import { animation$ } from "@/store/page.state";

export const Route = createFileRoute("/new/founder/")({
  component: function Page() {
    const stage = founderNewState$.stage.get();
    useUmamiPageEvent("Founder - What stage is your company at?");

    const navigate = useNavigate({ from: "/new/founder/" });

    return (
      <div>
        <section className="flex flex-col items-center justify-center">
          <h1 className="mt-10">What’s your company stage?</h1>
          <ToggleGroup
            className="mt-10 md:mt-20"
            type="single"
            defaultValue={stage!}
            onValueChange={(value) => {
              if (value) {
                founderNewState$.stage.set(value as Stage);

                umami.track("Founder Answer - What stage is your company at?", {
                  Stage: value,
                });
                navigate({ to: "/new/founder/packages-and-plan" });
              }
            }}
          >
            <ToggleGroupItem value="preSeed">Upstart</ToggleGroupItem>
            <ToggleGroupItem value="seed">Growth</ToggleGroupItem>
            <ToggleGroupItem value="seriesAplus">Legacy</ToggleGroupItem>
          </ToggleGroup>
        </section>

        <BottomNavigation
          left={
            <BackButton native onClick={() => animation$.set("slide-right")} />
          }
        />
      </div>
    );
  },
});
