import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { createFileRoute } from "@tanstack/react-router";
import dayjs from "dayjs";

import { trpcClientUtils } from "@/api/trpc";
import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { FadeSection } from "@/components/fade-section";
import { Spinner } from "@/components/spinner";

export const Route = createFileRoute("/dashboard/admin/site-reports")({
  loader: async () => {
    return {
      stats: await trpcClientUtils.siteReports.getWebsiteStats.ensureData(),
      klavioStats:
        await trpcClientUtils.siteReports.getKlavioReport.ensureData(),
    };
  },
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: RouteComponent,
});

function RouteComponent() {
  const { stats, klavioStats } = Route.useLoaderData();

  const lastWeekVisitorData = stats.last7Days.total.map((totalData, index) => {
    const newData = stats.last7Days.newPage[index];

    return {
      date: totalData.date,
      total: totalData.value - newData.value,
      newPage: newData.value,
    };
  });

  const referrerData = stats.referrer
    .map((data) => {
      return {
        id: data.x,
        label: data.x,
        value: data.y,
      };
    })
    .slice(0, 5);

  return (
    <main className="container flex w-full flex-col gap-20 pb-40 md:w-[70%]">
      <FadeSection>
        <h1>Site Reports</h1>

        <div>
          <h3>Total Visitors of last week</h3>
          <span>{stats.totoalVisitorLastWeek.total}</span>

          <div className="mx-auto h-80 w-full md:w-1/2">
            <ResponsiveBar
              data={lastWeekVisitorData}
              keys={["newPage", "total"]}
              indexBy={"date"}
              enableGridX={false}
              enableGridY={false}
              margin={{ top: 60, bottom: 50 }}
              axisLeft={null}
              axisBottom={{
                format: (value) => dayjs(value).format("ddd"),
              }}
              label={({ value, data: { total }, id }) => {
                const realTotal = value! + total;
                if (id === "newPage") {
                  return `${((value! / realTotal) * 100).toFixed(2)}%`;
                }
                return String(realTotal);
              }}
              theme={{
                text: {
                  fill: "#ffffff",
                },
                axis: {
                  ticks: {
                    text: {
                      fill: "#111111",
                    },
                  },
                },
                legends: {
                  text: {
                    fill: "#111111",
                  },
                },
              }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top-right",
                  direction: "column",
                  itemHeight: 20,
                  itemWidth: 30,
                  translateX: -50,
                  translateY: -60,
                },
              ]}
              colors={["#000000", "rgb(217, 217, 217)"]}
              legendLabel={({ id }) => {
                if (id === "newPage") {
                  return "/new Page";
                }
                return "Total";
              }}
              labelTextColor={({ key }) => {
                if (key.startsWith("newPage")) {
                  return "white";
                }

                return "black";
              }}
              tooltip={({ value, id, data: { total } }) => {
                const getValue = () => {
                  if (id === "newPage") {
                    return value;
                  }
                  return value + total;
                };
                return (
                  <div className="rounded-md bg-white p-2 shadow-md">
                    <h4>{id === "newPage" ? "/new page" : "Total"}</h4>
                    <span>{getValue()}</span>
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div>
          <h3>Top Referrers</h3>

          <div className="mx-auto h-80 w-full md:w-1/2">
            <ResponsivePie
              data={referrerData}
              margin={{ top: 50, bottom: 50 }}
              innerRadius={0}
              padAngle={0.7}
              colors={{ scheme: "tableau10" }}
              arcLabel={({ data }) => {
                // percentage
                const total = referrerData.reduce(
                  (acc, curr) => acc + curr.value,
                  0,
                );

                return `${((data.value / total) * 100).toFixed(2)}%`;
              }}
              arcLabelsSkipAngle={30}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              tooltip={({ datum: { value, id } }) => {
                const total = referrerData.reduce(
                  (acc, curr) => acc + curr.value,
                  0,
                );

                return (
                  <div className="rounded-md bg-white p-2 shadow-md">
                    <h4>{id}</h4>
                    <span>
                      {value} ({((value / total) * 100).toFixed(2)}%)
                    </span>
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div>
          <h3>Last Email Campaings</h3>

          <div className="mx-auto h-[500px] w-full md:w-1/2">
            <ResponsiveBar
              data={klavioStats}
              indexBy={"campaignName"}
              keys={["openRate", "clickRate", "unsubscribeRate"]}
              margin={{ top: 50, bottom: 200, right: 0, left: 0 }}
              axisLeft={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 90,
              }}
              theme={{
                text: {
                  fill: "#ffffff",
                },
                axis: {
                  ticks: {
                    text: {
                      fill: "#111111",
                    },
                  },
                },
                legends: {
                  text: {
                    fill: "#111111",
                  },
                },
              }}
              colors={{
                scheme: "category10",
              }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top-right",
                  direction: "row",
                  itemHeight: 20,
                  itemWidth: 80,
                  translateX: -20,
                  translateY: -30,
                },
              ]}
              legendLabel={({ id }) => {
                switch (id) {
                  case "openRate":
                    return "Open Rate";
                  case "clickRate":
                    return "Click Rate";
                  case "unsubscribeRate":
                    return "Uns Rate";
                  default:
                    return String(id);
                }
              }}
              colorBy="id"
              valueFormat={(value) => `${value.toFixed(2)}%`}
              tooltip={({ data }) => {
                return (
                  <div className="rounded-md bg-white p-2 shadow-md">
                    <h4 className="font-bold">{data.campaignName}</h4>
                    <span className="text-blue-400">
                      Delivered: {data.delivered}
                    </span>
                    <br />
                    <span>Unique Clicks: {data.uniqueClicks}</span>
                    <br />
                    <span>Click Rate: {data.clickRate.toFixed(2)}%</span>
                    <br />
                    <span>Total Clicks: {data.clicks}</span>
                    <br />
                    <span className="text-green">Opens: {data.opens}</span>
                    <br />
                    <span>Unique Opens: {data.opensUnique}</span>
                    <br />
                    <span>Open Rate: {data.openRate.toFixed(2)}%</span>
                    <br />
                    <span className="text-rose-500">
                      Unsubscribes: {data.unsubscribesUnique}
                    </span>
                    <br />
                    <span>
                      Unsubscribe Rate: {data.unsubscribeRate.toFixed(2)}%
                    </span>
                  </div>
                );
              }}
            />
          </div>
        </div>
      </FadeSection>
      <BottomNavigation left={<BackButton />} />
    </main>
  );
}
