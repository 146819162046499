import {
  createFileRoute,
  Link,
  Outlet,
  useRouterState,
} from "@tanstack/react-router";

import { OPLogo } from "@/components/op-logo";
import { SlideContainer } from "@/components/slide-container";
import { direction$ } from "@/store/page.state";

export const Route = createFileRoute("/onboarding")({
  component: function Layout() {
    const direction = direction$.get();
    const currentPathName = useRouterState({
      select: (state) => state.location.pathname,
    });

    return (
      <div className="h-screen">
        <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
          <Link to="/">
            <OPLogo />
          </Link>
        </header>
        <div className="h-full pt-[120px] md:pt-[144px]">
          <SlideContainer
            className="h-full"
            animationKey={currentPathName}
            direction={direction}
          >
            <Outlet />
          </SlideContainer>
        </div>
      </div>
    );
  },
});
