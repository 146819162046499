import {
  createFileRoute,
  Outlet,
  useRouterState,
} from "@tanstack/react-router";

import { SlideContainer } from "@/components/slide-container";
import { direction$ } from "@/store/page.state";

export const Route = createFileRoute("/new/designer")({
  component: function Page() {
    const direction = direction$.get();
    const currentPathName = useRouterState({
      select: (state) => state.location.pathname,
    });

    return (
      <section className="h-full pt-[88px] md:pt-[144px]">
        <SlideContainer
          className="h-full will-change-auto"
          animationKey={currentPathName}
          direction={direction}
        >
          <Outlet />
        </SlideContainer>
      </section>
    );
  },
});
