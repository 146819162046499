import { createFileRoute, Link, redirect } from "@tanstack/react-router";
import umami from "@umami/node";

import { get } from "@/api/client";
import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { Button } from "@/components/ui/button";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { Service } from "@/lib/global-types";
import { useUmamiPageEvent } from "@/lib/umami";
import {
  founderNewState$,
  isFounderNewCompleted$,
} from "@/store/founder.state";

export const Route = createFileRoute("/new/founder/packages")({
  beforeLoad: () => {
    if (!isFounderNewCompleted$.peek()) {
      return redirect({ to: "/new/founder" });
    }
  },
  loader: () => get<Service[]>("/services"),
  staleTime: Infinity,
  component: function Page() {
    const services = Route.useLoaderData();
    const founderServices = founderNewState$.services.get();
    useUmamiPageEvent("Founder - Package Selector");

    return (
      <div>
        <section className="flex flex-col items-center justify-center text-center">
          <h1 className="mt-10">
            Pick the service you need. <br />
            You can select more than one.
          </h1>

          <ToggleGroup
            className="mt-10 md:mt-20"
            type="multiple"
            value={founderServices.map((service) => service.type)}
            onValueChange={(value) => {
              if (value) {
                const order = ["brand", "website", "product"];
                const selectedServices = services
                  .filter(
                    (service) =>
                      value.includes(service.type) && service.size === "s",
                  )
                  .sort(
                    (a, b) => order.indexOf(a.type) - order.indexOf(b.type),
                  );

                founderNewState$.plan.set(undefined);
                founderNewState$.requireDurationServiceTypes.set(
                  selectedServices.map((s) => s.type),
                );

                founderNewState$.services.set(
                  selectedServices.map((service) => ({
                    size: "s" as const,
                    type: service.type,
                  })),
                );
              }
            }}
          >
            <ToggleGroupItem value="brand">Brand</ToggleGroupItem>
            <ToggleGroupItem value="website">Website</ToggleGroupItem>
            <ToggleGroupItem value="product">Product</ToggleGroupItem>
          </ToggleGroup>
        </section>

        <BottomNavigation
          left={<BackButton native />}
          middle={
            founderServices.length > 0 && (
              <Button
                asChild
                onClick={() =>
                  umami.track("Founder Answer - Plan Selector", {
                    Services: founderServices
                      .map((service) => `${service.type} - ${service.size}`)
                      .join(", "),
                  })
                }
              >
                <Link to="/new/founder/packages/durations">Next</Link>
              </Button>
            )
          }
        />
      </div>
    );
  },
});
