import { queryOptions } from "@tanstack/react-query";

import {
  BillingDetails,
  Call,
  CallBooked,
  Founder,
  FounderOnboardingPayload,
  FounderPayload,
  ProposalResult,
} from "@/lib/global-types";

import { get, post } from "../client";

export class FounderService {
  static async createFounder(founder: FounderPayload) {
    const res = await post<Founder>("/founders", founder);
    return res;
  }

  static async onboardFounder(founder: FounderOnboardingPayload) {
    const res = await post<Founder>("/founders/details", founder);

    const cookieStore = window.localStorage;

    cookieStore.setItem(
      "isOnboardingCompleted",
      String(res.is_onboarding_completed),
    );

    return res;
  }

  static getCurrentFounder() {
    return queryOptions({
      queryKey: ["founder", "current"],
      queryFn: () => get<Founder>("/founders/me"),
    });
  }

  static getCurrentFounderBookings() {
    return queryOptions({
      queryKey: ["founder", "current", "bookings"],
      queryFn: () => get<CallBooked[]>("/founders/bookings/me"),
    });
  }

  static async bookCall(call: Call) {
    const res = await post(`/founders/book-call`, {
      external_id: call.id,
      date: call.date,
      type: call.type,
      video_call_id: call.video_call_id,
      duration: call.duration,
    });

    return res;
  }

  static fetchFounderProposal() {
    return queryOptions({
      queryKey: ["founder", "current", "proposal"],
      queryFn: () => get<ProposalResult>(`/founders/proposals`),
    });
  }

  static async acceptProposal(proposalId: string) {
    const res = await post(`/founders/proposals/${proposalId}/accept`, {});

    return res;
  }

  static async acceptAgreement() {
    const res = await post(`/founders/accept-agreement`, {});
    return res;
  }

  static async addBillingDetails(payload: BillingDetails) {
    const res = await post(`/founders/billing/details`, {
      legal_entity_name: payload.legal_entity_name,
      country: payload.country,
      company_address: payload.company_address,
      company_phone: payload.company_phone,
    });

    return res;
  }

  static async addReceiptUrl(payload: { receiptUrl: string }) {
    const res = await post(`/founders/billing/receipt`, {
      receipt_url: payload.receiptUrl,
    });

    return res;
  }
}
