import { useEffect, useRef, useState } from "react";

export default function DemoReel() {
  const reelVideoRef = useRef<HTMLVideoElement>(null);
  const idleTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [muted, setMuted] = useState(true);
  const [idle, setIdle] = useState(true);

  useEffect(() => {
    if (reelVideoRef.current === null) return;
    const videoRef = reelVideoRef.current;
    const onMouseMove = () => {
      if (idle) {
        setIdle(false);
      }
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current);
      }
      idleTimeoutRef.current = setTimeout(() => {
        setIdle(true);
      }, 1000);
    };

    videoRef.addEventListener("mousemove", onMouseMove);

    return () => {
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current);
      }
      videoRef.removeEventListener("mousemove", onMouseMove);
    };
  }, [idle]);

  return (
    <div className="flex flex-col gap-4">
      <button
        className={"z-10 underline focus:outline-none"}
        onClick={() => {
          reelVideoRef.current!.muted = !reelVideoRef.current!.muted;
          setMuted(!muted);
        }}
      >
        {muted ? "Sound On" : "Sound Off"}
      </button>

      <div className="relative rounded-3xl text-center lg:text-lg">
        <video
          src="/reel.mp4"
          loop
          autoPlay
          muted
          playsInline
          disablePictureInPicture
          disableRemotePlayback
          ref={reelVideoRef}
          className="mx-auto aspect-video w-full max-w-5xl overflow-hidden rounded-3xl object-cover md:aspect-video"
        ></video>
      </div>
    </div>
  );
}
