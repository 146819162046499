import { useSuspenseQuery } from "@tanstack/react-query";
import {
  createFileRoute,
  ErrorComponent,
  Link,
  redirect,
} from "@tanstack/react-router";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useState } from "react";

import { FounderService } from "@/api/services/founder.service";
import { UpdatesService } from "@/api/services/updates.service";
import { BottomNavigation } from "@/components/bottom-navigation";
import { CoundownTimer } from "@/components/countdown-timer";
import { helpDialog$ } from "@/components/dialogs/common/help";
import { IntroCallRequest } from "@/components/founder/intro-call-request";
import { KickOffCallRequest } from "@/components/founder/kick-off-call-request";
import { LinkButton } from "@/components/link-button";
import { SlideContainer } from "@/components/slide-container";
import { Spinner } from "@/components/spinner";
import { StatusCircle } from "@/components/status-circle";
import { Button } from "@/components/ui/button";
import { chatState$ } from "@/features/chat/chat.state";
import { useUpdateTime } from "@/hooks/use-update-times";
import { useWebsocket } from "@/hooks/use-websocket";
import { DAYS, IS_DEV } from "@/lib/constants";
import { queryClient } from "@/lib/query-client";
import { toast } from "@/lib/utils/toast";

export const Route = createFileRoute("/dashboard/founder/_founder/")({
  loader: async () => {
    const [founder, bookings] = await Promise.all([
      queryClient.ensureQueryData(FounderService.getCurrentFounder()),
      queryClient.ensureQueryData(FounderService.getCurrentFounderBookings()),
    ]);

    queryClient.ensureQueryData(UpdatesService.getCurrentUpdate(founder.id));
    const updates = await queryClient.ensureQueryData(
      UpdatesService.getUpdates(founder.id),
    );

    switch (founder.status) {
      case "agreementAccepted":
        throw redirect({
          to: "/dashboard/founder/company-form",
          replace: true,
        });
      case "invoiceSent":
      case "companyDetailsSubmitted":
        throw redirect({
          to: "/dashboard/founder/invoice",
          replace: true,
        });
    }

    return {
      founder,
      bookings,
      updates,
    };
  },

  gcTime: 0,
  onError: (error) => {
    if (error instanceof AxiosError) {
      if (error.response?.status === 401) {
        toast("You are not authorized to view this page.");
        throw redirect({ to: "/login", throw: true });
      }
    }
    return <ErrorComponent error={error} />;
  },

  pendingComponent: () => (
    <div className="grid h-[100dvh] w-[100dvw] place-items-center">
      <Spinner />
    </div>
  ),

  component: function Page() {
    const { founder: initialFounder, updates } = Route.useLoaderData();
    const navigate = Route.useNavigate();

    const [founder, setFounder] = useState(initialFounder);

    // Let's assume that that if the founder has not assigned a designer, the founder's timezone is used
    const assignedDesingerTimeZone =
      founder.assigned_designer?.time_zone || founder.time_zone;

    const { startDate, endDate, shouldEnableNextUpdateButton, nextUpdateText } =
      useUpdateTime(assignedDesingerTimeZone!, founder.time_zone!); // TODO: chnage timezone when assign feature is implemented

    const bookingsQuery = useSuspenseQuery(
      FounderService.getCurrentFounderBookings(),
    );
    const bookings = bookingsQuery.data;

    const currentUpdateQuery = useSuspenseQuery(
      UpdatesService.getCurrentUpdate(founder.id),
    );

    const currentUpdate = currentUpdateQuery.data;

    const introCallBooking = bookings.find((b) => b.type === "first_call");
    const kickoffBooking = bookings.find((b) => b.type === "kickoff_call");

    const hasCurrentUpdatedSent =
      currentUpdate.images &&
      currentUpdate.images.length > 0 &&
      currentUpdate.visible_to_client;

    useWebsocket(founder.id, "FOUNDER_STATUS_UPDATED", (newFounder) => {
      setFounder(newFounder);
      bookingsQuery.refetch();
      currentUpdateQuery.refetch();
    });

    const renderActionButton = () => {
      switch (founder.status) {
        case "rejected":
          return <StatusCircle percentage={0}>Rejected</StatusCircle>;
        case "onboarded":
          return (
            <>
              <p className="text-center">
                Thank you, {founder.name}. <br />
                We’ll send you an email within <br />
                24 hours to book a call.
              </p>

              <CoundownTimer
                startDate={dayjs(founder.updated_at)}
                finishDate={dayjs(founder.updated_at).add(24, "hour")}
                timeZone={founder.time_zone!}
              >
                Reviewing...
              </CoundownTimer>
            </>
          );

        case "introCallRequestSent":
          return (
            <IntroCallRequest
              userType="founder"
              email={founder.email}
              name={founder.name}
              onSuccess={() => navigate({ to: "." })}
            />
          );
        case "introCallBooked": {
          if (!introCallBooking) return;

          const firstCall = dayjs(introCallBooking.date);
          return (
            <>
              <p className="text-center">
                We’ll send you an email <br /> an hour before our call.
              </p>
              <CoundownTimer
                startDate={dayjs(introCallBooking.created_at)}
                finishDate={firstCall}
                timeZone={founder.time_zone!}
              >
                First sync on {DAYS[firstCall.day()]} <br />
                {firstCall.format("DD MMM HH:mm")}
              </CoundownTimer>
            </>
          );
        }
        case "introCallStarted":
          if (!introCallBooking) return;
          return (
            <>
              <p className="text-center">It’s time. See you there.</p>
              <Button size="lg" asChild>
                <a
                  href={`https://app.cal.com/video/${introCallBooking.video_call_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Join the call
                </a>
              </Button>
            </>
          );
        case "introCallEnded":
        case "proposalCreated":
          return (
            <Button disabled size="lg">
              Waiting for Options...
            </Button>
          );

        case "proposalSent":
          return (
            <>
              <p className="text-center">
                Your next step <br /> is here.
              </p>
              <Button size="lg" asChild>
                <Link to="/dashboard/founder/proposals">View Options</Link>
              </Button>
            </>
          );
        case "proposalConfirmed":
          return (
            <Button size="lg" asChild>
              <Link to="/dashboard/founder/agreement">Review agreement</Link>
            </Button>
          );

        case "paymentMade": {
          // TODO: this date might be wrong
          const updatedAt = dayjs(founder.updated_at);

          return (
            <>
              <p className="text-center">
                Thank you. <br /> Give us a day <br /> to confirm payment.
              </p>
              <CoundownTimer
                startDate={updatedAt}
                finishDate={updatedAt.add(1, "day")}
                timeZone={founder.time_zone!}
              >
                <p>Reviewing...</p>
              </CoundownTimer>
            </>
          );
        }

        case "kickoffCallRequestSent":
          return <KickOffCallRequest founder={founder} />;

        case "kickoffCallBooked": {
          if (!kickoffBooking) return;

          const kickoffCallDate = dayjs(kickoffBooking.date);

          return (
            <CoundownTimer
              startDate={dayjs(kickoffBooking.created_at)}
              finishDate={kickoffCallDate}
              timeZone={founder.time_zone!}
            >
              Kickoff call on {DAYS[kickoffCallDate.day()]} <br />
              {kickoffCallDate.format("DD MMM HH:mm")}
            </CoundownTimer>
          );
        }
        case "kickoffCallStarted":
          if (!kickoffBooking) return;
          return (
            <>
              <p className="text-center">It’s time. See you there.</p>
              <Button size="lg" asChild>
                <a
                  href={`https://app.cal.com/video/${kickoffBooking?.video_call_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Join the call
                </a>
              </Button>
            </>
          );
        case "projectStarted":
          if (!currentUpdate) return null;
          return (
            <>
              {shouldEnableNextUpdateButton ? (
                <LinkButton
                  disabled={!hasCurrentUpdatedSent}
                  size="lg"
                  className="text-center"
                  to="/dashboard/founder/updates/$updateId"
                  params={{ updateId: String(currentUpdate.id) }}
                >
                  {hasCurrentUpdatedSent ? "See update" : "Waiting for update."}
                </LinkButton>
              ) : (
                <CoundownTimer
                  startDate={startDate}
                  finishDate={endDate}
                  timeZone={founder.time_zone!}
                >
                  {nextUpdateText}
                </CoundownTimer>
              )}

              <LinkButton
                disabled={updates.length === 0}
                to="/dashboard/founder/updates"
              >
                Updates
              </LinkButton>
            </>
          );

        case "agreementAccepted":
        case "companyDetailsSubmitted":
        case "invoiceSent":
          return "Not implemented in this page";
      }
    };

    return (
      <div className="flex flex-col items-center">
        {/* TODO: accoding to desing we dont need FounderTag for founder dashboard */}
        {/*     <FoundersTag
          className="mb-10"
          services={founder.services}
          plan={founder.plan}
          stage={founder.stage}
        /> */}

        <SlideContainer
          className="flex flex-col items-center gap-10 md:gap-20"
          animationKey={founder.status}
        >
          {renderActionButton()}
        </SlideContainer>

        {IS_DEV && (
          <code className="fixed left-5 top-5 flex flex-col items-start text-xs opacity-50">
            <span>email: {founder.email}</span>
            <span>id: {founder.id}</span>
            <span>status: {founder.status}</span>
            <span>time_zone: {founder.time_zone}</span>
          </code>
        )}

        <BottomNavigation
          middle={
            founder.status === "projectStarted" && (
              <Button onClick={() => chatState$.dialogOpen.set(true)}>
                Chat
              </Button>
            )
          }
          right={
            <Button size={"sm"} onClick={() => helpDialog$.set(true)}>
              ?
            </Button>
          }
        />
      </div>
    );
  },
});
