import { createRouter } from "@tanstack/react-router";

import { queryClient } from "@/lib/query-client";

import { routeTree } from "./routeTree.gen";

// Set up a Router instance
export const appRouter = createRouter({
  routeTree,
  defaultPreload: "intent",
  context: {
    queryClient,
  },
});

// Register things for typesafety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof appRouter;
  }
}
