import { createFileRoute, Link } from "@tanstack/react-router";

import { BottomNavigation } from "@/components/bottom-navigation";
import { FadeScaleContainer } from "@/components/fade-scale-container";
import { GraphIcon } from "@/components/icons/graph-icon";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { LinkButton } from "@/components/link-button";
import { Button } from "@/components/ui/button";

export type Filter = "past" | "present" | "next";
export const Route = createFileRoute("/dashboard/admin/_admin/")({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      filter: search.filter as Filter | undefined,
    };
  },
  component: function Page() {
    const { filter } = Route.useSearch();

    const hasFilter = filter !== undefined;

    const renderPage = () => {
      // if there is no filter, render the next, past, present buttons
      if (!hasFilter) {
        return (
          <div className="flex flex-col items-center gap-2 pt-10">
            <div className="flex items-center gap-2">
              <FadeScaleContainer duration={0.2} delay={0.1}>
                <Button disabled>Overview</Button>
              </FadeScaleContainer>
              <FadeScaleContainer duration={0.3} delay={0.2}>
                <Button disabled>Sync</Button>
              </FadeScaleContainer>
            </div>
            <div className="flex items-center gap-4">
              <FadeScaleContainer duration={0.2} delay={0.1}>
                <Button asChild>
                  <Link from={Route.fullPath} search={{ filter: "past" }}>
                    Past
                  </Link>
                </Button>
              </FadeScaleContainer>
              <FadeScaleContainer duration={0.3} delay={0.2}>
                <Button asChild>
                  <Link from={Route.fullPath} search={{ filter: "present" }}>
                    Present
                  </Link>
                </Button>
              </FadeScaleContainer>
              <FadeScaleContainer duration={0.3} delay={0.3}>
                <Button asChild>
                  <Link from={Route.fullPath} search={{ filter: "next" }}>
                    Next
                  </Link>
                </Button>
              </FadeScaleContainer>
            </div>
          </div>
        );
      }

      return (
        <div className="flex flex-row gap-2 pt-10">
          <FadeScaleContainer duration={0.2} delay={0.1}>
            <Button asChild>
              <Link
                from={Route.fullPath}
                to={"/dashboard/admin/founders"}
                search={{ filter: filter }}
              >
                Founders
              </Link>
            </Button>
          </FadeScaleContainer>
          <FadeScaleContainer duration={0.2} delay={0.2}>
            <Button asChild>
              <Link
                from={Route.fullPath}
                to={"/dashboard/admin/creatives"}
                search={{ filter: filter }}
              >
                Creatives
              </Link>
            </Button>
          </FadeScaleContainer>
        </div>
      );
    };

    return (
      <section className="flex flex-col justify-center">
        <FadeScaleContainer className="mx-auto">
          <img
            className="rounded-full border border-[#00000014]"
            src={"/blank-profile.jpeg"}
            alt="Blank avatar"
            width={40}
            height={40}
          />
        </FadeScaleContainer>

        <div className="mx-auto">
          {filter && <h1 className="mt-20 text-center capitalize">{filter}</h1>}
          {renderPage()}
        </div>

        <BottomNavigation
          left={
            filter && (
              <Button size={"sm"} asChild>
                <Link href={`/dashboard/admin`}>
                  <IconBackArrow />
                </Link>
              </Button>
            )
          }
          middle={!filter && <LinkButton disabled>Chat</LinkButton>}
          right={
            !filter && (
              <LinkButton size={"sm"} to="/dashboard/admin/site-reports">
                <GraphIcon />
              </LinkButton>
            )
          }
        />
      </section>
    );
  },
});
