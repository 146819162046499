import { createFileRoute, Link } from '@tanstack/react-router'
import { motion } from 'framer-motion'

import { BackButton } from '@/components/back-button'
import { BottomNavigation } from '@/components/bottom-navigation'
import { FadeSection } from '@/components/fade-section'
import { OPLogoLarge } from '@/components/op-logo-large'
import { Button } from '@/components/ui/button'

export const Route = createFileRoute('/_misc/about_/mission')({
  component: function () {
    return (
      <main className="container size-full w-full md:w-[50%]">
        <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
          <Link to="/">
            <OPLogoLarge />
          </Link>
        </header>

        <motion.section className="pb-[140px] pt-[144px] text-center">
          <FadeSection>
            <Button asChild>
              <Link to="/about">Mission</Link>
            </Button>

            <p className="mt-20">
              Some fear change. <br className="hidden md:block" />
              Some resist it. Others try to adapt.{' '}
              <br className="hidden md:block" /> OpenPurpose® exists to be the
              change.
            </p>

            <p className="mt-20">
              Over the past 1,5 decade, <br className="hidden md:block" />
              OpenPurpose® has taken many forms.
            </p>

            <p className="mt-10">
              Product studio. <br />
              Full-service agency. <br />
              Digital partner for governments.
              <br />
              Venture partner for enterprises.
            </p>

            <p className="mt-10">
              We have started fresh more times than we can count,
              <br className="hidden md:block" /> but each time we’ve moved
              closer toward <br className="hidden md:block" /> where we needed
              to be.
            </p>

            <p className="mt-10">
              We’ve tried every model. Sometimes even twice. Only to{' '}
              <br className="hidden md:block" />
              confirm they are all broken. The offerings are expensive,
              <br className="hidden md:block" /> the process is fragmented and
              the progress is too slow.
            </p>

            <p className="mt-10">
              We believe today’s agencies, venture capital firms and
              <br className="hidden md:block" /> even most countries are not
              suitable for tomorrow’s
              <br className="hidden md:block" /> Founders. With today’s
              technological progress, Founders
              <br className="hidden md:block" /> can accomplish more with less
              and free of their countries
              <br className="hidden md:block" /> limitations.
            </p>

            <p className="mt-10">
              We see a different future. <br />
              We call it OpenPurpose®.
            </p>

            <p className="mt-10">
              The world’s first Creative Venture Platform. A place where{' '}
              <br className="hidden md:block" />
              Founders, Investors and Creatives meet to launch
              <br className="hidden md:block" /> tomorrow’s companies. Enabled
              by one of the most
              <br className="hidden md:block" /> suitable governments for
              entrepreneurship.
            </p>

            <p className="mt-10">
              Our mission is to unify the creative industry and
              <br className="hidden md:block" /> revolutionise the creative
              economy. To give power and
              <br className="hidden md:block" /> freedom to today’s creatives to
              create a more open
              <br className="hidden md:block" /> tomorrow.
            </p>
          </FadeSection>
        </motion.section>

        <BottomNavigation className="bg-transparent" left={<BackButton />} />
      </main>
    )
  },
})
