import { createFileRoute, Link } from "@tanstack/react-router";

import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { Button } from "@/components/ui/button";

export const Route = createFileRoute(
  "/dashboard/founder/updates/$updateId/rate",
)({
  component: function Page() {
    const { updateId } = Route.useParams();
    return (
      <>
        <div className="flex flex-col items-center gap-20">
          <h1>How does this update feel?</h1>

          <div className="flex items-center gap-4">
            <Button asChild>
              <Link
                to={`/dashboard/founder/updates/$updateId/feedback`}
                params={{ updateId }}
                search={{ rating: "1" }}
              >
                <div className="flex flex-col items-center gap-4">
                  <div className="size-2 rounded-full bg-green"></div>
                  <div>On-track</div>
                </div>
              </Link>
            </Button>
            <Button asChild>
              <Link
                to={`/dashboard/founder/updates/$updateId/feedback`}
                params={{ updateId }}
                search={{ rating: "0" }}
              >
                <div className="flex flex-col items-center gap-4">
                  <div className="size-2 rounded-full bg-yellow"></div>
                  <div>Adjust</div>
                </div>
              </Link>
            </Button>
            <Button asChild>
              <Link
                to={`/dashboard/founder/updates/$updateId/feedback`}
                params={{ updateId }}
                search={{ rating: "-1" }}
              >
                <div className="flex flex-col items-center gap-4">
                  <div className="size-2 rounded-full bg-red"></div>
                  <div>Off-track</div>
                </div>
              </Link>
            </Button>
          </div>
        </div>

        <BottomNavigation left={<BackButton />} />
      </>
    );
  },
});
