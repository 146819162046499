import { cn } from "@/lib/utils";

import { FadeContainer } from "./fade-container";

interface BottomNavigationProps {
  left?: React.ReactNode;
  middle?: React.ReactNode;
  right?: React.ReactNode;
  className?: string;
}

export const BottomNavigation: React.FC<BottomNavigationProps> = ({
  left,
  middle,
  right,
  className,
}) => {
  return (
    <div
      className={cn(
        "fixed bottom-5 left-1/2 flex -translate-x-1/2 items-center bg-transparent",
        {
          "gap-2": !middle && left && right,
        },
        className,
      )}
    >
      {left && (
        <FadeContainer>
          <div
            className={cn("", {
              "absolute -left-16 top-1/2 -translate-y-1/2": middle,
            })}
          >
            {left}
          </div>
        </FadeContainer>
      )}
      {middle && <FadeContainer>{middle}</FadeContainer>}
      {right && (
        <FadeContainer>
          <div
            className={cn("", {
              "absolute -right-16 top-1/2 -translate-y-1/2": middle,
            })}
          >
            {right}
          </div>
        </FadeContainer>
      )}
    </div>
  );
};
