import dayjs from "dayjs";

import { Founder, ServiceType } from "./global-types";

export const HOST = import.meta.env.VITE_HOST;
export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
export const CALCOM_URL = import.meta.env.VITE_CALCOM_URL;
export const IS_DEV = import.meta.env.DEV;

export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const SERVICE_SIZE_VALUES = {
  notSure: 0,
  s: 1,
  m: 2,
  l: 3,
};

export type PackageDetail = {
  description: string;
  duration: string;
  preSeedPrice: number;
  seedPrice: number;
  seriesAPlusPrice: number;
};

export type Package = {
  name: ServiceType;
  details: {
    small: PackageDetail;
    medium: PackageDetail;
    large: PackageDetail;
  };
};

// This will be used all over the app to calculate the current date and time
// If you want to test the app with a different date, you can change this value
export const SYSTEM_TODAY = dayjs();

export const PACKAGES: Package[] = [
  {
    name: "brand",
    details: {
      small: {
        description: "Identity design \n with the essentials.",
        duration: "1 month",
        preSeedPrice: 20_000,
        seedPrice: 20_000,
        seriesAPlusPrice: 20_000,
      },
      medium: {
        description: "Includes illustrations \n and imagery.",
        duration: "2 months",
        preSeedPrice: 40_000,
        seedPrice: 40_000,
        seriesAPlusPrice: 40_000,
      },
      large: {
        description: "Includes advanced 3D \n and motion.",
        duration: "3 months",
        preSeedPrice: 60_000,
        seedPrice: 60_000,
        seriesAPlusPrice: 60_000,
      },
    },
  },
  {
    name: "website",
    details: {
      small: {
        description: "1-3 pages \n includes development.",
        duration: "1 month",
        preSeedPrice: 20_000,
        seedPrice: 20_000,
        seriesAPlusPrice: 20_000,
      },
      medium: {
        description: "4-5 pages \n includes development.",
        duration: "2 months",
        preSeedPrice: 40_000,
        seedPrice: 40_000,
        seriesAPlusPrice: 40_000,
      },
      large: {
        description: "5+ pages \n includes development.",
        duration: "3 months",
        preSeedPrice: 60_000,
        seedPrice: 60_000,
        seriesAPlusPrice: 60_000,
      },
    },
  },
  {
    name: "product",
    details: {
      small: {
        description: "Single key flow \n on one platform.",
        duration: "1 month",
        preSeedPrice: 20_000,
        seedPrice: 20_000,
        seriesAPlusPrice: 20_000,
      },
      medium: {
        description: "Multiple key flows \n on one platform.",
        duration: "2 months",
        preSeedPrice: 40_000,
        seedPrice: 40_000,
        seriesAPlusPrice: 40_000,
      },
      large: {
        description: "Multiple account types \n and platforms.",
        duration: "3 months",
        preSeedPrice: 60_000,
        seedPrice: 60_000,
        seriesAPlusPrice: 60_000,
      },
    },
  },
  // {
  //   name: "shop",
  //   details: {
  //     small: {
  //       description: "Store. \n Handful of products.",
  //       duration: "1 month",
  //       preSeedPrice: 20_000,
  //       seedPrice: 20_000,
  //       seriesAPlusPrice: 20_000,
  //     },
  //     medium: {
  //       description: "Market. \n Dozens of produtcs.",
  //       duration: "2 months",
  //       preSeedPrice: 40_000,
  //       seedPrice: 40_000,
  //       seriesAPlusPrice: 40_000,
  //     },
  //     large: {
  //       description: "Mall. \n Large catalog of products.",
  //       duration: "3 months",
  //       preSeedPrice: 60_000,
  //       seedPrice: 60_000,
  //       seriesAPlusPrice: 60_000,
  //     },
  //   },
  // },
  // {
  //   name: "campaign",
  //   details: {
  //     small: {
  //       description: "Creative story \n visualised on a page.",
  //       duration: "1 month",
  //       preSeedPrice: 20_000,
  //       seedPrice: 20_000,
  //       seriesAPlusPrice: 20_000,
  //     },
  //     medium: {
  //       description: "Creative story visualised \n in an illustrated video.",
  //       duration: "2 months",
  //       preSeedPrice: 40_000,
  //       seedPrice: 40_000,
  //       seriesAPlusPrice: 40_000,
  //     },
  //     large: {
  //       description: "Creative story visualised \n in real life video or 3D.",
  //       duration: "3 months",
  //       preSeedPrice: 60_000,
  //       seedPrice: 60_000,
  //       seriesAPlusPrice: 60_000,
  //     },
  //   },
  // },
];

export const founderGrayStatuses: Founder["status"][] = ["onboarded"];

export const founderHalfYellowStatuses: Founder["status"][] = [
  "introCallRequestSent",
  "introCallBooked",
  "introCallStarted",
  "introCallEnded",
  "proposalSent",
  "proposalConfirmed",
  "proposalCreated",
];

export const founderYellowStatuses: Founder["status"][] = [
  "companyDetailsSubmitted",
  "invoiceSent",
  "paymentMade",
  "agreementAccepted",
  "kickoffCallBooked",
  "kickoffCallRequestSent",
  "kickoffCallStarted",
];

export const founderGreenStatuses: Founder["status"][] = ["projectStarted"];

export const founderRedStatuses: Founder["status"][] = ["rejected"];
