import { createFileRoute } from "@tanstack/react-router";

import { BottomNavigation } from "@/components/bottom-navigation";
import { LinkButton } from "@/components/link-button";

export const Route = createFileRoute(
  "/dashboard/founder/updates/_updates/thanks",
)({
  component: () => (
    <>
      <div className="flex flex-col items-center gap-20">
        <h1>
          Thank you. <br />
          We are on it!
        </h1>
      </div>

      <BottomNavigation
        middle={<LinkButton to={`/dashboard/founder`}>Close</LinkButton>}
      />
    </>
  ),
});
