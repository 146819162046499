/* eslint-disable react/no-unknown-property */
"use client";

import { PerformanceMonitor } from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  BrightnessContrast,
  EffectComposer,
  Select,
} from "@react-three/postprocessing";
import { motion } from "framer-motion";
import { ComponentProps, useEffect, useRef, useState } from "react";
import * as THREE from "three";

import { cn } from "@/lib/utils";

export default function StartButton({
  className,
  ...rest
}: ComponentProps<"div">) {
  const [dpr, setDpr] = useState(1.5);
  const [webGLAvailable, setWebGLAvailable] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const isWebGLAvailable = () => {
    try {
      const canvas = document.createElement("canvas");
      return !!(
        window.WebGLRenderingContext &&
        (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
      );
    } catch (_e) {
      return false;
    }
  };

  useEffect(() => {
    setWebGLAvailable(isWebGLAvailable());
  }, []);

  return (
    <div
      className={cn(
        "flex size-56 items-center justify-center overflow-hidden rounded-full border border-primary bg-black transition-all duration-150 hover:scale-105 md:bottom-10",
        className,
      )}
      {...rest}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        className="size-56"
        ref={containerRef}
      >
        {webGLAvailable ? (
          <Canvas
            camera={{ position: [0, 0, 4], fov: 65, zoom: 1.09 }}
            dpr={dpr}
            gl={{ antialias: true }}
            className="pointer-events-none size-full"
          >
            <PerformanceMonitor
              onIncline={() => setDpr(2)}
              onDecline={() => setDpr(1)}
            ></PerformanceMonitor>
            <EffectComposer multisampling={8} autoClear={false}>
              <BrightnessContrast brightness={0} contrast={0} />
            </EffectComposer>
            <Sphere />
            <ambientLight intensity={1.5} />
          </Canvas>
        ) : (
          <span className="text-lg text-white">Start</span>
        )}
      </motion.div>
    </div>
  );
}

function Sphere() {
  const radius = 2;

  const sphereRef = useRef<THREE.Mesh>(null);
  const containerRef = useRef<THREE.Mesh>(null);

  useFrame((_state, delta) => {
    if (sphereRef.current === null || containerRef.current === null) return;
    sphereRef.current.rotation.y -= 1 * delta;
  });

  useEffect(() => {
    const size = 1024 * 2;
    const canvas = document.createElement("canvas");
    canvas.width = size * 2;
    canvas.height = size;
    const context = canvas.getContext("2d");
    if (!context) return;

    context.fillStyle = "#000000";
    context.fillRect(0, 0, canvas.width, canvas.height);

    const font = getComputedStyle(document.body)
      .getPropertyValue("--font-suisse")
      .trim();
    context.font = `128px ${font}`;
    context.fillStyle = "#FFFFFF";
    const text = "Start";

    const textWidth = context.measureText(text).width;
    let x = (canvas.width / 4 - textWidth) / 2;
    const y = canvas.height / 2;

    context.fillText(text, x, y);

    x = x + canvas.width / 4;
    context.fillText(text, x, y);

    x = x + canvas.width / 4;
    context.fillText(text, x, y);

    x = x + canvas.width / 4;
    context.fillText(text, x, y);

    const texture = new THREE.CanvasTexture(canvas);
    if (sphereRef.current) {
      const material = sphereRef.current.material as THREE.MeshBasicMaterial;
      material.map = texture;
      material.needsUpdate = true;
    }
  }, []);

  return (
    <mesh rotation={[0, 0, 0.2]} ref={containerRef}>
      <Select enabled={true}>
        <mesh ref={sphereRef} position={[0, 0, 0]} rotation={[0, 1.2, 0]}>
          <sphereGeometry args={[radius, 32 * 6, 32 * 6]} />
          <meshBasicMaterial color={"white"} />
        </mesh>
      </Select>
    </mesh>
  );
}
