import { createFileRoute, Link } from "@tanstack/react-router";

import { DesignerService } from "@/api/services/designer.service";
import { UpdatesService } from "@/api/services/updates.service";
import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { CopyEmailButton } from "@/components/copy-email-button";
import { CoundownTimer } from "@/components/countdown-timer";
import { FoundersTag } from "@/components/founders-tag";
import { Button } from "@/components/ui/button";
import { useUpdateTime } from "@/hooks/use-update-times";

export const Route = createFileRoute(
  "/dashboard/designer/projects/$projectId/_$projectId/",
)({
  loader: async ({ params, context: { queryClient } }) => {
    const [project, designer, currentUpdate, updates] = await Promise.all([
      queryClient.ensureQueryData(DesignerService.getProject(params.projectId)),
      queryClient.ensureQueryData(DesignerService.getCurrentDesigner()),
      queryClient.ensureQueryData(
        UpdatesService.getCurrentUpdate(params.projectId),
      ),
      queryClient.ensureQueryData(UpdatesService.getUpdates(params.projectId)),
    ]);

    return { project, currentUpdate, designer, updates };
  },
  component: function Page() {
    const { project, currentUpdate, designer, updates } = Route.useLoaderData();
    const { projectId } = Route.useParams();

    const { shouldEnableNextUpdateButton, startDate, endDate, nextUpdateText } =
      useUpdateTime(designer.time_zone!, designer.time_zone!);

    return (
      <>
        <div className="flex flex-col items-center gap-10">
          <FoundersTag
            stage={project.stage}
            services={project.services}
            plan={project.plan}
          />
          <div className="flex flex-col items-center gap-2">
            <CopyEmailButton email={project.email} text={project.name} />
            <p>{project.company_name}</p>
          </div>

          <div className="mt-10 flex flex-col items-center gap-10">
            {shouldEnableNextUpdateButton ? (
              <Button size="lg" asChild className="text-center">
                <Link
                  to={`/dashboard/designer/projects/$projectId/updates/$updateId`}
                  params={{ projectId, updateId: String(currentUpdate.id) }}
                >
                  It&apos;s time to update
                </Link>
              </Button>
            ) : (
              <CoundownTimer
                startDate={startDate}
                finishDate={endDate}
                timeZone={designer.time_zone!}
              >
                {nextUpdateText}
              </CoundownTimer>
            )}

            <Button asChild disabled={updates.length === 0}>
              <Link
                to={`/dashboard/designer/projects/$projectId/updates`}
                params={{ projectId }}
              >
                Updates
              </Link>
            </Button>
          </div>
        </div>
        <BottomNavigation left={<BackButton to="/dashboard/designer" />} />
      </>
    );
  },
});
