import { Dayjs } from "dayjs";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";

import { SYSTEM_TODAY } from "@/lib/constants";
import { unFormatTimezone } from "@/lib/utils";

import { StatusCircle } from "./status-circle";

type CoundownTimerProps = PropsWithChildren<{
  startDate: Dayjs;
  finishDate: Dayjs;
  timeZone: string;
}>;
export const CoundownTimer = ({
  startDate,
  finishDate,
  timeZone,
  children,
}: CoundownTimerProps) => {
  const totalSeconds = finishDate.diff(startDate, "second");

  const tick = useCallback(() => {
    const secondsPassed = SYSTEM_TODAY.tz(unFormatTimezone(timeZone)).diff(
      startDate,
      "second",
    );

    const progress = (secondsPassed / totalSeconds) * 100;

    return progress;
  }, [startDate, timeZone, totalSeconds]);

  const [percentage, setPercentage] = useState(() => tick());

  useEffect(() => {
    const interval = setInterval(() => {
      const progress = tick();
      setPercentage(progress);
      if (progress >= 100) {
        clearInterval(interval);
        return;
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [startDate, tick, totalSeconds]);

  return <StatusCircle percentage={percentage}>{children}</StatusCircle>;
};
