import { createFileRoute, redirect } from "@tanstack/react-router";
import { useState } from "react";

import { useRateUpdate } from "@/api/services/updates.service";
import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";

export const Route = createFileRoute(
  "/dashboard/founder/updates/$updateId/feedback",
)({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      rating: search.rating as string,
    };
  },
  beforeLoad: async ({ params, search }) => {
    if (!search.rating) {
      return redirect({
        to: "/dashboard/founder/updates/$updateId/rate",
        params,
      });
    }
  },
  component: function Page() {
    const navigate = Route.useNavigate();
    const { updateId } = Route.useParams();
    const { rating } = Route.useSearch();
    const [feedback, setFeedback] = useState("");

    const rateUpdateMutation = useRateUpdate();

    return (
      <>
        <div className="flex flex-col items-center gap-10">
          <h2>
            Write your feedback below. <br />
            Big picture first then details.
          </h2>
          <Textarea
            placeholder="Feedback.."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </div>

        <BottomNavigation
          left={
            <BackButton
              disabled={rateUpdateMutation.isPending}
              size="sm"
              to={`/dashboard/founder/updates/$updateId/rate`}
              params={{ updateId }}
            />
          }
          middle={
            <Button
              loading={rateUpdateMutation.isPending}
              onClick={async () => {
                await rateUpdateMutation.mutateAsync({
                  updateId: parseInt(updateId),
                  rating: parseInt(rating),
                  feedback,
                });

                navigate({
                  to: `/dashboard/founder/updates/thanks`,
                });
              }}
            >
              Send
            </Button>
          }
        />
      </>
    );
  },
});
