import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useRef,
  useState,
} from "react";

import { FadeContainer } from "@/components/fade-container";
import { IconClose } from "@/components/icons/icon-close";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { urlSchema } from "@/lib/utils";
import { toast } from "@/lib/utils/toast";

import { Button } from "./ui/button";

interface Props {
  className?: string;
  currentLink: string;
  setCurrentLink: (link: string) => void;
  linkList: string[];
  setLinkList: (linkList: string[]) => void;
}

export const Links = ({
  className,
  currentLink,
  setCurrentLink,
  linkList,
  setLinkList,
}: Props) => {
  const [showAddLink, setShowAddLink] = useState(false);

  const [delay, setDelay] = useState(0);
  const isEmptyRef = useRef(false);

  const handleLinkRemoveClick = (link: string) => {
    const newLinkList = linkList.filter((pressedLink) => link !== pressedLink);

    setDelay(0.2);
    setTimeout(() => setLinkList(newLinkList), 0);
    setTimeout(() => setDelay(0), 400);
  };

  const handleLinkInputOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.currentTarget.value.replace("https://https://", "https://");
    if (value === "") isEmptyRef.current = true;
    if (isEmptyRef.current) {
      setCurrentLink("https://" + value);
      isEmptyRef.current = false;
      return;
    }
    setCurrentLink(value);
  };

  const handleLinkInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (
    e,
  ) => {
    if (e.shiftKey) return;

    if (currentLink && e.key === "Enter") {
      const linkAlreadyExist = linkList.some(
        (checkingLink) => checkingLink === currentLink,
      );
      if (linkAlreadyExist) {
        toast("Link already exists");
        return;
      }

      try {
        const url = urlSchema.parse(currentLink);
        const newLinkList = [...linkList, url];
        setLinkList(newLinkList);
        setCurrentLink("https://");
        setShowAddLink(false);
      } catch (error) {
        if (error instanceof Error) {
          toast(JSON.parse(error.message)[0].message);
        }
      }
    }
  };

  return (
    <div className={cn("flex flex-col items-center gap-2", className)}>
      {linkList.map((link) => (
        <FadeContainer key={link}>
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="inline-flex min-h-10 max-w-[334px] items-center justify-center gap-1 overflow-hidden rounded-full border border-primary pl-4 focus-visible:bg-primary focus-visible:text-white focus-visible:outline-none"
          >
            <span className="truncate">{link}</span>
            <button
              className="min-h-10 rounded-full px-3 ring-inset focus-visible:bg-primary focus-visible:text-white focus-visible:outline-none"
              onClick={(e) => {
                e.preventDefault();
                handleLinkRemoveClick(link);
              }}
            >
              <IconClose />
            </button>
          </a>
        </FadeContainer>
      ))}
      {linkList.length < 5 &&
        (showAddLink ? (
          <FadeContainer delay={delay}>
            <Input
              autoFocus
              type={"url"}
              pattern="https://.*"
              required
              onChange={handleLinkInputOnChange}
              onKeyDown={handleLinkInputKeyDown}
              placeholder={"Add a link"}
              value={currentLink}
              size={1}
            />
          </FadeContainer>
        ) : (
          <FadeContainer delay={delay}>
            <Button
              className="size-auto min-h-10 rounded-full border-dashed px-4"
              onClick={() => setShowAddLink(true)}
            >
              Add link
            </Button>
          </FadeContainer>
        ))}
    </div>
  );
};

Links.displayName = "Links";
