import { createFileRoute, Link } from "@tanstack/react-router";
import { useState } from "react";

import { BottomNavigation } from "@/components/bottom-navigation";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { Links } from "@/components/links";
import { Button } from "@/components/ui/button";
import { founderOnboardingState$ } from "@/store/founder.state";

export const Route = createFileRoute("/onboarding/founder/links")({
  component: function Page() {
    const links = founderOnboardingState$.links.get();
    const [currentLink, setCurrentLink] = useState("");

    const navigate = Route.useNavigate();

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();

      navigate({
        to: "/onboarding/founder/mail-preference",
      });
    };

    return (
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center text-center"
      >
        <h1>
          {"Any links you can share with us?"} <br />
          {"Website, social or maybe a figma file?"}
        </h1>

        <Links
          className="mt-20"
          currentLink={currentLink}
          setCurrentLink={setCurrentLink}
          linkList={links}
          setLinkList={(val) => {
            founderOnboardingState$.links.set(val as string[]);
          }}
        />

        <BottomNavigation
          left={
            <Button size="sm" asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={<Button type="submit">Next</Button>}
        />
      </form>
    );
  },
});
