import { createFileRoute, redirect } from "@tanstack/react-router";
import umami from "@umami/node";

import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { KickoffDate } from "@/lib/global-types";
import { useUmamiPageEvent } from "@/lib/umami";
import {
  founderNewState$,
  isFounderNewCompleted$,
} from "@/store/founder.state";

export const Route = createFileRoute("/new/founder/time")({
  beforeLoad: () => {
    if (!isFounderNewCompleted$.peek()) {
      return redirect({ to: "/new/founder" });
    }
  },
  component: function Page() {
    const kickOffDate = founderNewState$.kickoff_date.get();
    const navigate = Route.useNavigate();
    useUmamiPageEvent("Founder - When would you like to begin?");

    return (
      <div>
        <section className="flex flex-col items-center justify-center text-center">
          <h1 className="mt-10">When would you like to begin?</h1>

          <ToggleGroup
            className="mt-10 md:mt-20"
            type="single"
            defaultValue={kickOffDate!}
            onValueChange={(value) => {
              if (value) {
                founderNewState$.kickoff_date.set(value as KickoffDate);
                umami.track("Founder Answer - When would you like to begin?", {
                  KickoffDate: value,
                });
                navigate({ to: "/new/founder/email" });
              }
            }}
          >
            <ToggleGroupItem value="nextWeek">Next Week</ToggleGroupItem>
            <ToggleGroupItem value="nextMonth">Next Month</ToggleGroupItem>
            <ToggleGroupItem value="later">Later</ToggleGroupItem>
          </ToggleGroup>
        </section>

        <BottomNavigation left={<BackButton native />} />
      </div>
    );
  },
});
