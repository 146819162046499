import * as React from "react";
// @ts-expect-error - no types available
import AutosizeInput from "react-18-input-autosize";

import { cn } from "@/lib/utils";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export const Input = ({ className, type, ...props }: InputProps) => {
  const [_, render] = React.useState({});

  React.useEffect(() => {
    render({});
  }, []);

  return (
    <AutosizeInput
      type={type}
      className={cn(
        "placeholder:*:text-secondary focus:*:outline-none",
        className,
      )}
      inputStyle={{
        padding: "8px 16px",
        height: "22px",
        borderRadius: "300px",
        borderWidth: "1px",
        borderColor: "#000",
        lineHeight: "24px",
        maxWidth: "300px",
        textAlign: "center",

        ...props.style,
      }}
      {...props}
    />
  );
};
