"use client";

import { HTMLMotionProps, motion } from "framer-motion";
import { ComponentProps } from "react";

import { cn } from "@/lib/utils";

type FeatueContainerProps = {} & HTMLMotionProps<"div">;
export const FeatueContainer = ({
  className,
  children,
  ...rest
}: FeatueContainerProps) => {
  return (
    <motion.div
      transition={{ duration: 0.2 }}
      className={cn(
        `relative flex border-b-2 border-black after:absolute after:bottom-[-1px] after:right-0 after:h-2 after:w-[2px] after:bg-primary group-hover:border-white after:group-hover:bg-white`,
        className,
      )}
      {...rest}
    >
      {children}
    </motion.div>
  );
};

type FeatureGroupProps = {} & HTMLMotionProps<"div">;
export const FeatureGroup = ({
  className,
  children,
  ...rest
}: FeatureGroupProps) => {
  return (
    <motion.div
      suppressHydrationWarning
      className={cn(
        `relative flex items-center justify-center px-2 before:absolute before:bottom-[-1px] before:left-0 before:h-2 before:w-[2px] before:bg-primary last:after:absolute last:after:bottom-0 last:after:right-0 last:after:hidden last:after:h-2 last:after:w-[2px] last:after:bg-primary before:group-hover:bg-white first:before:group-hover:bg-white last:after:group-hover:bg-white`,
        "feature-group",
        className,
      )}
      {...rest}
    >
      {children}
    </motion.div>
  );
};

type FeatureDotProps = ComponentProps<typeof motion.div> & {
  animation?: boolean;
};
export const FeatureDot = ({
  className,
  animation = false,
  ...rest
}: FeatureDotProps) => {
  return (
    <motion.div
      initial={animation ? { opacity: 0, y: 10 } : undefined}
      animate={animation ? { opacity: 1, y: 0 } : undefined}
      exit={animation ? { opacity: 0, y: 10 } : undefined}
      className={cn(
        "h-3 w-3 rounded-full bg-primary group-hover:bg-white",
        className,
      )}
      {...rest}
    ></motion.div>
  );
};
