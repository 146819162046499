// import { createFileRoute, Link, redirect } from "@tanstack/react-router";
import { createFileRoute, redirect } from '@tanstack/react-router'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'

import { get } from '@/api/client'
import { BackButton } from '@/components/back-button'
import { BottomNavigation } from '@/components/bottom-navigation'
import { FeatureDot } from '@/components/feature-groups'
import { SlideContainer } from '@/components/slide-container'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel'
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'
import { useIsMobile } from '@/hooks/use-is-mobile'
import { Service, ServiceSize, ServiceType } from '@/lib/global-types'
import { priceFormatter, sizeToMonth, sizeToMountCount } from '@/lib/utils'
import { founderNewState$, isFounderNewCompleted$ } from '@/store/founder.state'
import { direction$ } from '@/store/page.state'

const DESCRIPTIONS: Record<
  ServiceType,
  {
    header: React.ReactNode
    durations: React.ReactNode[]
  }
> = {
  brand: {
    header: (
      <p>
        Which brand package are you interested in? <br />
        All packages include Brand Design System.
      </p>
    ),
    durations: [
      <p key={0}>
        New identity with <br />
        essential assets.
      </p>,
      <p key={1}>
        + Illustrations <br />
        and imagery.
      </p>,
      <p key={3}>
        + Advanced 3D <br />
        and motion.
      </p>,
    ],
  },

  website: {
    header: (
      <p>
        Which website package fit your needs best? <br />
        All packages include development.
      </p>
    ),
    durations: [
      <p key={0}>1-2 pages</p>,
      <p key={1}>3-5 pages</p>,
      <p key={3}>6+ pages</p>,
    ],
  },
  product: {
    header: <p>Which package describe your product best?</p>,
    durations: [
      <p key={0}>Single Purpose.</p>,
      <p key={1}>Multi Purpose.</p>,
      <p key={3}>Multi Platform</p>,
    ],
  },
}

const PackageDurationSelector = ({
  services,
  type,
  onSelectDuration,
  isDetailsOpen,
  setIsDetailsOpen,
}: {
  services: Service[]
  type: ServiceType
  onSelectDuration: (size: ServiceSize) => void
  isDetailsOpen?: boolean
  setIsDetailsOpen: (open: boolean) => void
}) => {
  const { isMobile } = useIsMobile()
  const founder = founderNewState$.get()

  const selectedSericeGroup = services.filter(
    (service) => service.type === type,
  )

  const smallService = selectedSericeGroup.find(
    (service) => service.size === 's',
  )!

  const mediumService = selectedSericeGroup.find(
    (service) => service.size === 'm',
  )!

  const largeService = selectedSericeGroup.find(
    (service) => service.size === 'l',
  )!

  return (
    <div>
      <SlideContainer direction="forwards">
        <section className="flex flex-col items-center justify-center text-center">
          <h1 className="mt-10">{DESCRIPTIONS[type]?.header}</h1>

          <button
            className="mt-2 underline"
            onClick={() => setIsDetailsOpen(!isDetailsOpen)}
          >
            {isDetailsOpen ? 'Hide' : 'View'} details
          </button>

          <ToggleGroup
            className="mt-10 w-screen md:mt-20 md:w-full"
            type="single"
            defaultValue={founder.stage || undefined}
            onValueChange={(value) => {
              onSelectDuration(value as ServiceSize)
            }}
          >
            <Carousel
              opts={{
                containScroll: isMobile ? false : 'keepSnaps',
                active: isMobile,
              }}
              className="w-full"
            >
              <CarouselContent data-cy="durations">
                <CarouselItem className="flex basis-[80%] items-center justify-center md:basis-1/3">
                  <ToggleGroupItem
                    className="group flex size-80 flex-col items-center"
                    value="s"
                  >
                    <motion.span
                      layout
                      layoutDependency={isDetailsOpen}
                      className="mb-10 flex flex-row items-center gap-[6px]"
                    >
                      {Array.from({
                        length: sizeToMountCount(smallService.size),
                      }).map((_, index) => (
                        <FeatureDot
                          className="group group-hover:bg-white"
                          key={index}
                        />
                      ))}
                    </motion.span>

                    {isDetailsOpen && (
                      <motion.span
                        layout
                        layoutDependency={isDetailsOpen}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="mb-10 text-wrap"
                      >
                        {/* {smallService?.description} */}
                        {DESCRIPTIONS[type]?.durations[0]}
                      </motion.span>
                    )}

                    <motion.div layout layoutDependency={isDetailsOpen}>
                      <p>{sizeToMonth(smallService.size)}</p>
                      {/* @ts-expect-error - TODO: Fix this */}
                      <p>{priceFormatter.format(smallService.seed_price)}</p>
                      <p>{smallService?.price}</p>
                    </motion.div>
                  </ToggleGroupItem>
                </CarouselItem>

                <CarouselItem className="flex basis-[80%] items-center justify-center md:basis-1/3">
                  <ToggleGroupItem
                    className="group flex size-80 flex-col items-center"
                    value="m"
                  >
                    <motion.span
                      layout
                      layoutDependency={isDetailsOpen}
                      className="mb-10 flex flex-row items-center gap-[6px]"
                    >
                      {Array.from({
                        length: sizeToMountCount(mediumService.size),
                      }).map((_, index) => (
                        <FeatureDot
                          className="group group-hover:bg-white"
                          key={index}
                        />
                      ))}
                    </motion.span>

                    {isDetailsOpen && (
                      <motion.span
                        layout
                        layoutDependency={isDetailsOpen}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="mb-10 text-wrap"
                      >
                        {/*  {mediumService?.description} */}
                        {DESCRIPTIONS[type]?.durations[1]}
                      </motion.span>
                    )}

                    <motion.div layout layoutDependency={isDetailsOpen}>
                      <p>{sizeToMonth(mediumService.size)}</p>
                      {/* @ts-expect-error - TODO: Fix this */}
                      <p>{priceFormatter.format(mediumService.seed_price)}</p>
                      <p>{mediumService?.price}</p>
                    </motion.div>
                  </ToggleGroupItem>
                </CarouselItem>

                <CarouselItem className="flex basis-[80%] items-center justify-center md:basis-1/3">
                  <ToggleGroupItem
                    className="group flex size-80 flex-col items-center"
                    value="l"
                  >
                    <motion.span
                      layout
                      layoutDependency={isDetailsOpen}
                      className="mb-10 flex flex-row items-center gap-[6px]"
                    >
                      {Array.from({
                        length: sizeToMountCount(largeService.size),
                      }).map((_, index) => (
                        <FeatureDot
                          className="group group-hover:bg-white"
                          key={index}
                        />
                      ))}
                    </motion.span>

                    {isDetailsOpen && (
                      <motion.span
                        layout
                        layoutDependency={isDetailsOpen}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="mb-10 text-wrap"
                      >
                        {/* {largeService?.description} */}
                        {DESCRIPTIONS[type]?.durations[2]}
                      </motion.span>
                    )}

                    <motion.div layout layoutDependency={isDetailsOpen}>
                      <p>{sizeToMonth(largeService.size)}</p>
                      {/* @ts-expect-error - TODO: Fix this */}
                      <p>{priceFormatter.format(largeService.seed_price)}</p>
                      <p>{largeService?.price}</p>
                    </motion.div>
                  </ToggleGroupItem>
                </CarouselItem>
              </CarouselContent>
            </Carousel>
          </ToggleGroup>
        </section>
      </SlideContainer>
    </div>
  )
}

export const Route = createFileRoute('/new/founder/packages_/durations')({
  beforeLoad: () => {
    if (!isFounderNewCompleted$.peek()) {
      return redirect({ to: '/new/founder' })
    }
  },

  loader: () => get<Service[]>('/services'),
  staleTime: Infinity,
  component: function Page() {
    const direction = direction$.get()
    const services = Route.useLoaderData()
    const navigate = Route.useNavigate()

    const [isDetailsOpen, setIsDetailsOpen] = useState(true)

    const currentDurationSelectionServiceType =
      founderNewState$.requireDurationServiceTypes.get()[0]

    useEffect(() => {
      founderNewState$.showServiceHighlight.set(true)

      return () => {
        const founderServices = founderNewState$.services.peek()
        founderNewState$.showServiceHighlight.set(false)
        founderNewState$.requireDurationServiceTypes.set(
          founderServices.map((service) => service.type),
        )
      }
    }, [])

    return (
      <div>
        <SlideContainer
          animationKey={currentDurationSelectionServiceType}
          direction={direction}
        >
          <PackageDurationSelector
            services={services}
            isDetailsOpen={isDetailsOpen}
            setIsDetailsOpen={setIsDetailsOpen}
            type={currentDurationSelectionServiceType}
            onSelectDuration={async (size) => {
              const requireDurationServiceTypes =
                founderNewState$.requireDurationServiceTypes.peek()
              const founder = founderNewState$.peek()

              const selectedService = services.find(
                (service) =>
                  service.type === currentDurationSelectionServiceType &&
                  service.size === size,
              )!
              const restServices = founder.services.filter(
                (service) =>
                  service.type !== currentDurationSelectionServiceType,
              )

              const newRequireDurationServiceTypes =
                requireDurationServiceTypes.slice(1)

              founderNewState$.services.set([
                ...restServices,
                {
                  size: selectedService.size,
                  type: selectedService.type,
                },
              ])
              founderNewState$.showDuration.set(true)

              if (newRequireDurationServiceTypes.length === 0) {
                founderNewState$.showServiceHighlight.set(false)
                founderNewState$.showDuration.set(true)
                navigate({ to: '/new/founder/time' })
                return
              }

              founderNewState$.requireDurationServiceTypes.set(
                newRequireDurationServiceTypes,
              )
            }}
          />
        </SlideContainer>

        <BottomNavigation left={<BackButton native />} />
      </div>
    )
  },
})
