import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

interface Props extends PropsWithChildren {
  className?: string;
  percentage: number;
}

export const StatusCircle = ({ children, className, percentage }: Props) => {
  return (
    <div
      suppressHydrationWarning
      style={{
        background: `conic-gradient(black ${percentage}%, #00000014 0)`,
      }}
      className={cn(
        "flex size-[224px] items-center justify-center rounded-full",
        className,
      )}
    >
      <div className="flex size-[222px] items-center justify-center rounded-full bg-white px-4 text-center">
        {children}
      </div>
    </div>
  );
};
StatusCircle.displayName = "StatusCircle";
