import { observable } from "@legendapp/state";

import { KickoffDate, ServiceType } from "@/lib/global-types";

export type CreativesTiers = "L1" | "L2" | "L3";

export type DesignerCreate = {
  services: ServiceType[];
  samples: string[];
  email: string;
  links: string[];
  availability: KickoffDate | null;
};

export const designerCreateState$ = observable<DesignerCreate>({
  email: "",
  samples: [],
  services: [],
  links: [],
  availability: null,
});

export const isDesignerCreateCompleted$ = observable(() => {
  return (
    designerCreateState$.services.get().length > 0 ||
    designerCreateState$.samples.get().length > 0 ||
    designerCreateState$.email.get().length > 0
  );
});
