import { cn } from "@/lib/utils";

interface Props {
  className?: string;
  color?: string;
  size?: number;
}

export const Spinner = ({ className, color, size = 20 }: Props) => {
  const bars = Array(12).fill(0);
  return (
    <div
      className={cn("wrapper", className)}
      style={
        {
          "--spinner-size": `${size}px`,
          "--spinner-color": color,
        } as React.CSSProperties
      }
    >
      <div className="spinner">
        {bars.map((_, i) => (
          <div className="bar" key={`spinner-bar-${i}`} />
        ))}
      </div>
    </div>
  );
};
Spinner.displayName = "Spinner";
