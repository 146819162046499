import { createFileRoute } from "@tanstack/react-router";

import { OPLogo } from "@/components/op-logo";
import { OPLogoLarge } from "@/components/op-logo-large";

export const Route = createFileRoute("/_misc/_newsletter/confirmed")({
  component: function Page() {
    return (
      <main className="flex size-full min-h-dvh flex-col items-center justify-center">
        <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
          <a href="https://openpurpose.com" rel="noreferrer">
            <OPLogoLarge />
          </a>
        </header>

        <main className="flex flex-col items-center justify-center gap-10 text-center">
          <h2>
            Happy you joined us.
            <br />
            You’ll hear from us soon.
          </h2>
        </main>

        <section className="fixed bottom-6 left-0 right-0 z-20 flex items-center justify-center md:bottom-10">
          <a href="https://x.com/openpurpose" target="_blank" rel="noreferrer">
            <OPLogo />
          </a>
        </section>
      </main>
    );
  },
});
