import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { Link } from "@tanstack/react-router";
import dayjs from "dayjs";
import { useState } from "react";

import { AdminService } from "@/api/services/admin.service";
import { BottomNavigation } from "@/components/bottom-navigation";
import { CopyEmailButton } from "@/components/copy-email-button";
import { FadeScaleContainer } from "@/components/fade-scale-container";
import { FoundersTag } from "@/components/founders-tag";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { OfferButton } from "@/components/proposals/offer-button";
import {
  OfferDialog,
  ServicesPayload,
} from "@/components/proposals/offer-dialog";
import { Spinner } from "@/components/spinner";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { SYSTEM_TODAY } from "@/lib/constants";
import { ProposalPayload, ProposalResult } from "@/lib/global-types";
import { cn, formatTimezone } from "@/lib/utils";
import { toast } from "@/lib/utils/toast";

const today = SYSTEM_TODAY;

const nextMonday =
  today.day() === 1 ? today.add(7, "day") : today.add(8 - today.day(), "day"); // Otherwise, find the next Monday

const POSSIBLE_DATES = [
  nextMonday.add(1, "week"),
  nextMonday.add(2, "week"),
  nextMonday.add(3, "week"),
];

const getServicesFromProposal = (
  proposal: ProposalResult["proposals"][number],
) => {
  const plan = proposal.proposal_items.find((item) => item.type === "plan");

  if (plan) {
    const item = proposal.proposal_items[0];
    return item.plan?.services;
  } else {
    return proposal.proposal_items.map((item) => item.service!);
  }
};

type ProposalState = {
  base: ServicesPayload | null;
  suggestion: ServicesPayload | null;
  requested: ServicesPayload;
};

export const Route = createFileRoute(
  "/dashboard/admin/founders/$founderId/proposal",
)({
  loader: async ({ params, context: { queryClient } }) => {
    await Promise.all([
      queryClient.ensureQueryData(AdminService.fetchFounder(params.founderId)),
      queryClient.ensureQueryData(
        AdminService.fetchFounderProposal(params.founderId),
      ),
    ]);
  },

  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: function Page() {
    /*   const { founder, proposal: initialProposal } = Route.useLoaderData(); */
    const { founderId } = Route.useParams();
    const navigate = Route.useNavigate();

    const founderQuery = useSuspenseQuery(AdminService.fetchFounder(founderId));
    const proposalQuery = useSuspenseQuery(
      AdminService.fetchFounderProposal(founderId),
    );

    const founder = founderQuery.data;
    const initialProposal = proposalQuery.data;

    const [projectDescription, setProjectDescription] = useState(
      initialProposal?.description || "",
    );

    const [selectedDate, setSelectedDate] = useState(
      dayjs(initialProposal.kickoff_date || nextMonday.toDate()),
    );
    const [isBaseOfferDialogOpen, setIsBaseOfferDialogOpen] = useState(false);
    const [isSuggestionOfferDialogOpen, setIsSuggestionOfferDialogOpen] =
      useState(false);
    const [isRequestedOfferDialogOpen, setIsRequestedOfferDialogOpen] =
      useState(false);

    //@ts-expect-error this is so complicated to type. Yes, skill issue. But my boss is rushing me.
    const [state, setState] = useState<ProposalState>(() => {
      if (initialProposal) {
        const base = initialProposal.proposals.find(
          (proposal) => proposal.type === "recommended",
        );
        const suggestion = initialProposal.proposals.find(
          (proposal) => proposal.type === "suggested",
        );

        const requested = initialProposal.proposals.find(
          (proposal) => proposal.type === "requested",
        );

        const baseSelectedServices = base ? getServicesFromProposal(base) : [];
        const suggestionSelectedServices = suggestion
          ? getServicesFromProposal(suggestion)
          : [];
        const requestedSelectedServices = requested
          ? getServicesFromProposal(requested)
          : [];

        return {
          base: base ? { selectedServices: baseSelectedServices } : null,
          suggestion: suggestion
            ? {
                selectedServices: !suggestion?.proposal_items[0].plan
                  ? suggestionSelectedServices
                  : [],
                selectedPlan: suggestion.proposal_items[0].plan,
              }
            : null,
          requested: {
            selectedServices: !requested?.proposal_items[0].plan
              ? requestedSelectedServices
              : [],
            selectedPlan: requested ? requested.proposal_items[0].plan : null,
          },
        };
      }

      return {
        base: {
          recommendation: "",
          selectedServices: [],
          selectedPlan: null,
        },
        suggestion: null,
      };
    });

    const saveMutation = useMutation({
      mutationFn: async (silent: boolean = false) => {
        const isRecommendedEmpty =
          !state.base ||
          (state.base.selectedServices.length === 0 &&
            !state.base.selectedPlan);

        const isSuggestionsEmpty =
          !state.suggestion ||
          (state.suggestion.selectedServices.length === 0 &&
            !state.suggestion.selectedPlan);

        if ((isRecommendedEmpty || isSuggestionsEmpty) && !silent) {
          toast("Please select at least one service or plan");
          return;
        }

        const payload: ProposalPayload = {
          description: projectDescription,
          kickoff_date: selectedDate.toISOString(),
          proposals: [],
        };

        // Add recommended(base) proposal
        if (state.base) {
          const baseItems: ProposalPayload["proposals"][number]["proposal_items"] =
            state.base.selectedServices.map((s) => {
              return {
                type: "service",
                id: s.id,
                description: s.description,
              };
            });

          if (state.base.selectedPlan) {
            baseItems.push({
              type: "plan",
              id: parseInt(state.base.selectedPlan.id),
              description: state.base.selectedPlan.description,
              services: state.base.selectedPlan.services.map((s) => ({
                id: s.id,
                description: s.description,
              })),
            });
          }

          payload.proposals.push({
            type: "recommended",
            proposal_items: baseItems,
          });
        }

        // Add requested proposal
        const requestedItems: ProposalPayload["proposals"][number]["proposal_items"] =
          state.requested.selectedServices.map((s) => {
            return {
              type: "service",
              id: s.id,
              description: s.description,
            };
          });

        if (state.requested.selectedPlan) {
          requestedItems.push({
            type: "plan",
            id: parseInt(state.requested.selectedPlan.id),
            description: state.requested.selectedPlan.description,
            services: state.requested.selectedPlan.services.map((s) => ({
              id: s.id,
              description: s.description,
            })),
          });
        }

        payload.proposals.push({
          type: "requested",
          proposal_items: requestedItems,
        });

        if (state.suggestion) {
          const suggestionItems: ProposalPayload["proposals"][number]["proposal_items"] =
            state.suggestion.selectedServices.map((s) => {
              return {
                type: "service",
                id: s.id,
                description: s.description,
              };
            });

          if (state.suggestion.selectedPlan) {
            suggestionItems.push({
              type: "plan",
              id: parseInt(state.suggestion.selectedPlan.id),
              description: state.suggestion.selectedPlan.description,
              services: state.suggestion.selectedPlan.services.map((s) => ({
                id: s.id,
                description: s.description,
              })),
            });
          }

          payload.proposals.push({
            type: "suggested",
            proposal_items: suggestionItems,
          });
        }

        await AdminService.saveProposal(founder.id, payload);

        if (!silent) {
          await AdminService.publishProposal(founder.id);
          navigate({
            to: "..",
          });
        }
      },
    });

    return (
      <>
        {" "}
        <FadeScaleContainer className="w-f flex w-full flex-col items-center pb-52 text-center">
          <FoundersTag
            className="mb-20"
            stage={founder.stage}
            services={founder.services}
            plan={founder.plan}
            renderSection={["stage"]}
          />
          <div className="mb-20 flex flex-col items-center">
            <CopyEmailButton text={founder.name} email={founder.email} />
            <p className="underline">{founder.company_name}</p>
            {founder.time_zone && (
              <p>{formatTimezone(founder.time_zone)}</p>
            )}{" "}
            {/* // TOOD add timezone componenet */}
          </div>

          <div className="flex flex-col items-center gap-6 md:flex-row">
            <OfferButton
              title="Request"
              payload={state.requested}
              onClick={() => {
                setIsRequestedOfferDialogOpen(true);
              }}
            />

            <OfferButton
              title="Recommendation"
              payload={state.base || undefined}
              onClick={() => {
                if (!state.base) {
                  setState({
                    ...state,
                    base: {
                      selectedServices: [],
                      selectedPlan: null,
                    },
                  });
                }
                setIsBaseOfferDialogOpen(true);
              }}
            />
            <OfferButton
              title="Suggestion"
              payload={state.suggestion || undefined}
              onClick={() => {
                if (!state.suggestion) {
                  setState({
                    ...state,
                    suggestion: {
                      selectedServices: [],
                      selectedPlan: null,
                    },
                  });
                }

                setIsSuggestionOfferDialogOpen(true);
              }}
            />
          </div>

          <OfferDialog
            open={isBaseOfferDialogOpen}
            onClose={() => {
              setIsBaseOfferDialogOpen(false);
              saveMutation.mutate(true);
            }}
            payload={state.base || undefined}
            setPayload={(base) => {
              if (base) {
                setState({ ...state, base });
              }
            }}
          />

          <OfferDialog
            open={isSuggestionOfferDialogOpen}
            onClose={() => {
              setIsSuggestionOfferDialogOpen(false);
              saveMutation.mutate(true);
            }}
            payload={state.suggestion || undefined}
            setPayload={(suggestion) => {
              if (suggestion) {
                setState({ ...state, suggestion });
              }
            }}
          />

          <OfferDialog
            open={isRequestedOfferDialogOpen}
            onClose={() => {
              setIsRequestedOfferDialogOpen(false);
              saveMutation.mutate(true);
            }}
            payload={state.requested}
            setPayload={(requested) => {
              if (requested) {
                setState({ ...state, requested });
              }
            }}
          />

          <div className="mt-20 flex flex-col items-center gap-6">
            <h2>Note</h2>
            <Textarea
              placeholder={"Note"}
              value={projectDescription}
              onChange={(e) => {
                setProjectDescription(e.target.value);
              }}
            />
          </div>

          {/* Date */}
          <div className="mt-20 flex flex-col items-center gap-6">
            <h2>Starting Date</h2>
            <div className="flex gap-2">
              {POSSIBLE_DATES.map((date) => (
                <Button
                  key={date.toString()}
                  className={cn(
                    date.isSame(selectedDate, "day") && "bg-primary text-white",
                  )}
                  onClick={() => {
                    setSelectedDate(date);
                  }}
                >
                  <span className="w-[70%]">{date.format("MMM D ddd")}</span>
                </Button>
              ))}
            </div>
          </div>
        </FadeScaleContainer>
        <BottomNavigation
          left={
            <Button size="sm" asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={
            <Button
              onClick={() => saveMutation.mutate(false)}
              loading={saveMutation.isPending}
            >
              Send
            </Button>
          }
        />
      </>
    );
  },
});
