import { IconClose } from "@/components/icons/icon-close";
import { OPLogo } from "@/components/op-logo";

import { chatState$ } from "../chat.state";
import { ChatView } from "./chat";

export const ChatDialog = () => {
  const open = chatState$.dialogOpen.get();

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex w-full flex-col justify-end bg-[#FFFFFF14] backdrop-blur-[20px] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
      <header className="absolute left-0 right-0 top-0 z-10 flex justify-center bg-gradient-to-b from-[#FFFFFF] to-[transparent] text-black">
        <a href="https://openpurpose.com" rel="noreferrer" className="mt-10">
          <OPLogo />
        </a>
      </header>
      <div className="mx-auto mb-10 h-lvh w-[100%] overflow-auto md:container md:w-[60%] lg:w-[40%] xl:w-[30%]">
        <button
          className="absolute right-5 top-5 z-30 rounded-full bg-black p-2 text-white"
          onClick={() => chatState$.dialogOpen.set(false)}
        >
          <IconClose />
        </button>
        <ChatView />
      </div>
    </div>
  );
};
