import { useMemo, useRef } from "react";

import { useTimeZoneClock } from "@/hooks/use-time-zone-clock";
import { unFormatTimezone } from "@/lib/utils";

export const useUpdateTime = (timeZone: string, targetTimeZone: string) => {
  const tzTime = useTimeZoneClock(unFormatTimezone(timeZone));

  const tzTimeRef = useRef(tzTime);

  const shouldEnableNextUpdateButton = useMemo(() => {
    // designers can only update on tuesday and thursday between 4pm and 5pm
    const currentDay = tzTime.day();

    if (currentDay === 2 || currentDay === 4) {
      const currentTime = tzTime.hour();
      return currentTime >= 16 && currentTime < 17;
    }

    return false;
  }, [tzTime]);

  const startDate = useMemo(() => {
    // startDate should be the previous closest Tuesday or Thursday at 5 PM
    let date = tzTimeRef.current.clone();
    const currentDay = date.day();
    const currentHour = date.hour();

    switch (currentDay) {
      // If today is Tuesday or Thursday and after 5 PM, set today at 5 PM
      case 2:
      case 4: {
        if (currentHour >= 17) {
          break;
        } else if (currentDay === 2) {
          // tuesday
          // last thursday
          date = date.add(-5, "day");
        } else {
          // thursday
          // last tuesday
          date = date.add(-2, "day");
        }
        break;
      }
      case 1:
        // monday
        // last thursday
        date = date.add(-4, "day");
        break;

      case 3: {
        // wednesday
        // last tuesday
        date = date.add(-1, "day");
        break;
      }
      case 5:
        // friday
        // last thursday
        date = date.add(-1, "day");
        break;
      case 6:
        // saturday
        // last thursday
        date = date.add(-2, "day");
        break;
      case 0:
        // sunday
        // last thursday
        date = date.add(-3, "day");
        break;
    }

    return date.hour(17).minute(0).second(0);
  }, []);

  const endDate = useMemo(() => {
    // endDate should be the next closest Tuesday or Thursday at 5 PM
    let date = tzTimeRef.current.clone();
    const currentDay = date.day();
    const currentHour = date.hour();

    switch (currentDay) {
      case 2:
      case 4: {
        if (currentHour < 17) {
          break;
        } else if (currentDay === 2) {
          // tuesday
          // next thursday
          date = date.add(2, "day");
        } else {
          // thursday
          // next tuesday
          date = date.add(5, "day");
        }

        break;
      }
      case 1:
        // monday
        // next tuesday
        date = date.add(1, "day");
        break;
      case 3:
        // wednesday
        // next thursday
        date = date.add(1, "day");
        break;
      case 5:
        // friday
        // next tuesday
        date = date.add(4, "day");
        break;
      case 6:
        // saturday
        // next tuesday
        date = date.add(3, "day");
        break;
      case 0:
        // sunday
        // next tuesday
        date = date.add(2, "day");
        break;
    }

    return date.hour(16).minute(0).second(0);
  }, []);

  const nextUpdateText = useMemo(() => {
    const targetTimeZoneDate = endDate
      .clone()
      .tz(unFormatTimezone(targetTimeZone));
    const day = targetTimeZoneDate.format("dddd");

    return (
      <>
        Next Update on <br />
        {day} {targetTimeZoneDate.format("HH:mm")}
      </>
    );
  }, [endDate, targetTimeZone]);

  return {
    time: tzTime,
    startDate,
    endDate,
    shouldEnableNextUpdateButton,
    nextUpdateText,
  };
};
