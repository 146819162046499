import { useMutation } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";

import { AdminService } from "@/api/services/admin.service";
import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { Links } from "@/components/links";
import { TimezoneSelector } from "@/components/timezone-selector";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";

export const Route = createFileRoute("/dashboard/admin/create-project")({
  component: function Page() {
    const navigate = Route.useNavigate();
    const [payload, setPayload] = useState({
      name: "",
      email: "",
      company_name: "",
      project_outcome_description: "",
      links: [],
      time_zone: "",
      stage: "",
      kickoff_date: "",
    });

    const [currentLink, setCurrentLink] = useState("");

    const shouldShowSubmitButton =
      payload.name &&
      payload.email &&
      payload.company_name &&
      payload.project_outcome_description &&
      payload.time_zone &&
      payload.stage &&
      payload.kickoff_date;

    const createProjectMutation = useMutation({
      mutationFn: async () => {
        if (!shouldShowSubmitButton) {
          return;
        }

        const founder = await AdminService.createProject(payload);

        navigate({
          to: "/dashboard/admin/founders/$founderId/proposal",
          params: {
            founderId: founder.id,
          },
          replace: true,
        });
      },
    });

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();

          createProjectMutation.mutate();
        }}
      >
        <div className="flex flex-col items-center gap-10 pb-40">
          <h1>Create Project</h1>
          <Input
            placeholder="Name"
            value={payload.name}
            onChange={(e) => setPayload({ ...payload, name: e.target.value })}
          />

          <Input
            placeholder="Email"
            type="email"
            value={payload.email}
            onChange={(e) => setPayload({ ...payload, email: e.target.value })}
          />

          <Input
            placeholder="Company Name"
            value={payload.company_name}
            onChange={(e) =>
              setPayload({ ...payload, company_name: e.target.value })
            }
          />

          <Textarea
            className="w-full self-center"
            placeholder="Description"
            value={payload.project_outcome_description}
            onChange={(e) =>
              setPayload({
                ...payload,
                project_outcome_description: e.target.value,
              })
            }
          />

          <div>
            <p className="mb-2">Links</p>
            <Links
              currentLink={currentLink}
              setCurrentLink={setCurrentLink}
              linkList={payload.links}
              setLinkList={(links) =>
                setPayload({ ...payload, links: links as never[] })
              }
            />
          </div>

          <div>
            <p className="mb-2">Time Zone</p>
            <TimezoneSelector
              defaultValue={payload.time_zone}
              onChange={(value) => {
                setPayload({ ...payload, time_zone: value });
              }}
            />
          </div>

          <div>
            <p className="mb-2">Company Stage</p>
            <ToggleGroup
              type="single"
              defaultValue={payload.stage}
              onValueChange={(value) => {
                if (value) {
                  setPayload({ ...payload, stage: value });
                }
              }}
            >
              <ToggleGroupItem value="preSeed">Upstart</ToggleGroupItem>
              <ToggleGroupItem value="seed">Growth</ToggleGroupItem>
              <ToggleGroupItem value="seriesAplus">Legacy</ToggleGroupItem>
            </ToggleGroup>
          </div>

          <div>
            <p className="mb-2">Start Date</p>
            <ToggleGroup
              type="single"
              defaultValue={payload.kickoff_date}
              onValueChange={(value) => {
                if (value) {
                  setPayload({ ...payload, kickoff_date: value });
                }
              }}
            >
              <ToggleGroupItem value="nextWeek">Next Week</ToggleGroupItem>
              <ToggleGroupItem value="nextMonth">Next Month</ToggleGroupItem>
              <ToggleGroupItem value="later">Later</ToggleGroupItem>
            </ToggleGroup>
          </div>
        </div>

        <BottomNavigation
          left={<BackButton />}
          middle={
            shouldShowSubmitButton && (
              <Button
                type="submit"
                loading={createProjectMutation.isPending}
                disabled={createProjectMutation.isPending}
              >
                Submit
              </Button>
            )
          }
        />
      </form>
    );
  },
});
