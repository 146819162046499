import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import { AdminService } from "@/api/services/admin.service";
import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { Spinner } from "@/components/spinner";
import { Button } from "@/components/ui/button";
import { queryClient } from "@/lib/query-client";
import { FOUNDER_FILTERS } from "@/routes/dashboard/admin/founders/_founders.index";

export const Route = createFileRoute(
  "/dashboard/admin/designers/$designerId/assign",
)({
  loader: async ({ params }) => {
    const statuses = FOUNDER_FILTERS.present;
    const founders = await queryClient.fetchQuery(
      AdminService.fetchFilteredFounderList({
        statuses,
        is_onboarding_completed: true,
      }),
    );

    const countList = await queryClient.fetchQuery(
      AdminService.fetchFounderCount(),
    );

    const desinger = await queryClient.fetchQuery(
      AdminService.fetchDesigner(params.designerId),
    );

    return { founders, countList, desinger };
  },
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: function Page() {
    const { founders, countList, desinger } = Route.useLoaderData();
    const navigate = Route.useNavigate();

    const { ref, inView } = useInView();

    const statuses = FOUNDER_FILTERS.present;
    const filters = FOUNDER_FILTERS.present;

    const currentFilterTotalCount = countList.reduce(
      (prevItem, currentItem) => {
        if (filters.includes(currentItem.status)) {
          return prevItem + currentItem.count;
        } else {
          return prevItem;
        }
      },
      0,
    );

    const founderQuery = useInfiniteQuery({
      queryKey: ["founders", statuses],
      queryFn: ({ pageParam }) => {
        return queryClient.fetchQuery(
          AdminService.fetchFilteredFounderList({
            statuses,
            offset: pageParam,
            is_onboarding_completed: true,
          }),
        );
      },
      initialData: () => ({
        pageParams: [0],
        pages: [founders],
      }),
      initialPageParam: 0,
      getNextPageParam: (_, allPages) => {
        const total = allPages.flat().length;

        if (total < currentFilterTotalCount) {
          return total;
        }

        return undefined;
      },
    });

    const assignMutation = useMutation({
      mutationFn: async (founderId: string) => {
        const res = await AdminService.assignDesignerToProject({
          founderId,
          designerId: desinger.creative_id,
        });

        navigate({ to: ".." });

        return res;
      },
    });

    const flatFounders = founderQuery.data.pages.flat();

    useEffect(() => {
      if (inView) {
        founderQuery.fetchNextPage();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView, founderQuery.data.pageParams]);

    return (
      <div className="flex flex-col items-center pb-40">
        <span>{currentFilterTotalCount} Projects</span>

        <div className="flex flex-wrap justify-center gap-4 pt-20">
          {flatFounders.map((founder, index) => (
            <motion.div
              key={founder.id}
              animate={{ y: 0, opacity: 1, scale: 1 }}
              initial={{ y: 10, opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.2, delay: index * 0.05 }}
            >
              <Button
                className="size-[224px]"
                disabled={assignMutation.isPending}
                onClick={() => {
                  assignMutation.mutate(founder.id);
                }}
              >
                {founder.company_name}
              </Button>
            </motion.div>
          ))}
        </div>

        {founderQuery.hasNextPage && (
          <div ref={ref} className="mt-10 text-center">
            <Spinner />
          </div>
        )}

        <BottomNavigation left={<BackButton />} />
      </div>
    );
  },
});
