import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";

import {
  UpdatesService,
  useSendUpdate,
  useUpsertUpdate,
} from "@/api/services/updates.service";
import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { queryClient } from "@/lib/query-client";
import { toast } from "@/lib/utils/toast";

export const Route = createFileRoute(
  "/dashboard/admin/founders/$founderId/updates/$updateId/next",
)({
  loader: async ({ params: { founderId } }) => {
    const update = await queryClient.fetchQuery(
      UpdatesService.getCurrentUpdate(founderId),
    );

    return { update };
  },
  component: function Page() {
    const navigate = Route.useNavigate();
    const { founderId } = Route.useParams();
    const { update } = Route.useLoaderData();
    const [nextSteps, setNextSteps] = useState(update.next_steps);

    const upsertUpdateMutation = useUpsertUpdate();
    const sendUpdateMutation = useSendUpdate();

    const isPending =
      upsertUpdateMutation.isPending || sendUpdateMutation.isPending;

    return (
      <>
        <div className="flex flex-col items-center gap-10">
          <h2>Outline your next step</h2>

          <Textarea
            placeholder="Write..."
            value={nextSteps}
            onChange={(e) => setNextSteps(e.target.value)}
          />
        </div>

        <BottomNavigation
          left={
            <BackButton
              disabled={isPending}
              to={`/dashboard/admin/founders/$founderId/updates/$updateId/preview`}
              params={{ founderId }}
            />
          }
          middle={
            <Button
              onClick={async () => {
                await upsertUpdateMutation.mutateAsync({
                  updateId: update.id,
                  nextSteps,
                });

                await sendUpdateMutation.mutateAsync({
                  updateId: update.id,
                });

                navigate({
                  to: "/dashboard/admin/founders/$founderId/updates",
                  params: { founderId },
                });

                toast("Update sent successfully!");
              }}
              loading={isPending}
            >
              Send
            </Button>
          }
        />
      </>
    );
  },
});
