import { Link } from "@tanstack/react-router";
import { useMemo } from "react";

import { FadeContainer } from "@/components/fade-container";
import {
  FeatueContainer,
  FeatureDot,
  FeatureGroup,
} from "@/components/feature-groups";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { OPLogo } from "@/components/op-logo";
import { ServicesPayload } from "@/components/proposals/offer-dialog";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/solid-dialog";
import { Founder, Propsal } from "@/lib/global-types";
import {
  capitalize,
  cn,
  priceFormatter,
  sizeToMonth,
  sizeToMountCount,
} from "@/lib/utils";

type Props = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  name: Propsal["type"];
  proposal: ServicesPayload;
  showConfirm?: boolean;
  founder: Founder;
  loading?: boolean;
};
export const DelivarablesDialog = ({
  open,
  onConfirm,
  onClose,
  proposal,
  name,
  showConfirm = true,
  loading = false,
}: Props) => {
  const handleConfirm = () => {
    onConfirm();
  };

  const allServices = useMemo(() => {
    if (proposal.selectedPlan) return proposal.selectedPlan.services;
    else return proposal.selectedServices;
  }, [proposal.selectedPlan, proposal.selectedServices]);

  const total = useMemo(() => {
    if (proposal.selectedPlan) return proposal.selectedPlan.price;
    return allServices.reduce((acc, service) => acc + service.price, 0);
  }, [allServices, proposal.selectedPlan]);

  const maxDurationService = allServices.sort(
    (a, b) => sizeToMountCount(b.size) - sizeToMountCount(a.size),
  )[0];

  return (
    <Dialog open={open}>
      <DialogContent>
        <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-black md:top-10">
          <Link to="/dashboard">
            <OPLogo />
          </Link>
        </header>
        <div className="flex max-h-screen w-[100vw] flex-col items-center gap-5 overflow-y-scroll py-20 md:gap-10 md:pb-40">
          <h1 className="mt-20 w-1/3 text-center">
            You’ve selected our <br /> {capitalize(name)} package
          </h1>

          <div className="mt-10 flex flex-col gap-20">
            <div className="flex flex-col gap-2 self-center text-center">
              <div className="flex self-center">
                <FeatueContainer>
                  {proposal.selectedPlan
                    ? proposal.selectedPlan.services.map((service) => (
                        <FeatureGroup key={service.id} className="w-7">
                          {service.type[0].toUpperCase()}
                        </FeatureGroup>
                      ))
                    : proposal.selectedServices.map((service) => (
                        <FeatureGroup key={service.id} className="w-7">
                          {service.type[0].toUpperCase()}
                        </FeatureGroup>
                      ))}
                  <FeatureGroup className="gap-2">
                    {[...Array(sizeToMountCount(maxDurationService.size))].map(
                      (_, i) => (
                        <FeatureDot key={i} />
                      ),
                    )}
                  </FeatureGroup>
                </FeatueContainer>
              </div>

              <p className="mt-10">
                {proposal.selectedServices
                  .map((service) => capitalize(service.type))
                  .join(" + ")}
              </p>

              <p>{sizeToMonth(maxDurationService.size)}</p>
              <p>{priceFormatter.format(total)}</p>
            </div>
          </div>

          <div className="absolute bottom-4 z-10">
            <FadeContainer className="flex items-center justify-center gap-4">
              <Button
                size="sm"
                className={cn({
                  "absolute -left-16 top-1/2 -translate-y-1/2": showConfirm,
                })}
                onClick={onClose}
              >
                <IconBackArrow />
              </Button>

              {showConfirm && (
                <Button onClick={handleConfirm} loading={loading}>
                  Confirm
                </Button>
              )}
            </FadeContainer>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
