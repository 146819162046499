import { createFileRoute, Link } from "@tanstack/react-router";
import { motion } from "framer-motion";

import { DesignerService } from "@/api/services/designer.service";
import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { FoundersTag } from "@/components/founders-tag";
import { Button } from "@/components/ui/button";
import { queryClient } from "@/lib/query-client";
import { generateKickOffDateLabel } from "@/lib/utils";

export const Route = createFileRoute("/dashboard/designer/projects/_projects/")(
  {
    loader: async () => {
      const projects = await queryClient.fetchQuery(
        DesignerService.getProjects(),
      );

      return { projects };
    },
    component: function Page() {
      const { projects } = Route.useLoaderData();
      const delay = Math.random() / 10;

      return (
        <>
          <div className="flex flex-col gap-6 text-center">
            <h1>{projects.length} Projects</h1>
          </div>

          <div className="flex flex-col gap-4 pt-20">
            {projects.map((project) => (
              <motion.div
                key={project.id}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                initial={{ y: 10, opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.2, delay: delay }}
              >
                <Button
                  size="lg"
                  className="group flex-col gap-4 text-center"
                  asChild
                >
                  <Link
                    to={`/dashboard/designer/projects/$projectId`}
                    params={{ projectId: project.id }}
                  >
                    <h2>{project.company_name}</h2>

                    <FoundersTag
                      stage={project.stage}
                      services={project.services}
                      plan={project.plan}
                      className="scale-75"
                    />

                    <h3>{generateKickOffDateLabel(project.kickoff_date)}</h3>
                  </Link>
                </Button>
              </motion.div>
            ))}
          </div>

          <BottomNavigation left={<BackButton />} />
        </>
      );
    },
  },
);
