import { createFileRoute, Link, Outlet } from "@tanstack/react-router";

import { OPLogoLarge } from "@/components/op-logo-large";

export const Route = createFileRoute("/new")({
  component: function Page() {
    return (
      <div className="h-screen">
        <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
          <Link to="/">
            <OPLogoLarge />
          </Link>
        </header>

        <Outlet />
      </div>
    );
  },
});
