import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useRef, useState } from "react";

const ITEMS = [
  "/present/founder/1.png",
  "/present/founder/2.png",
  "/present/founder/3.png",
  "/present/founder/4.mp4",
  "/present/founder/5.png",
  "/present/founder/6.png",
  "/present/founder/7.png",
  "/present/founder/8.png",
  "/present/founder/9.png",
];

export const Route = createFileRoute("/presentation/founder")({
  component: function PresentationPage() {
    const [current, setCurrent] = useState(0);
    const videoRef = useRef<HTMLVideoElement>(null);

    const onMouseDown = (e: React.MouseEvent) => {
      if (e.clientX > window.innerWidth / 2) {
        setCurrent((prev) => Math.min(prev + 1, ITEMS.length - 1));
      } else {
        setCurrent((prev) => Math.max(prev - 1, 0));
      }
    };

    const renderItem = (item: string) => {
      const isVideo = item.endsWith(".mp4");

      if (isVideo) {
        return (
          <video
            ref={videoRef}
            src={item}
            playsInline
            loop
            className="w-full"
          />
        );
      } else {
        return <img src={item} alt="image" className="w-full" />;
      }
    };

    useEffect(() => {
      const selected = ITEMS[current];
      const isSelectedVideo = selected.endsWith(".mp4");

      if (isSelectedVideo) {
        // start from the beginning
        videoRef.current?.play();
      } else {
        videoRef.current?.pause();
      }
    }, [current]);

    return (
      <div>
        <section>
          <div className="flex h-screen w-screen items-center justify-center">
            <div className="flex items-center justify-center">
              {renderItem(ITEMS[current])}
            </div>
          </div>
        </section>
        <div className="absolute inset-0" onMouseDown={onMouseDown}></div>
      </div>
    );
  },
});
