import { createFileRoute } from "@tanstack/react-router";

import { UpdatesService } from "@/api/services/updates.service";
import { BackButton } from "@/components/back-button";
import { BottomNavigation } from "@/components/bottom-navigation";
import { LinkButton } from "@/components/link-button";
import { Button } from "@/components/ui/button";
import { cn, getRatingColor } from "@/lib/utils";

export const Route = createFileRoute("/dashboard/founder/updates/$updateId/")({
  loader: async ({ params: { updateId } }) => {
    const update = await UpdatesService.getUpdate(parseInt(updateId, 10));
    return { update };
  },

  component: function Page() {
    const { update } = Route.useLoaderData();

    return (
      <>
        <div className="flex flex-col items-center gap-20 pb-40">
          <div className="flex flex-col items-center gap-10">
            <Button
              className={cn({
                "border-gray-200": update.founder_rating === null,
              })}
            >
              <span className="flex flex-col items-center gap-2">
                {update.founder_rating !== null && (
                  <span
                    className={cn(
                      "size-2 rounded-full",
                      getRatingColor(update.founder_rating),
                    )}
                  ></span>
                )}
                <span>{update.order || "1"}</span>
              </span>
            </Button>
            <p className="max-w-[450px] text-center">{update.description}</p>
          </div>

          {update.founder_feedback && (
            <div className="flex flex-col items-center gap-6">
              <p>Your Feedback</p>
              <p className="max-w-[450px] text-center">
                {update.founder_feedback}
              </p>
            </div>
          )}

          <div className="flex flex-col items-center gap-10">
            {update.images?.map((imageSource, index) => (
              <div
                key={imageSource}
                className={cn(
                  "group relative flex aspect-video w-[90vw] max-w-[960px] items-center justify-center overflow-hidden rounded-2xl ring-1 ring-gray-200",
                )}
              >
                <img
                  height={540}
                  width={960}
                  src={imageSource}
                  alt={`preview of image ${index}`}
                />
              </div>
            ))}
          </div>

          <div className="flex flex-col items-center gap-6">
            <p>Next steps</p>
            <p className="max-w-[450px] text-center">{update.next_steps}</p>
          </div>
        </div>

        <BottomNavigation
          left={<BackButton to="/dashboard/founder" />}
          middle={
            update.founder_rating === null && (
              <LinkButton
                to="/dashboard/founder/updates/$updateId/rate"
                params={{ updateId: String(update.id) }}
              >
                Rate
              </LinkButton>
            )
          }
        />
      </>
    );
  },
});
