import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, Link, redirect } from "@tanstack/react-router";
import dayjs from "dayjs";
import { useCallback, useState } from "react";

import { AdminService } from "@/api/services/admin.service";
import { BottomNavigation } from "@/components/bottom-navigation";
import { CoundownTimer } from "@/components/countdown-timer";
import { FadeContainer } from "@/components/fade-container";
import { FeatueContainer, FeatureGroup } from "@/components/feature-groups";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { OPLogo } from "@/components/op-logo";
import { SlideContainer } from "@/components/slide-container";
import { Spinner } from "@/components/spinner";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/solid-dialog";
import { useTimeZoneClock } from "@/hooks/use-time-zone-clock";
import { useWebsocket } from "@/hooks/use-websocket";
import { DAYS, IS_DEV } from "@/lib/constants";
import { Creative } from "@/lib/global-types";
import { formatTimezone } from "@/lib/utils";
import { toast } from "@/lib/utils/toast";

export const Route = createFileRoute("/dashboard/admin/designers/$designerId/")(
  {
    loader: async ({ params, context: { queryClient } }) => {
      const designer = await queryClient.ensureQueryData(
        AdminService.fetchDesigner(params.designerId),
      );

      await queryClient.fetchQuery(
        AdminService.fetchCreativeBookings(designer.creative_id),
      );

      if (designer.status === "onboarded") {
        throw redirect({
          to: `/dashboard/admin/designers/$designerId/info`,
          params,
          replace: true,
        });
      }

      return {
        designer,
      };
    },

    pendingComponent: () => (
      <div className="grid h-full w-full place-items-center">
        <Spinner />
      </div>
    ),
    component: function Page() {
      const navigate = Route.useNavigate();
      const { designerId } = Route.useParams();

      const designerQuery = useSuspenseQuery(
        AdminService.fetchDesigner(designerId),
      );

      const designer = designerQuery.data;

      const bookingsQuery = useSuspenseQuery(
        AdminService.fetchCreativeBookings(designer.creative_id),
      );

      const [isOverrideDialogOpen, setIsOverrideDialogOpen] = useState(false);

      const bookings = bookingsQuery.data;

      const timezoneCurrentTime = useTimeZoneClock(designer.time_zone!);

      const introCall = bookings.find((c) => c.type === "first_call");

      useWebsocket(designer.id, "DESIGNER_STATUS_UPDATED", () => {
        designerQuery.refetch();
        bookingsQuery.refetch();
      });
      /* useWebsocket(designer.id, "DESIGNER_BOOKING_UPDATED", (bookings)=> {}) */

      const updateDesignerStatusQuery = useMutation({
        mutationFn: async (_status: Creative["status"]) => {
          /*   await AdminService.updateFounderStatus(designer.id, status); */ // TODO: add update status
          setIsOverrideDialogOpen(false);
          toast("Status updated");
          navigate({ to: "." });
        },
      });

      const renderActionButton = useCallback(() => {
        switch (designer.status) {
          case "introCallRequestSent":
            return (
              <Button disabled size={"lg"}>
                Intro call requested
              </Button>
            );
          case "introCallBooked":
            if (!introCall) return;
            return (
              <CoundownTimer
                startDate={dayjs(introCall.created_at)}
                finishDate={dayjs(introCall.date)}
                timeZone={designer.time_zone!}
              >
                First sync on {DAYS[dayjs(introCall.date).day()]} <br />
                {dayjs(introCall.date).format("DD MMM HH:mm")}
              </CoundownTimer>
            );

          case "introCallStarted":
            return (
              <Button size={"lg"} asChild>
                <Link target="_blank" to={`/presentation/creatives`}>
                  Present
                </Link>
              </Button>
            );

          case "introCallEnded":
            // TODO(mert): we can show updates here instead of a offer button
            /*  return (
              <div className="flex flex-col items-center gap-10">
                <Button size="lg">Make an offer</Button>
                <Button asChild>
                  <Link target="_blank" to={`/presentation/creatives`}>
                    Present
                  </Link>
                </Button>
              </div>
            ); */

            return (
              <div className="flex flex-col items-center gap-10">
                <Button size="lg" asChild>
                  {/* // add assigned project name when emre kasari complate the endpoint */}
                  <Link
                    to="/dashboard/admin/designers/$designerId/assign"
                    params={{ designerId: designer.id }}
                  >
                    Assign a project
                  </Link>
                </Button>

                {designer.projects.length > 0 && (
                  <div>
                    <h3 className="mb-2">Assigned Projects</h3>
                    <ul>
                      {designer.projects.map((p) => {
                        return (
                          <li key={p.id}>
                            <Link
                              className="underline"
                              to="/dashboard/admin/founders/$founderId"
                              params={{ founderId: p.id }}
                            >
                              {p.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            );
        }
        return null;
      }, [
        designer.id,
        designer.projects,
        designer.status,
        designer.time_zone,
        introCall,
      ]);

      return (
        <main className="flex flex-col items-center gap-20 pb-20 text-center">
          <div className="flex flex-col items-center gap-10">
            <FeatueContainer>
              {designer.services.map((service) => {
                return (
                  <FeatureGroup key={service}>
                    {service.at(0)?.toUpperCase()}
                  </FeatureGroup>
                );
              })}
            </FeatueContainer>

            <div>
              <Link
                className="underline"
                to="/dashboard/admin/designers/$designerId/info"
                params={{ designerId: designer.id }}
              >
                {designer.name}
              </Link>
              {designer.time_zone && (
                <h3 className="mt-2">
                  {formatTimezone(designer.time_zone)} ･{" "}
                  {timezoneCurrentTime.format("HH:mm")}
                </h3>
              )}
            </div>
          </div>

          {IS_DEV && (
            <code className="fixed left-5 top-5 flex flex-col items-start text-xs opacity-50">
              <span>email: {designer.email}</span>
              <span>id: {designer.id}</span>
              <span>status: {designer.status}</span>
            </code>
          )}

          <SlideContainer animationKey={designer.status}>
            {renderActionButton()}
          </SlideContainer>

          <Dialog open={isOverrideDialogOpen}>
            <DialogContent>
              <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
                <Link to="/" rel="noreferrer">
                  <OPLogo />
                </Link>
              </header>
              <div className="no-scrollbar flex max-h-screen w-[100vw] flex-col items-center overflow-y-scroll bg-white pb-40 pt-[104px] md:gap-10 md:pt-[184px]">
                <p className="text-center">
                  Select the step that <br /> fits the current status best.
                </p>

                <div className="mt-10 flex flex-col items-center justify-around gap-4 md:flex-row">
                  <Button
                    size="lg"
                    onClick={() => {
                      updateDesignerStatusQuery.mutate("introCallEnded");
                    }}
                  >
                    Call Done
                  </Button>
                  <Button
                    size="lg"
                    onClick={() => {
                      // TODO: add trial done status
                    }}
                  >
                    Trial done
                  </Button>
                  <Button
                    size="lg"
                    onClick={() => updateDesignerStatusQuery.mutate("rejected")}
                  >
                    Cancelled
                  </Button>
                </div>
              </div>

              <section className="fixed bottom-0 left-0 right-0 z-10 flex items-center justify-center gap-2 pb-5">
                <FadeContainer>
                  <Button
                    size="sm"
                    onClick={() => setIsOverrideDialogOpen(false)}
                  >
                    <IconBackArrow />
                  </Button>
                </FadeContainer>
              </section>
            </DialogContent>
          </Dialog>

          <BottomNavigation
            left={
              <Button size="sm" asChild>
                <Link to={`/dashboard/admin`} search={{ filter: undefined }}>
                  <IconBackArrow />
                </Link>
              </Button>
            }
            middle={
              <Button onClick={() => setIsOverrideDialogOpen(true)}>
                Override
              </Button>
            }
          />
        </main>
      );
    },
  },
);
