import { createFileRoute, Link } from "@tanstack/react-router";

import { UpdatesService } from "@/api/services/updates.service";
import { BottomNavigation } from "@/components/bottom-navigation";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { Button } from "@/components/ui/button";
import { queryClient } from "@/lib/query-client";
import { cn, getRatingColor } from "@/lib/utils";

export const Route = createFileRoute(
  "/dashboard/admin/founders/$founderId/updates/_updates/",
)({
  loader: async ({ params: { founderId } }) => {
    const updates = await queryClient.ensureQueryData(
      UpdatesService.getUpdates(founderId),
    );

    return { updates };
  },
  component: function Page() {
    const { founderId } = Route.useParams();
    const { updates } = Route.useLoaderData();

    return (
      <>
        <div className="flex flex-col items-center gap-4">
          {updates.length > 0 ? (
            updates
              .filter((update) => update.id)
              .map((update) => (
                <Button
                  asChild
                  key={update.id}
                  className={cn({
                    "border-gray-200": update.founder_rating === null,
                  })}
                >
                  <Link
                    to="/dashboard/admin/founders/$founderId/updates/$updateId"
                    params={{
                      founderId,
                      updateId: update.id.toString(),
                    }}
                  >
                    <span className="flex flex-col items-center gap-2">
                      {update.founder_rating !== null && (
                        <span
                          className={cn(
                            "size-2 rounded-full",
                            getRatingColor(update.founder_rating),
                          )}
                        ></span>
                      )}
                      <span>{update.order}</span>
                    </span>
                  </Link>
                </Button>
              ))
          ) : (
            <p>No updates yet</p>
          )}
        </div>

        <BottomNavigation
          left={
            <Button size="sm" asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
        />
      </>
    );
  },
});
