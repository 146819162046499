import { useMutation } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { useState } from "react";

import { AdminService } from "@/api/services/admin.service";
import { BottomNavigation } from "@/components/bottom-navigation";
import { IconBackArrow } from "@/components/icons/icon-back-arrow";
import { Button } from "@/components/ui/button";

export const Route = createFileRoute(
  "/dashboard/admin/designers/$designerId/rate",
)({
  component: function Page() {
    const { designerId } = Route.useParams();
    const [isRejectConfirmed, setIsRejectConfirmed] = useState(false);
    const navigate = Route.useNavigate();

    const approveDesigner = useMutation({
      mutationFn: async () => {
        await AdminService.approveDesigner(designerId);

        navigate({
          to: "/dashboard/admin/designers/$designerId",
          params: { designerId },
        });
      },
    });

    const dejectDesigner = useMutation({
      mutationFn: async () => {
        await AdminService.rejectDesigner(designerId);

        navigate({
          to: "/dashboard/admin",
          search: { filter: undefined },
        });
      },
    });

    return (
      <div>
        <div className="flex flex-col items-center gap-20">
          <h1>{"How's the work?"}</h1>

          <div className="flex gap-4">
            <Button onClick={() => approveDesigner.mutate()}>Yes</Button>
            <Button
              loading={dejectDesigner.isPending}
              onClick={() => {
                if (!isRejectConfirmed) {
                  setIsRejectConfirmed(true);
                  return;
                }
                dejectDesigner.mutate();
              }}
            >
              {isRejectConfirmed ? "Sure?" : "No"}
            </Button>
          </div>
        </div>

        <BottomNavigation
          left={
            <Button size="sm" disabled={dejectDesigner.isPending} asChild>
              <Link
                to={`/dashboard/admin/designers/$designerId`}
                params={{ designerId: designerId }}
              >
                <IconBackArrow />
              </Link>
            </Button>
          }
        />
      </div>
    );
  },
});
