export const GraphIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 22.75H5C2.93 22.75 1.25 21.07 1.25 19V2C1.25 1.59 1.59 1.25 2 1.25C2.41 1.25 2.75 1.59 2.75 2V19C2.75 20.24 3.76 21.25 5 21.25H22C22.41 21.25 22.75 21.59 22.75 22C22.75 22.41 22.41 22.75 22 22.75Z"
        fill="currentColor"
      />
      <path
        d="M4.99982 17.75C4.82982 17.75 4.64982 17.69 4.50982 17.57C4.19982 17.3 4.15982 16.83 4.42982 16.51L9.01982 11.15C9.51982 10.57 10.2398 10.22 10.9998 10.19C11.7598 10.17 12.5098 10.45 13.0498 10.99L13.9998 11.94C14.2498 12.19 14.5798 12.31 14.9298 12.31C15.2798 12.3 15.5998 12.14 15.8298 11.87L20.4198 6.51002C20.6898 6.20002 21.1598 6.16 21.4798 6.43C21.7898 6.7 21.8298 7.17 21.5598 7.49L16.9698 12.85C16.4698 13.43 15.7498 13.78 14.9898 13.81C14.2298 13.83 13.4798 13.55 12.9398 13.01L11.9998 12.06C11.7498 11.81 11.4198 11.68 11.0698 11.69C10.7198 11.7 10.3998 11.86 10.1698 12.13L5.57982 17.49C5.41982 17.66 5.20982 17.75 4.99982 17.75Z"
        fill="currentColor"
      />
    </svg>
  );
};
