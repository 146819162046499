"use client";

import * as React from "react";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";

import { useIsMobile } from "@/hooks/use-is-mobile";
import { cn } from "@/lib/utils";

const MAX_WIDTH = 480;
const MOBILE_MAX_WIDTH = 320;
const MIN_WIDTH = 150;
const DEFAULT_INPUT_HEIGHT = 40;

export const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  TextareaAutosizeProps
>(({ className, ...props }, outerRef) => {
  const [isMultiLine, setIsMultiLine] = useState<boolean>(false);
  const [textWidth, setTextWidth] = useState<number>(-1);

  const innerRef = useRef<HTMLTextAreaElement | null>(null);

  useImperativeHandle(outerRef, () => innerRef.current!, []);

  const { isMobile } = useIsMobile();

  useEffect(() => {
    function getTextWidth(text: string, font: string): number {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (!context) return -1;
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    }

    function getCssStyle(element: HTMLElement, prop: string) {
      return window.getComputedStyle(element, null).getPropertyValue(prop);
    }

    function getCanvasFont(el = document.body) {
      const fontWeight = getCssStyle(el, "font-weight") || "normal";
      const fontSize = getCssStyle(el, "font-size") || "18px";
      const fontFamily = getCssStyle(el, "font-family") || "Suisse-Intl";

      return `${fontWeight} ${fontSize} ${fontFamily}`;
    }

    if (typeof props.value === "string") {
      setTextWidth(getTextWidth(props.value, getCanvasFont()));
    }
  }, [props.value]);

  useEffect(() => {
    const textareaElement = innerRef.current;

    function getTextareaWidth() {
      if (isMobile && (textWidth >= MOBILE_MAX_WIDTH || isMultiLine)) {
        return MOBILE_MAX_WIDTH;
      }

      if (textWidth >= MAX_WIDTH || isMultiLine) {
        return MAX_WIDTH;
      }

      if (textWidth > 0) {
        return Math.ceil(textWidth) + 68;
      }

      if (textWidth <= 0) {
        return MIN_WIDTH;
      }
    }

    if (textareaElement) {
      textareaElement.style.width = `${getTextareaWidth()}px`;
    }
  }, [isMobile, isMultiLine, textWidth]);

  useEffect(() => {
    // This is a workaround for this bug:
    // We have a keydown event listener that we use to increase steps if info is provided
    // If textarea is focused immediately when it renders after an "Enter" keystroke it
    // renders with a <br/> inside. This setTimeout fixes it
    if (!props.autoFocus) return;
    setTimeout(() => {
      innerRef.current?.focus();
    }, 0);
  }, [props.autoFocus]);

  return (
    <TextareaAutosize
      minRows={1}
      maxRows={10}
      className={cn(
        `no-scrollbar flex placeholder:text-secondary min-w-[${MIN_WIDTH}px] max-w-[${MOBILE_MAX_WIDTH}px] resize-none rounded-full border border-primary bg-white px-4 py-[7px] text-center focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 md:max-w-[480px]`,
        { "rounded-2xl": isMultiLine },
        className,
      )}
      onHeightChange={(height) => {
        if (!isMultiLine && height > DEFAULT_INPUT_HEIGHT) {
          setIsMultiLine(true);
        }
        if (isMultiLine && height <= DEFAULT_INPUT_HEIGHT) {
          setIsMultiLine(false);
        }
      }}
      ref={(tag) => {
        innerRef.current = tag; // Set the ref directly
      }}
      {...props}
    />
  );
});
Textarea.displayName = "Textarea";
